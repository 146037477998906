/* eslint-disable jsx-a11y/anchor-is-valid */
import { QUESTIONNAIRE_TEMPLATE_URL_PARAM } from 'constants/questionnaire';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

const getName = (investor, account, mode) => {
  const name = investor.full_name;
  if (mode.startsWith('investor') || !account) return name;
  const s = name.charAt(name.length - 1) === 's' ? '' : 's';
  return `${name}'${s} ${account.display_name}`;
};

const Heading = ({ flavor, investors, mode, questionnaireTemplate, step }) => {
  const { printRiskToleranceQuestionnaire } = useContext(AdvisorContext);

  const params = new URLSearchParams(window.location.search);
  const questionnaireTemplateId = params.get(QUESTIONNAIRE_TEMPLATE_URL_PARAM);

  const handlePrintQuestionnaire = useCallback(
    () => printRiskToleranceQuestionnaire(questionnaireTemplateId),
    [questionnaireTemplateId]
  );

  const getTarget = () => {
    const [investor] = investors;
    const account = mode.startsWith('account') ? investor?.accounts?.[0] : null;
    if (investor) return getName(investor, account, mode);
    if (!investor && account) return account.display_name;
    return '';
  };

  const getTitle = () => {
    if (questionnaireTemplate?.is_check_in) return <FormattedMessage id="check-in.title" />;
    if (questionnaireTemplate?.is_scored)
      return <FormattedMessage id="rtq.title.risk-tolerance-assessment" />;
    return questionnaireTemplate?.name || <FormattedMessage id="rtq.title.questionnaire" />;
  };

  return (
    <div className="prism-questions-investor-heading" data-investor={flavor === 'investor'}>
      <h4>
        {getTitle()}
        {flavor === 'advisor' && (
          <FormattedMessage id="rtq.title.risk-tolerance-for" values={{ target: getTarget() }} />
        )}
      </h4>
      {flavor === 'advisor' && step !== 'result' && (
        <div className="question-pdf">
          <a
            aria-label="Print Questionnaire"
            href="#"
            onClick={handlePrintQuestionnaire}
            role="button"
          >
            <FormattedMessage id="rtq.print.pdf-questionnaire" />
          </a>
        </div>
      )}
    </div>
  );
};

Heading.propTypes = {
  flavor: PropTypes.string,
  investors: PropTypes.array,
  mode: PropTypes.string.isRequired,
  questionnaireTemplate: PropTypes.object,
  step: PropTypes.string.isRequired
};

Heading.defaultProps = {
  flavor: 'advisor',
  investors: [],
  questionnaireTemplate: null
};

export default Heading;
