import BulkUpdatePortfoliosSummaryModal from 'components/advisor/bulk-update/summary';
import { AdvisorContext } from 'containers/advisor';
import notificationHandlers from 'containers/layout/header/notifications';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import NavigationAdvisor from '../../navigation/advisor';
import SideBarAdvisor from '../../navigation/left-bar';
import Header from '../header';
import './styles.scss';

const FIVE_SECONDS = 5 * 1000;
const ONE_MINUTE = 60 * 1000;

export const NOTIFICATION_VERBS = {
  accountsBulkUpdate: 'accounts bulk update',
  exceptionUpdated: 'exception updated',
  followed: 'followed',
  hasTakenCheckIn: 'has taken check-in',
  hasTakenQuestionnaire: 'has taken questionnaire',
  integrationAccountFailed: 'integration account failed',
  modelPortfoliosBulkUpdate: 'model portfolios bulk update',
  reviewedReport: 'reviewed report',
  scanIQComplete: 'scan_iq_completed',
  scanIQExtracted: 'scan_iq_extracted',
  scanIQFailed: 'scan_iq_failed',
  scanIQUpdate: 'scan_iq_update',
  surveillanceExceptionUpdated: 'surveillance exception updated',
  tagged: 'tagged',
  wasLinked: 'was linked'
};

export const HeaderAdvisor = ({ modelsMeta, notifications }) => {
  const {
    aiAssistantProvider,
    authProvider,
    investorProvider,
    modelProvider,
    notificationsProvider,
    prismProvider,
    prospectProvider,
    routerActions,
    user
  } = useContext(AdvisorContext);

  if (!authProvider.isAnyAdvisor(user)) return null;

  const [bulkUpdatePortfoliosInvestor, setBulkUpdatePortfoliosInvestor] = useState(null);
  const [bulkUpdatePortfoliosSummary, setBulkUpdatePortfoliosSummary] = useState(null);
  const [displayedNotifications, setDisplayedNotifications] = useState([]);
  const [takenQuestionnaireModal, setTakenQuestionnaireModal] = useState(null);

  const { pathname } = window.location;
  const regexScanIQ = /^\/advisor\/ai-assistant\/scan-iq(\/\d+)?(\/details\/?)?$/;

  const clearPortfoliosSummary = () => {
    setBulkUpdatePortfoliosSummary(null);
    setBulkUpdatePortfoliosInvestor(null);
  };

  const setAccountsSummary = (summary, investor) => {
    setBulkUpdatePortfoliosSummary(summary);
    setBulkUpdatePortfoliosInvestor(investor);
    const provider = investor.is_prospect ? prospectProvider : investorProvider;
    provider.getAccounts(investor.id);
  };

  const setModelPortfoliosSummary = summary => {
    setBulkUpdatePortfoliosSummary(summary);
    modelProvider.list(modelsMeta.params);
  };

  const stopPolling = interval => response => {
    if (response?.error?.code === 403 && interval) clearInterval(interval);
  };

  /*
   * Initialize new investors counter
   */
  useEffect(() => {
    investorProvider.countNew();
  }, []);

  /*
   * Initialize notifications polling
   */
  useEffect(() => {
    let notificationsInterval;
    const checkNotifications = () => {
      notificationsProvider.list().catch(stopPolling(notificationsInterval));
    };

    if (notificationsInterval) clearInterval(notificationsInterval);
    notificationsInterval = setInterval(checkNotifications, FIVE_SECONDS);
    return () => clearInterval(notificationsInterval);
  }, [user.id]);

  /*
   * Initialize PRISM intents polling
   */
  useEffect(() => {
    let intentsInterval;
    const checkIntents = () => {
      prismProvider.getModelPortfolioPrismIntents().catch(stopPolling(intentsInterval));
      prismProvider.getAccountPrismIntents().catch(stopPolling(intentsInterval));
    };

    if (intentsInterval) clearInterval(intentsInterval);
    intentsInterval = setInterval(checkIntents, ONE_MINUTE);
    return () => clearInterval(intentsInterval);
  }, [user.id]);

  /*
   * Handle new notifications
   */
  useEffect(() => {
    const notificationIds = [];
    notifications.forEach(notification => {
      if (!displayedNotifications.includes(notification.id)) {
        setDisplayedNotifications(prevDisplayedNotifications => [
          ...prevDisplayedNotifications,
          notification.id
        ]);

        switch (notification.verb) {
          case NOTIFICATION_VERBS.exceptionUpdated:
            notificationHandlers.handleExceptionUpdated(notification);
            break;

          case NOTIFICATION_VERBS.surveillanceExceptionUpdated:
            notificationHandlers.handleSurveillanceExceptionUpdated(notification);
            break;

          case NOTIFICATION_VERBS.followed:
            notificationHandlers.handleFollowed(notification);
            break;

          case NOTIFICATION_VERBS.hasTakenCheckIn:
            notificationHandlers.handleHasTakenCheckIn(notification);
            break;

          case NOTIFICATION_VERBS.hasTakenQuestionnaire: {
            const {
              id: investorId,
              first_name: firstName,
              is_first: isFirst,
              is_prospect: isProspect,
              view_answers: viewAnswers
            } = notification.data;
            const clientProvider = isProspect ? prospectProvider : investorProvider;
            Promise.all([
              clientProvider.get(investorId),
              clientProvider.getAccounts(investorId)
            ]).then(() => {
              if (isFirst && !takenQuestionnaireModal)
                setTakenQuestionnaireModal({
                  actorName: firstName,
                  reviewUrl: viewAnswers
                });
              if (!isFirst) notificationHandlers.handleHasTakenQuestionnaire(notification);
            });
            break;
          }

          case NOTIFICATION_VERBS.reviewedReport:
            notificationHandlers.handleReviewedReport(notification);
            break;

          case NOTIFICATION_VERBS.integrationAccountFailed:
            notificationHandlers.handleIntegrationAccountFailed(notification);
            break;

          case NOTIFICATION_VERBS.accountsBulkUpdate:
            notificationHandlers.handlePortfoliosBulkUpdate(notification, setAccountsSummary);
            break;

          case NOTIFICATION_VERBS.modelPortfoliosBulkUpdate:
            prismProvider.getModelPortfolioPrismIntents();
            notificationHandlers.handlePortfoliosBulkUpdate(
              notification,
              setModelPortfoliosSummary
            );
            break;

          case NOTIFICATION_VERBS.tagged:
            notificationHandlers.handleTagged(notification);
            break;

          case NOTIFICATION_VERBS.wasLinked:
            notificationHandlers.handleWasLinked(notification);
            break;

          case NOTIFICATION_VERBS.scanIQExtracted:
            aiAssistantProvider.saveScanFileVerb(notification.verb, notification.data?.task_id);
            if (!regexScanIQ.test(pathname))
              notificationHandlers.handleFileScanIQExtract(notification, routerActions);
            break;

          case NOTIFICATION_VERBS.scanIQComplete:
            aiAssistantProvider.saveScanFileVerb(notification.verb, notification.data?.task_id);
            if (!regexScanIQ.test(pathname))
              notificationHandlers.handleFileScanIQCompleted(notification, routerActions);
            break;

          case NOTIFICATION_VERBS.scanIQUpdate:
            if (notification.data.function_name && notification.data?.task_id)
              aiAssistantProvider.saveScanFunctionUpdate(
                notification.data.function_name,
                notification.data?.task_id
              );
            aiAssistantProvider.saveScanFileVerb(notification.verb, notification.data.task_id);
            break;

          case NOTIFICATION_VERBS.scanIQFailed:
            aiAssistantProvider.saveScanFileVerb(notification.verb, notification.data.task_id);
            if (!regexScanIQ.test(pathname))
              notificationHandlers.handleFileScanIQFailed(notification, routerActions);
            break;
          default:
            break;
        }

        notificationIds.push(notification.id);
      }
    });

    if (!_.isEmpty(notificationIds)) notificationsProvider.read(notificationIds);
  }, [JSON.stringify(notifications)]);

  const { advisor } = user;

  return (
    <>
      <Header
        mode="advisor"
        advisor={advisor}
        baseUrl="/advisor/me/company"
        navigation={<NavigationAdvisor />}
        sidebar={<SideBarAdvisor shown />}
      />
      {takenQuestionnaireModal && (
        <notificationHandlers.TakenQuestionnaireModal {...takenQuestionnaireModal} />
      )}
      <BulkUpdatePortfoliosSummaryModal
        investor={bulkUpdatePortfoliosInvestor}
        onClose={clearPortfoliosSummary}
        summary={bulkUpdatePortfoliosSummary}
      />
    </>
  );
};

HeaderAdvisor.propTypes = {
  modelsMeta: PropTypes.object.isRequired,
  notifications: PropTypes.array
};

HeaderAdvisor.defaultProps = {
  notifications: []
};

export default connect(state => ({
  modelsMeta: state.models.listMeta,
  notifications: state.notifications
}))(HeaderAdvisor);
