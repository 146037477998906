/* eslint-disable class-methods-use-this, no-underscore-dangle */
import { contentTypes, headers as headerNames } from 'constants/http';
import config from '../config';

export class AjaxProvider {
  headers = { [headerNames.CONTENT_TYPE]: contentTypes.JSON };

  // considering that this class is a singleton,
  // we can use this variable to store the refresh token promise
  // and avoid multiple calls to the refresh token endpoint
  refreshTokenPromise = null;

  constructor() {
    // implement singleton pattern
    if (!AjaxProvider.instance) AjaxProvider.instance = this;
    return AjaxProvider.instance;
  }

  configure({ dispatch, ...params }) {
    if (!this.dispatch) {
      this.dispatch = dispatch;
      import('providers/auth').then(({ default: AuthProvider }) => {
        this.authProvider = new AuthProvider({ dispatch, ...params });
      });
    }
  }

  addHeader(key, value) {
    this.headers[key] = value;
  }

  deleteHeader(key) {
    delete this.headers[key];
  }

  authenticate(token) {
    this.addHeader(config.apiTokenLabel, config.apiTokenValue(token));
  }

  deauthenticate() {
    this.deleteHeader('Authorization');
  }

  handleResponse(url, options, data, response, isRetry) {
    if (response.ok) return Promise.resolve(data);
    if (response.status !== 401 || isRetry || url === this.authProvider.refreshTokenUrl)
      return Promise.reject(data);
    return this.handle401(url, options, data);
  }

  handle401(url, options, data) {
    // remove the expired token from the headers
    this.deauthenticate();

    if (!this.refreshTokenPromise)
      this.refreshTokenPromise = this.authProvider.refreshToken().finally(() => {
        // Reset after handling
        this.refreshTokenPromise = null;
      });

    return this.refreshTokenPromise
      .then(refreshTokenResponse => {
        // If the refresh token request fails,
        // deauthenticate the user and reject the original request
        if (!refreshTokenResponse || refreshTokenResponse.error) {
          this.authProvider.deauthenticate();
          return Promise.reject(data);
        }

        // retry the original request
        return this.request(url, options, true);
      })
      .catch(() => Promise.reject(data));
  }

  request(url, options, isRetry = false) {
    const opts = { ...options };
    opts.headers = { ...this.headers, ...opts.headers };
    return fetch(url, opts)
      .then(response => Promise.all([response.json(), response]))
      .then(([data, response]) => this.handleResponse(url, options, data, response, isRetry))
      .catch(response => response);
  }

  requestFormData(url, options) {
    const _opts = { ...options };
    _opts.headers = { ...this.headers, ..._opts.headers };
    delete _opts.headers[headerNames.CONTENT_TYPE];
    const promise = fetch(url, _opts)
      .then(response => Promise.all([response.json(), response]))
      .then(([data, response]) => (response.ok ? Promise.resolve(data) : Promise.reject(data)))
      .catch(error => error);

    return promise;
  }

  requestNoJSON(url, options, data, convertArraysToMultiAttrs = false) {
    const _opts = { ...options };
    _opts.headers = { ...(_opts.headers || this.headers) };
    return fetch(url + this.buildQueryString(data, convertArraysToMultiAttrs), _opts);
  }

  get(url, data, headers, opts, convertArraysToMultiAttrs = false) {
    return this.request(url + this.buildQueryString(data, convertArraysToMultiAttrs), {
      ...opts,
      method: 'GET',
      headers
    });
  }

  post(url, data, headers, opts) {
    return this.request(url, { ...opts, method: 'POST', headers, body: JSON.stringify(data) });
  }

  put(url, data, headers, opts) {
    return this.request(url, { ...opts, method: 'PUT', headers, body: JSON.stringify(data) });
  }

  delete(url, headers, opts) {
    return this.requestNoJSON(url, { ...opts, method: 'DELETE', headers });
  }

  deleteWithResponseBody(url, headers, opts) {
    return this.request(url, { ...opts, method: 'DELETE', headers });
  }

  patch(url, data, headers, opts) {
    return this.request(url, { ...opts, method: 'PATCH', headers, body: JSON.stringify(data) });
  }

  postFormData(url, data, headers, method = 'POST') {
    return this.requestFormData(url, { method, headers, body: data });
  }

  buildQueryString = (params, convertArraysToMultiAttrs = false) => {
    let queryString = Object.keys(params || {})
      .filter(k => params[k] !== undefined)
      .map(k => {
        if (convertArraysToMultiAttrs && Array.isArray(params[k]))
          return params[k].map(p => `${k}=${p}`).join('&');
        return `${k}=${params[k]}`;
      })
      .join('&');
    if (queryString.length) queryString = `?${queryString}`;
    return queryString;
  };
}

const ajaxProvider = new AjaxProvider();
export default ajaxProvider;
