import PropTypes from 'prop-types';
import React from 'react';
import '../styles.scss';

const ConfigurationFormSection = ({ children, onSubmit, onCancel, onResetToDefault, error }) => (
  <form onSubmit={onSubmit} autoComplete="off">
    <div>{children}</div>
    <div className="form-save-wrapper">
      {onResetToDefault && (
        <button
          type="button"
          onClick={onResetToDefault}
          className="btn btn-outline-primary return-to-default__button btn-small"
        >
          Reset To Default
        </button>
      )}
      {onCancel && (
        <button type="button" onClick={onCancel} className="btn btn-secondary btn-small">
          Cancel
        </button>
      )}
      <button type="submit" className="btn btn-primary btn-small" disabled={error}>
        Save
      </button>
    </div>
  </form>
);

ConfigurationFormSection.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onResetToDefault: PropTypes.func,
  error: PropTypes.object
};

ConfigurationFormSection.defaultProps = {
  error: null,
  onCancel: null,
  onResetToDefault: null
};

export default ConfigurationFormSection;
