import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _ from 'lodash';
import { VerboseErrorInput, Textarea } from 'app/components/form';
import { RISK_LEVELS } from 'containers/risk-tolerance-questionnaire/result/score-context/utils/constants';
import SpinnerLoader from 'app/components/performance-spinner';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import Radio from 'components/form/radio';
import { RISK_LEVELS_COLORS } from 'app/utils/colors';
import { ENGLISH_LANGUAGE, SPANISH_LANGUAGE } from 'lang/constants';
import { BalancedIcon } from 'app/containers/risk-tolerance-questionnaire/result/score-context/personality-type/pdf/icons';
import RiskLevelsSlider from './slider';
import RiskLevelColorSelector from './color-selector';
import './styles.scss';

const MINIMUM_GAP_BUCKET = 2;
const MINIMUM_FIRST_POSITION_OF_THE_SLIDER = 2.8;
const PREVIOUS_MAX = 'previousMax';
const NEXT_MIN = 'nextMin';

export const validateRiskLevels = riskLevels => {
  if (!Array.isArray(riskLevels)) return 'Invalid risk levels data';

  const errors = riskLevels.map((level, index) => {
    const levelErrors = {};

    if (_.isEmpty(level.label?.en))
      levelErrors.labelEn = `Label (EN) is required for risk level ${index + 1}`;

    if (_.isEmpty(level.description?.en))
      levelErrors.descriptionEn = `Description (EN) is required for risk level ${index + 1}`;

    return _.isEmpty(levelErrors) ? null : levelErrors;
  });

  // Filter out null values (valid entries)
  const filteredErrors = errors.filter(error => error !== null);

  return filteredErrors.length > 0 ? filteredErrors : undefined;
};

const RiskLevelBucket = ({
  addRiskLevel,
  openModal,
  level,
  index,
  companyRiskLevels,
  updateRiskLevelAttribute,
  updateColor,
  focusedRow,
  labelLanguage,
  descriptionLanguage,
  setFocusedRow
}) => {
  const isFocusedRow = focusedRow === index;

  const debouncedBlurHandler = useMemo(
    () =>
      _.debounce(() => {
        const activeRow = document.activeElement.closest('.risk-level__row');
        if (!activeRow) setFocusedRow(null);
      }, 100),
    [setFocusedRow]
  );

  const gap = level.max - level.min;

  const getPositionAdjustment = percentage => {
    switch (true) {
      case percentage > 65:
        return `+ ${Math.min(80, percentage - 30)}px`; // More than 65%
      case percentage < 45:
        return `- ${Math.max(-10, 50 - percentage)}px`; // Less than 45%
      default:
        return `- ${Math.max(-10, 20 - percentage)}px`; // Between 45% and 65%
    }
  };

  return (
    <div
      className="risk-level__row"
      style={{
        position: 'absolute',
        zIndex: isFocusedRow ? 10 : 1,
        top:
          index === 0
            ? `calc(5% - 80px)`
            : `calc(${((level.min - 1) / 9) * 100}% ${getPositionAdjustment(
                ((level.min - 1) / 9) * 100
              )})`
      }}
      tabIndex={-1}
    >
      <div
        className="risk-level__row-main-container"
        onFocus={() => setFocusedRow(index)}
        onBlur={() => {
          if (!document.activeElement.closest('.risk-level__row')) debouncedBlurHandler();
        }}
      >
        <div
          className={cn('risk-level__range', { onfocus: isFocusedRow })}
          onClick={() => setFocusedRow(index)}
        >
          <RiskLevelColorSelector
            color={level.color}
            prevColor={index > 0 ? companyRiskLevels[index - 1].color : null}
            nextColor={
              index < companyRiskLevels.length - 1 ? companyRiskLevels[index + 1].color : null
            }
            onChange={newColor => updateColor(newColor, index)}
          />
          <p className="range-value">
            {level.min} → {level.max}
          </p>
        </div>

        <div className="risk-level__label">
          <VerboseErrorInput
            type="text"
            className="form-control"
            value={level.label?.[labelLanguage] || ''}
            onFocus={() => setFocusedRow(index)}
            onBlur={() => {
              if (!document.activeElement.closest('.risk-level__row')) debouncedBlurHandler();
            }}
            onChange={e => updateRiskLevelAttribute('label', e.target.value, index, labelLanguage)}
            error="It must have a Label"
          />
        </div>

        <div className="risk-level__description">
          <Textarea
            rows={focusedRow === index ? 3 : 1}
            maxLength={500}
            className="form-control"
            value={level.description?.[descriptionLanguage] || ''}
            onFocus={() => setFocusedRow(index)}
            onBlur={() => {
              if (!document.activeElement.closest('.risk-level__row')) debouncedBlurHandler();
            }}
            onChange={e =>
              updateRiskLevelAttribute('description', e.target.value, index, descriptionLanguage)
            }
          />
        </div>
        {companyRiskLevels.length > 1 && (
          <button
            type="button"
            onClick={() => openModal(index, level)}
            className={cn('btn-remove-risk-level', 'btn', 'btn-transparent', {
              onfocus: isFocusedRow
            })}
            aria-label="remove Risk level"
          >
            <i className="icon-cross-circle remove-icon" />
          </button>
        )}
      </div>

      <div className="risk-level__buttons">
        {gap >= MINIMUM_GAP_BUCKET && (
          <button
            type="button"
            onClick={() => addRiskLevel(level, index)}
            className="add-risk-level-button btn btn-transparent"
          >
            <i className="icon-add_item" />
            Click to add risk level
          </button>
        )}
      </div>
    </div>
  );
};

const RiskLevelsConfiguration = ({ riskLevel, handleChange }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [companyRiskLevels, setCompanyRiskLevels] = useState([]);
  const [focusedRow, setFocusedRow] = useState(null);
  const [isShown, setIsShown] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState(null);
  const [adjustScore, setAdjustScore] = useState(PREVIOUS_MAX);
  const [labelLanguage, setLabelLanguage] = useState(ENGLISH_LANGUAGE);
  const [descriptionLanguage, setDescriptionLanguage] = useState(ENGLISH_LANGUAGE);
  const hide = () => {
    setIsShown(false);
    setSelectedLevel(null); // Reset selection when modal closes
  };

  useEffect(() => {
    if (!_.isEmpty(riskLevel?.value) || !_.isEmpty(riskLevel?.initialValue)) {
      const effectiveRiskLevels = riskLevel.value ?? riskLevel.initialValue ?? RISK_LEVELS;

      setCompanyRiskLevels(prevState => {
        const newStateString = JSON.stringify(effectiveRiskLevels);
        return JSON.stringify(prevState) !== newStateString
          ? JSON.parse(newStateString)
          : prevState;
      });

      setIsLoaded(true);
    }
  }, [JSON.stringify(riskLevel.value), JSON.stringify(riskLevel.initialValue)]);

  const handleSliderChange = updatedRiskLevels => {
    setCompanyRiskLevels(updatedRiskLevels);
    handleChange('risk_levels', updatedRiskLevels);
  };
  const updateRiskLevelAttribute = (attribute, value, index, lang) => {
    const updatedRiskLevels = companyRiskLevels.map((level, idx) =>
      idx === index
        ? {
            ...level,
            [attribute]: {
              ...level[attribute],
              [lang]: value
            }
          }
        : level
    );

    setCompanyRiskLevels(updatedRiskLevels);
    handleChange('risk_levels', updatedRiskLevels);
  };

  const updateColor = (newColor, index) => {
    const updatedRiskLevels = companyRiskLevels.map((level, idx) =>
      idx === index ? { ...level, color: newColor } : level
    );

    setCompanyRiskLevels(updatedRiskLevels);
    handleChange('risk_levels', updatedRiskLevels);
  };

  const getMiddleColor = (prevColor, nextColor) => {
    const prevIndex = RISK_LEVELS_COLORS.indexOf(prevColor);
    const nextIndex = RISK_LEVELS_COLORS.indexOf(nextColor);

    if (prevIndex !== -1 && nextIndex !== -1 && prevIndex < nextIndex) {
      const middleIndex = Math.floor((prevIndex + nextIndex) / 2);
      return RISK_LEVELS_COLORS[middleIndex];
    }

    return null;
  };

  const addRiskLevel = (level, index) => {
    setCompanyRiskLevels(prevLevels => {
      if (prevLevels.length === 0) return prevLevels;

      const updatedLevels = [...prevLevels];

      // Calculate the gap between min and max
      const gap = level.max - level.min;

      // index 0: max must be at least 2.8
      if (index === 0 && level.max < MINIMUM_FIRST_POSITION_OF_THE_SLIDER) return prevLevels;

      // Prevent adding a row if gap is less than 2
      if (gap < MINIMUM_GAP_BUCKET) return prevLevels;

      const newMin = parseFloat(((level.min + level.max) / 2).toFixed(1));
      const newMax = parseFloat(level.max.toFixed(1));

      updatedLevels[index].max = newMin; // Lower max to create space for the new level

      const prevColor = index > 0 ? updatedLevels[index].color : RISK_LEVELS_COLORS[0];
      const nextColor = index < updatedLevels.length - 1 ? updatedLevels[index + 1]?.color : null;
      const newColor = getMiddleColor(prevColor, nextColor) || prevColor;

      const newLevel = {
        min: newMin,
        max: newMax,
        color: newColor,
        reportIcon: BalancedIcon,
        label: { en: '', es: '' },
        description: { en: '', es: '' }
      };

      updatedLevels.splice(index + 1, 0, newLevel);

      setFocusedRow(index + 1);

      handleChange('risk_levels', updatedLevels);
      return updatedLevels;
    });
  };

  const removeRiskLevel = (index, adjustScore) => {
    setCompanyRiskLevels(prevLevels => {
      if (prevLevels.length <= 1) return prevLevels; // Prevent removing the last row

      const updatedLevels = prevLevels.filter((_, i) => i !== index);

      // If adjusting the previous level's max
      if (adjustScore === PREVIOUS_MAX && index > 0)
        updatedLevels[index - 1].max = prevLevels[index].max;
      // If adjusting the next level's min
      else if (adjustScore === NEXT_MIN && index < prevLevels.length - 1)
        updatedLevels[index].min = prevLevels[index].min;

      // Ensure the first row always has min = 1
      if (updatedLevels.length > 0) updatedLevels[0].min = 1;

      setFocusedRow(null);

      handleChange('risk_levels', updatedLevels);
      return updatedLevels;
    });
  };

  const removeRiskLevelWithAdjustment = () => {
    removeRiskLevel(selectedLevel.index, adjustScore);
    hide();
  };

  const previousLevel =
    selectedLevel?.index > 0 ? companyRiskLevels[selectedLevel.index - 1] : null;

  const nextLevel =
    selectedLevel?.index < companyRiskLevels.length - 1
      ? companyRiskLevels[selectedLevel.index + 1]
      : null;

  const adjustedPreviousMax = previousLevel ? selectedLevel.max : null;
  // eslint-disable-next-line no-nested-ternary
  const adjustedNextMin = nextLevel ? (selectedLevel.index === 0 ? 1 : selectedLevel.min) : null;

  const openModal = (index, level) => {
    setSelectedLevel({ ...level, index });

    setIsShown(true);
    if (index === 0) return setAdjustScore(NEXT_MIN);
    setAdjustScore(PREVIOUS_MAX);
  };

  if (!isLoaded) return <SpinnerLoader spinnerLoading />;

  return (
    <div className="risk-levels-language-and-content__container">
      <div className="risk-levels-language-toggle">
        <button
          type="button"
          className={cn({ active: labelLanguage === ENGLISH_LANGUAGE })}
          onClick={() => {
            setLabelLanguage(ENGLISH_LANGUAGE);
            setDescriptionLanguage(ENGLISH_LANGUAGE);
          }}
        >
          EN
        </button>
        <button
          type="button"
          className={cn({ active: labelLanguage === SPANISH_LANGUAGE })}
          onClick={() => {
            setLabelLanguage(SPANISH_LANGUAGE);
            setDescriptionLanguage(SPANISH_LANGUAGE);
          }}
        >
          ES
        </button>
      </div>
      <div id="risk-level__configuration">
        <div className="risk-level-slider__container">
          <RiskLevelsSlider riskLevels={companyRiskLevels} onChange={handleSliderChange} />
        </div>

        <div className="risk-level-main__container">
          {companyRiskLevels.map((level, index) => (
            <RiskLevelBucket
              key={index}
              level={level}
              index={index}
              companyRiskLevels={companyRiskLevels}
              updateRiskLevelAttribute={updateRiskLevelAttribute}
              updateColor={updateColor}
              focusedRow={focusedRow}
              setFocusedRow={setFocusedRow}
              addRiskLevel={addRiskLevel}
              removeRiskLevel={removeRiskLevel}
              openModal={openModal}
              descriptionLanguage={descriptionLanguage}
              labelLanguage={labelLanguage}
            />
          ))}
        </div>
        <Modal id="remove-risk-level-modal" className="modal-lg" show={isShown} onHide={hide}>
          <ModalHeader />
          <ModalBody>
            {selectedLevel && (
              <>
                <h3 className="mb-2">
                  You are removing the risk level <strong>{selectedLevel.label.en}</strong>.
                </h3>
                <p>
                  This creates a gap between <strong>{selectedLevel.min}</strong> -{' '}
                  <strong>{selectedLevel.max}</strong>. How do you want to fill it ?
                </p>
                <p />
              </>
            )}

            <div className="radios-section">
              {previousLevel && (
                <div className="radio-container">
                  <Radio
                    checked={adjustScore === PREVIOUS_MAX}
                    onChange={() => setAdjustScore(PREVIOUS_MAX)}
                  />
                  <span>
                    Adjust the maximum of <strong>{previousLevel.label.en}</strong> to
                    <strong> {adjustedPreviousMax}</strong>
                  </span>
                </div>
              )}
              {nextLevel && (
                <div className="radio-container">
                  <Radio
                    checked={adjustScore === NEXT_MIN}
                    onChange={() => setAdjustScore(NEXT_MIN)}
                  />
                  <span>
                    Adjust the minimum of <strong>{nextLevel.label.en}</strong> to
                    <strong> {adjustedNextMin}</strong>
                  </span>
                </div>
              )}
            </div>

            <p className="last-label-message">
              You can adjust the remaining levels after removing.
            </p>

            <div className="text-sm-center mt-4">
              <button
                type="button"
                className="btn btn-outline-secondary mr-2 btn-cancel"
                onClick={hide}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-delete delete btn-modal"
                onClick={removeRiskLevelWithAdjustment}
              >
                Remove
              </button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

RiskLevelBucket.propTypes = {
  addRiskLevel: PropTypes.func.isRequired,
  level: PropTypes.shape({
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    label: PropTypes.object.isRequired,
    description: PropTypes.object.isRequired
  }).isRequired,
  index: PropTypes.number.isRequired,
  updateRiskLevelAttribute: PropTypes.func.isRequired,
  focusedRow: PropTypes.object.isRequired,
  setFocusedRow: PropTypes.func.isRequired,
  companyRiskLevels: PropTypes.object.isRequired,
  updateColor: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  labelLanguage: PropTypes.string.isRequired,
  descriptionLanguage: PropTypes.string.isRequired
};

RiskLevelsConfiguration.propTypes = {
  riskLevel: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default RiskLevelsConfiguration;
