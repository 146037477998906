import React from 'react';
import FilterContainer from '../../common/filter-container';
import SingleSelectWithDatePickerFilter from '../../common/single-select-datepicker';
import { EXCEPTION_CREATED_RANGE_ATTR } from '../../constants';
import { filterDefaultProps, filterPropTypes } from '../../types';
import { VIEW_ALL, CUSTOM_RANGE, rangeOptions as baseRangeOptions } from './utils';

const ExceptionCreatedFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => {
  const updateCustomRange = () => {
    if (filters[EXCEPTION_CREATED_RANGE_ATTR]) {
      const dateRange = filters[EXCEPTION_CREATED_RANGE_ATTR].split('__');
      return { ...CUSTOM_RANGE, value: dateRange };
    }
    return CUSTOM_RANGE;
  };

  const getDefaultValue = () => {
    if (!filters[EXCEPTION_CREATED_RANGE_ATTR]) return VIEW_ALL;
    const currentOption = filters[EXCEPTION_CREATED_RANGE_ATTR];
    const matchedOption = baseRangeOptions.find(
      option => option.value.join('__') === currentOption
    );
    return matchedOption || updateCustomRange();
  };

  // Dynamically update range options with the updated CUSTOM_RANGE
  const rangeOptions = [...baseRangeOptions];
  const updatedCustomRange = updateCustomRange();
  rangeOptions[rangeOptions.length - 1] = updatedCustomRange;

  const defaultValue = getDefaultValue();

  const onChangeHandler = ({ value }) => {
    const useDefault = JSON.stringify(value) === JSON.stringify(VIEW_ALL.value);
    setFilters(prevFilters => ({
      ...prevFilters,
      [EXCEPTION_CREATED_RANGE_ATTR]: useDefault ? undefined : value.join('__')
    }));
    setRequiresPageIndexReset(true);
  };

  return (
    <FilterContainer attrs={[EXCEPTION_CREATED_RANGE_ATTR]} filters={filters} label="Created">
      <SingleSelectWithDatePickerFilter
        defaultValue={defaultValue}
        onChange={onChangeHandler}
        options={rangeOptions}
        keepMenuOpenFor="Custom Range"
      />
    </FilterContainer>
  );
};

ExceptionCreatedFilter.propTypes = { ...filterPropTypes };

ExceptionCreatedFilter.defaultProps = { ...filterDefaultProps };

export default ExceptionCreatedFilter;
