import Disclosure from 'components/disclosure';
import { QUESTIONNAIRE_TEMPLATE_URL_PARAM } from 'constants/questionnaire';
import { AdvisorContext } from 'containers/advisor';
import { AuthenticationContext } from 'containers/auth';
import { InvestorContext } from 'containers/investor';
import QuestionnaireWizard from 'containers/risk-tolerance-questionnaire';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { getQuestionnaireIdFromTemplate } from 'utils/questionnaire';
import { recordLogRocketEvent } from 'utils/tracking';

const ToleranceQuestionnaire = ({
  isProspect,
  location: { query },
  questionnaires,
  mode,
  params: { accountId, id: investorId, token },
  route: { flavor }
}) => {
  const params = new URLSearchParams(window.location.search);
  const isMarketing = params.get('source') === 'marketing';

  const { authProvider, user } = useContext(AuthenticationContext);
  const Context = useMemo(
    () => (authProvider.isInvestor(user) ? InvestorContext : AdvisorContext),
    [JSON.stringify(user)]
  );
  const { clientProvider, questionProvider } = useContext(Context);

  const [investors, setInvestors] = useState({});
  const [contentLoaded, setContentLoaded] = useState(false);
  const [questionnaireTemplateId, setQuestionnaireTemplateId] = useState(
    params.get(QUESTIONNAIRE_TEMPLATE_URL_PARAM)
  );

  // get the corresponding list of questions for this questionnaire
  const questions =
    useMemo(() => {
      if (!contentLoaded || !questionnaireTemplateId) return [];
      const questionnaireId = getQuestionnaireIdFromTemplate(
        questionnaireTemplateId,
        investorId,
        accountId
      );
      return questionnaires[questionnaireId];
    }, [contentLoaded, questionnaireTemplateId, JSON.stringify(questionnaires)]) ?? [];

  async function loadTargets() {
    if (!token && !authProvider.isAuthenticated(user)) return;

    // load target & questions
    setContentLoaded(false);
    const investor = await clientProvider.list(
      { id: mode === 'investors' ? query.investors : investorId },
      {},
      false // avoids updating the meta attributes of previous requests
    );
    if (questionnaireTemplateId)
      questionProvider
        .getTemplateQuestions(questionnaireTemplateId, investorId, accountId)
        .catch(() => {
          // if an error occurs because the template used is in draft or invalid, set
          // the `questionnaireTemplateId` to `null` to allow the use of the default template
          setQuestionnaireTemplateId(null);
        });
    setInvestors(investor.results);
    setContentLoaded(true);
  }

  const onQuestionnaireSave = useCallback(success => {
    if (success) loadTargets(); // reload questionnaire target to get the fresh score
  }, []);

  useEffect(() => {
    if (authProvider.isInvestor(user)) recordLogRocketEvent('RTQ');
  }, []);

  // fetch the target objects, a.k.a the account(s) or investor(s)
  useEffect(() => {
    async function fetchData() {
      await loadTargets();
    }
    fetchData();
  }, [JSON.stringify(query.investors), mode, token, user?.id]);

  // sets the default template if no template has been provided
  useEffect(() => {
    const company = user.advisor?.company || user.investor?.advisor?.company || {};
    const {
      default_questionnaire_template_id: defaultQuestionnaireTemplateId,
      default_widget_questionnaire_template_id: defaultWidgetQuestionnaireTemplateId
    } = company;

    if (!questionnaireTemplateId)
      questionProvider.listQuestionnaireTemplates().then(questionnaireTemplates => {
        // gets the default questionnaire for the widget, and otherwise, the default questionnaire
        const questionnaireTemplate =
          questionnaireTemplates.find(qt => qt.id === defaultWidgetQuestionnaireTemplateId) ||
          questionnaireTemplates.find(qt => qt.is_widget_default) ||
          questionnaireTemplates.find(qt => qt.id === defaultQuestionnaireTemplateId) ||
          questionnaireTemplates.find(qt => qt.is_default);
        if (questionnaireTemplate) {
          setQuestionnaireTemplateId(questionnaireTemplate.id);
          questionProvider.getTemplateQuestions(questionnaireTemplate.id, investorId, accountId);
          const url = new URL(window.location.href);
          url.searchParams.set(QUESTIONNAIRE_TEMPLATE_URL_PARAM, questionnaireTemplate.id);
          window.history.replaceState(null, '', url);
        }
      });
  }, [questionnaireTemplateId]);

  if (contentLoaded && mode.startsWith('account') && investors.length)
    if (query.accounts)
      investors[0].accounts = investors[0].accounts.filter(a =>
        query.accounts.split(',').includes(`${a.id}`)
      );
    else if (accountId)
      investors[0].accounts = investors[0].accounts.filter(a => `${a.id}` === `${accountId}`);

  return (
    <div>
      <div className="prism-questions-container prism-questions-begin-container prism-target-questionnaire-start">
        <QuestionnaireWizard
          flavor={flavor}
          initialStep={query.step}
          investors={investors}
          isProspect={isProspect}
          mode={mode}
          onQuestionnaireSave={onQuestionnaireSave}
          questions={questions}
          isMarketing={isMarketing}
        />
      </div>
      <Disclosure />
    </div>
  );
};

ToleranceQuestionnaire.propTypes = {
  isProspect: PropTypes.bool,
  location: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  params: PropTypes.object,
  prospects: PropTypes.array,
  questionnaires: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

ToleranceQuestionnaire.defaultProps = {
  isProspect: false,
  params: null,
  prospects: []
};

function mergeProps({ ...stateProps }, dispatchProps, ownProps) {
  const {
    location: { pathname, query },
    params: { id: investorId, accountId }
  } = ownProps;
  const { user } = stateProps;
  const isProspect =
    user && user.investor ? user.investor.is_prospect : pathname.includes('advisor/prospects/');

  let mode;
  if (!investorId && query.investors) mode = 'investors';
  else if (investorId)
    if (query.accounts) mode = 'accounts';
    else if (accountId) mode = 'account';
    else mode = 'investor';

  return { ...stateProps, ...ownProps, isProspect, mode };
}

export default connect(
  state => ({
    account: state.accounts.view,
    questionnaires: state.questions.questionnaires,
    user: state.auth.user
  }),
  null,
  mergeProps
)(React.memo(ToleranceQuestionnaire));
