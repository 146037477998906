import cn from 'classnames';
import Disclosure from 'components/disclosure';
import Link from 'components/utils/link';
import AssetsClassification from 'containers/advisor/assets-classification';
import CompanyMeta from 'containers/advisor/templates/company-meta';
import QuestionnaireTemplates from 'containers/advisor/templates/questionnaire-templates';
import ReportTemplates from 'containers/advisor/templates/report-templates';
import { AuthenticationContext } from 'containers/auth';
import withSubscription from 'hocs/subscription-validation';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import './styles.scss';

const ADVISOR_TABS = {
  ips: 'IPS',
  proposal: 'Proposal',
  'fact-finder-forms': 'Fact Finder Forms'
};

export const TABS = {
  crs: 'Form CRS',
  ...ADVISOR_TABS,
  emails: 'Emails',
  'report-disclosure': 'Reports Disclosure',
  'assets-classification': 'Assets Classification'
};

const Templates = ({ params: { type, id } }) => {
  const { user, authProvider } = useContext(AuthenticationContext);
  const isAdvisorOrAnalyst = !authProvider.hasCompliancePermissionsOrAbove(user);

  const generateTabs = useMemo(() => {
    const tabs = isAdvisorOrAnalyst ? ADVISOR_TABS : TABS;
    return Object.entries(tabs).map(([key, title]) => (
      <li className={cn({ active: type === key })} key={key}>
        <Link className="btn-transparent" to={`/advisor/templates/${key}/`}>
          {title}
        </Link>
      </li>
    ));
  }, [isAdvisorOrAnalyst, type]);

  return (
    <div id="templates">
      <div className="head">
        <h2>Templates</h2>
        <span>Set customized content to share with the entire team.</span>
      </div>
      <ul className="tabs">{generateTabs}</ul>

      {type === 'assets-classification' && <AssetsClassification />}

      {(type === 'ips' || type === 'proposal') && (
        <ReportTemplates id={id} title={TABS[type]} type={type} />
      )}

      {type === 'fact-finder-forms' && <QuestionnaireTemplates id={id} />}

      {(type === 'crs' || type === 'emails' || type === 'report-disclosure') && (
        <CompanyMeta
          target={type === 'crs' ? 'crs_metadata' : type.replace(/-/g, '_')}
          title={TABS[type]}
        />
      )}

      <Disclosure />
    </div>
  );
};

Templates.propTypes = {
  params: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string
  }).isRequired
};

export default withSubscription({
  plan: 'business',
  id: 'templates',
  name: 'Centralized Templates Pack'
})(Templates);
