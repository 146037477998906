import _ from 'lodash';
import { RISK_LEVELS } from '../score-context/utils/constants';

/*
  This function converts the personality types to marks for the slider
  @return {object} marks

  The personality types have the properties: min, max, label and mark (position and value)
  The marks objects have the personality type value as key and the properties: position, range, and label.
*/

function getMarks(riskLevels) {
  // Fallback to RISK_LEVELS if riskLevels is invalid
  const levels = !_.isEmpty(riskLevels) ? riskLevels : RISK_LEVELS;

  return levels.reduce((marks, riskLevel, index) => {
    const position = index % 2 === 0 ? 'top' : 'bottom'; // Alternate position by index
    const value = (riskLevel.min + riskLevel.max) / 2; // Midpoint for the slider mark value

    marks[value] = {
      position,
      range: [riskLevel.min, riskLevel.max],
      label: riskLevel.label.en // Use the English label
    };

    return marks;
  }, {});
}

export default getMarks;
