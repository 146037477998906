import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RISK_LEVELS_COLORS } from 'app/utils/colors';
import {
  BalancedIcon,
  CautiousIcon,
  GrowthOrientedIcon,
  HighRiskTakerIcon,
  RiskAverseIcon
} from '../personality-type/pdf/icons';
import {
  HighFinancialCapacityIcon,
  LowFinancialCapacityIcon,
  ModerateFinancialCapacityIcon
} from '../risk-capacity/pdf/icons';

export const RISK_LEVELS = [
  {
    label: {
      en: 'Risk Averse',
      es: 'Conservador'
    },
    description: {
      en: 'prioritizes stability and preservation of capital over potential high returns. Favors low-risk, low-return investments such as savings accounts and bonds.',
      es: 'da prioridad a la estabilidad y preservación del capital frente a posibles rendimientos elevados. Favorece las inversiones de bajo riesgo y baja rentabilidad, como las cuentas de ahorro y los bonos.'
    },
    min: 1,
    max: 2,
    icon: '/img/icons/personality/risk-averse.svg',
    reportIcon: RiskAverseIcon,
    color: `${RISK_LEVELS_COLORS[0]}`
  },
  {
    label: {
      en: 'Cautious',
      es: 'Cauteloso'
    },
    description: {
      en: 'prioritizes limited risk and moderate returns. Favors a mix skewed towards low-risk and medium-risk investments such as short-term bonds and municipal bonds.',
      es: 'da prioridad al riesgo limitado y los rendimientos moderados. Favorece una mezcla compuesta entre inversiones de bajo y medio riesgo, como bonos a corto plazo y bonos municipales.'
    },
    min: 2.1,
    max: 4,
    icon: '/img/icons/personality/cautious.svg',
    reportIcon: CautiousIcon,
    color: `${RISK_LEVELS_COLORS[3]}`
  },
  {
    label: {
      en: 'Balanced',
      es: 'Equilibrado'
    },
    description: {
      en: 'prioritizes a balance between risk and reward. Favors a diversified mix of low-risk, medium-risk, and high-risk investments such as corporate bonds and blue-chip stocks.',
      es: 'da prioridad al equilibrio entre riesgo y recompensa. Favorece una mezcla diversificada de inversiones de bajo, medio y alto riesgo, como bonos corporativos y acciones de primera categoría.'
    },
    min: 4.1,
    max: 6,
    icon: '/img/icons/personality/balanced.svg',
    reportIcon: BalancedIcon,
    color: `${RISK_LEVELS_COLORS[5]}`
  },
  {
    label: {
      en: 'Growth Oriented',
      es: 'Centrado en el crecimiento'
    },
    description: {
      en: 'prioritizes higher returns over stability and is willing to take on some risk to achieve them. Favors investments in growing companies and industries with the potential for long-term growth.',
      es: 'da prioridad a una mayor rentabilidad frente a la estabilidad y está dispuesto a asumir cierto riesgo para conseguirla. Favorece las inversiones en empresas e industrias en expansión con potencial de crecimiento a largo plazo.'
    },
    min: 6.1,
    max: 9,
    icon: '/img/icons/personality/growth-oriented.svg',
    reportIcon: GrowthOrientedIcon,
    color: `${RISK_LEVELS_COLORS[7]}`
  },
  {
    label: {
      en: 'Aggressive',
      es: 'Arriesgado'
    },
    description: {
      en: 'prioritizes high returns and is comfortable with a higher level of risk, including the possibility of significant losses. Favors high-stakes investments such as individual stocks, hedge funds, and private equity.',
      es: 'da prioridad a los altos rendimientos y se siente cómodo con un mayor nivel de riesgo, incluida la posibilidad de pérdidas significativas. Favorece las inversiones de alto riesgo, como las acciones individuales, los fondos de cobertura y el capital de riesgo/inversión.'
    },
    min: 9.1,
    max: 10,
    icon: '/img/icons/personality/high-risk-taker.svg',
    reportIcon: HighRiskTakerIcon,
    color: `${RISK_LEVELS_COLORS[9]}`
  }
];

export const CAPACITY_RANGES = {
  LOW_CAPACITY: {
    min: 1,
    max: 3,
    label: <FormattedMessage id="score.capacity.low" />,
    description: <FormattedMessage id="score.capacity.low-description" />,
    src: '/img/icons/financial-capacity/low.svg',
    reportIcon: LowFinancialCapacityIcon
  },
  MODERATE_CAPACITY: {
    min: 3.1,
    max: 6,
    label: <FormattedMessage id="score.capacity.moderate" />,
    description: <FormattedMessage id="score.capacity.moderate-description" />,
    src: '/img/icons/financial-capacity/moderate.svg',
    reportIcon: ModerateFinancialCapacityIcon
  },
  HIGH_CAPACITY: {
    min: 6.1,
    max: 10,
    label: <FormattedMessage id="score.capacity.high" />,
    description: <FormattedMessage id="score.capacity.high-description" />,
    src: '/img/icons/financial-capacity/high.svg',
    reportIcon: HighFinancialCapacityIcon
  }
};
