import {
  CONCENTRATED_FACTOR_ATTR,
  CORRELATION_FACTOR_ATTR,
  TAIL_FACTOR_ATTR,
  VOLATILITY_FACTOR_ATTR
} from './common/prism-factor/utils';
import {
  ACCOUNT_DRIFT_LEVEL_ATTR,
  ACCOUNT_DRIFT_OVERALL_MAX_ATTR,
  ACCOUNT_DRIFT_OVERALL_MIN_ATTR,
  ACCOUNT_PRISM_CONCENTRATED_MAX_ATTR,
  ACCOUNT_PRISM_CONCENTRATED_MIN_ATTR,
  ACCOUNT_PRISM_CORRELATION_MAX_ATTR,
  ACCOUNT_PRISM_CORRELATION_MIN_ATTR,
  ACCOUNT_PRISM_OVERALL_MAX_ATTR,
  ACCOUNT_PRISM_OVERALL_MIN_ATTR,
  ACCOUNT_PRISM_TAIL_MAX_ATTR,
  ACCOUNT_PRISM_TAIL_MIN_ATTR,
  ACCOUNT_PRISM_VOLATILITY_MAX_ATTR,
  ACCOUNT_PRISM_VOLATILITY_MIN_ATTR,
  ACCOUNT_RISK_LEVEL_ATTR,
  ACCOUNT_TOTAL_MAX_ATTR,
  ACCOUNT_TOTAL_MIN_ATTR,
  ADVISOR_ATTR,
  EXCEPTION_AGE_RANGE_ATTR,
  EXCEPTION_CREATED_RANGE_ATTR,
  EXCEPTION_DRIFT_RANGE_ATTR,
  EXCEPTION_STATUS_TERMS_ATTR,
  EXCEPTION_TARGET_ATTR,
  EXCEPTION_TYPE_ATTR,
  EXCEPTION_VALUE_RANGE_ATTR,
  FILTERS,
  HOUSEHOLD_DRIFT_LEVEL_ATTR,
  HOUSEHOLD_DRIFT_OVERALL_MAX_ATTR,
  HOUSEHOLD_DRIFT_OVERALL_MIN_ATTR,
  HOUSEHOLD_PRISM_CONCENTRATED_MAX_ATTR,
  HOUSEHOLD_PRISM_CONCENTRATED_MIN_ATTR,
  HOUSEHOLD_PRISM_CORRELATION_MAX_ATTR,
  HOUSEHOLD_PRISM_CORRELATION_MIN_ATTR,
  HOUSEHOLD_PRISM_OVERALL_MAX_ATTR,
  HOUSEHOLD_PRISM_OVERALL_MIN_ATTR,
  HOUSEHOLD_PRISM_TAIL_MAX_ATTR,
  HOUSEHOLD_PRISM_TAIL_MIN_ATTR,
  HOUSEHOLD_PRISM_VOLATILITY_MAX_ATTR,
  HOUSEHOLD_PRISM_VOLATILITY_MIN_ATTR,
  HOUSEHOLD_RISK_LEVEL_ATTR,
  HOUSEHOLD_TOTAL_MAX_ATTR,
  HOUSEHOLD_TOTAL_MIN_ATTR,
  INVESTOR_DRIFT_LEVEL_ATTR,
  INVESTOR_DRIFT_OVERALL_MAX_ATTR,
  INVESTOR_DRIFT_OVERALL_MIN_ATTR,
  INVESTOR_PRISM_CONCENTRATED_MAX_ATTR,
  INVESTOR_PRISM_CONCENTRATED_MIN_ATTR,
  INVESTOR_PRISM_CORRELATION_MAX_ATTR,
  INVESTOR_PRISM_CORRELATION_MIN_ATTR,
  INVESTOR_PRISM_OVERALL_MAX_ATTR,
  INVESTOR_PRISM_OVERALL_MIN_ATTR,
  INVESTOR_PRISM_TAIL_MAX_ATTR,
  INVESTOR_PRISM_TAIL_MIN_ATTR,
  INVESTOR_PRISM_VOLATILITY_MAX_ATTR,
  INVESTOR_PRISM_VOLATILITY_MIN_ATTR,
  INVESTOR_RISK_LEVEL_ATTR,
  INVESTOR_TARGET_MODIFIED_AFTER_ATTR,
  INVESTOR_TARGET_MODIFIED_BEFORE_ATTR,
  INVESTOR_TOTAL_MAX_ATTR,
  INVESTOR_TOTAL_MIN_ATTR,
  MODEL_ADVISOR_ATTR,
  MODEL_IS_BENCHMARK_ATTR,
  MODEL_IS_STRATEGY_ATTR,
  MODEL_TYPE_ATTR,
  MODEL_VISIBILITY_ATTR,
  PRISM_FACTOR_ATTR,
  SECURITY_RISK_OVERALL_MAX_ATTR,
  SECURITY_RISK_OVERALL_MIN_ATTR,
  TEAM_ATTR
} from './constants';

export const getActiveFilters = filters => [
  ...new Set(
    Object.keys(filters).filter(
      key => FILTERS.includes(key) && (Number.isFinite(filters[key]) || filters[key])
    )
  )
];

export const getActiveFiltersCount = filters => {
  let count = 0;
  const activeFilters = getActiveFilters(filters);

  // -----------------------------------------------------------------------------------------------
  // Account
  // -----------------------------------------------------------------------------------------------

  if (activeFilters.includes(ACCOUNT_DRIFT_LEVEL_ATTR)) count += 1;
  if (
    activeFilters.includes(ACCOUNT_DRIFT_OVERALL_MAX_ATTR) ||
    activeFilters.includes(ACCOUNT_DRIFT_OVERALL_MIN_ATTR)
  )
    count += 1;
  if (
    activeFilters.includes(ACCOUNT_PRISM_CONCENTRATED_MAX_ATTR) ||
    activeFilters.includes(ACCOUNT_PRISM_CONCENTRATED_MIN_ATTR) ||
    activeFilters.includes(ACCOUNT_PRISM_CORRELATION_MAX_ATTR) ||
    activeFilters.includes(ACCOUNT_PRISM_CORRELATION_MIN_ATTR) ||
    activeFilters.includes(ACCOUNT_PRISM_OVERALL_MAX_ATTR) ||
    activeFilters.includes(ACCOUNT_PRISM_OVERALL_MIN_ATTR) ||
    activeFilters.includes(ACCOUNT_PRISM_TAIL_MAX_ATTR) ||
    activeFilters.includes(ACCOUNT_PRISM_TAIL_MIN_ATTR) ||
    activeFilters.includes(ACCOUNT_PRISM_VOLATILITY_MAX_ATTR) ||
    activeFilters.includes(ACCOUNT_PRISM_VOLATILITY_MIN_ATTR)
  )
    count += 1;
  if (activeFilters.includes(ACCOUNT_RISK_LEVEL_ATTR)) count += 1;
  if (
    activeFilters.includes(ACCOUNT_TOTAL_MAX_ATTR) ||
    activeFilters.includes(ACCOUNT_TOTAL_MIN_ATTR)
  )
    count += 1;

  // -----------------------------------------------------------------------------------------------
  // Exception
  // -----------------------------------------------------------------------------------------------

  if (activeFilters.includes(EXCEPTION_AGE_RANGE_ATTR)) count += 1;
  if (activeFilters.includes(EXCEPTION_CREATED_RANGE_ATTR)) count += 1;
  if (activeFilters.includes(EXCEPTION_DRIFT_RANGE_ATTR)) count += 1;
  if (activeFilters.includes(EXCEPTION_STATUS_TERMS_ATTR)) count += 1;
  if (activeFilters.includes(EXCEPTION_TARGET_ATTR)) count += 1;
  if (activeFilters.includes(EXCEPTION_TYPE_ATTR)) count += 1;
  if (activeFilters.includes(EXCEPTION_VALUE_RANGE_ATTR)) count += 1;

  // -----------------------------------------------------------------------------------------------
  // Household
  // -----------------------------------------------------------------------------------------------

  if (activeFilters.includes(HOUSEHOLD_DRIFT_LEVEL_ATTR)) count += 1;
  if (
    activeFilters.includes(HOUSEHOLD_DRIFT_OVERALL_MAX_ATTR) ||
    activeFilters.includes(HOUSEHOLD_DRIFT_OVERALL_MIN_ATTR)
  )
    count += 1;
  if (
    activeFilters.includes(HOUSEHOLD_PRISM_CONCENTRATED_MAX_ATTR) ||
    activeFilters.includes(HOUSEHOLD_PRISM_CONCENTRATED_MIN_ATTR) ||
    activeFilters.includes(HOUSEHOLD_PRISM_CORRELATION_MAX_ATTR) ||
    activeFilters.includes(HOUSEHOLD_PRISM_CORRELATION_MIN_ATTR) ||
    activeFilters.includes(HOUSEHOLD_PRISM_OVERALL_MAX_ATTR) ||
    activeFilters.includes(HOUSEHOLD_PRISM_OVERALL_MIN_ATTR) ||
    activeFilters.includes(HOUSEHOLD_PRISM_TAIL_MAX_ATTR) ||
    activeFilters.includes(HOUSEHOLD_PRISM_TAIL_MIN_ATTR) ||
    activeFilters.includes(HOUSEHOLD_PRISM_VOLATILITY_MAX_ATTR) ||
    activeFilters.includes(HOUSEHOLD_PRISM_VOLATILITY_MIN_ATTR)
  )
    count += 1;
  if (activeFilters.includes(HOUSEHOLD_RISK_LEVEL_ATTR)) count += 1;
  if (
    activeFilters.includes(HOUSEHOLD_TOTAL_MAX_ATTR) ||
    activeFilters.includes(HOUSEHOLD_TOTAL_MIN_ATTR)
  )
    count += 1;

  // -----------------------------------------------------------------------------------------------
  // Investor
  // -----------------------------------------------------------------------------------------------

  if (activeFilters.includes(INVESTOR_DRIFT_LEVEL_ATTR)) count += 1;
  if (
    activeFilters.includes(INVESTOR_DRIFT_OVERALL_MAX_ATTR) ||
    activeFilters.includes(INVESTOR_DRIFT_OVERALL_MIN_ATTR)
  )
    count += 1;
  if (
    activeFilters.includes(INVESTOR_PRISM_CONCENTRATED_MAX_ATTR) ||
    activeFilters.includes(INVESTOR_PRISM_CONCENTRATED_MIN_ATTR) ||
    activeFilters.includes(INVESTOR_PRISM_CORRELATION_MAX_ATTR) ||
    activeFilters.includes(INVESTOR_PRISM_CORRELATION_MIN_ATTR) ||
    activeFilters.includes(INVESTOR_PRISM_OVERALL_MAX_ATTR) ||
    activeFilters.includes(INVESTOR_PRISM_OVERALL_MIN_ATTR) ||
    activeFilters.includes(INVESTOR_PRISM_TAIL_MAX_ATTR) ||
    activeFilters.includes(INVESTOR_PRISM_TAIL_MIN_ATTR) ||
    activeFilters.includes(INVESTOR_PRISM_VOLATILITY_MAX_ATTR) ||
    activeFilters.includes(INVESTOR_PRISM_VOLATILITY_MIN_ATTR)
  )
    count += 1;
  if (activeFilters.includes(INVESTOR_RISK_LEVEL_ATTR)) count += 1;
  if (
    activeFilters.includes(INVESTOR_TARGET_MODIFIED_AFTER_ATTR) ||
    activeFilters.includes(INVESTOR_TARGET_MODIFIED_BEFORE_ATTR)
  )
    count += 1;
  if (
    activeFilters.includes(INVESTOR_TOTAL_MAX_ATTR) ||
    activeFilters.includes(INVESTOR_TOTAL_MIN_ATTR)
  )
    count += 1;

  // -----------------------------------------------------------------------------------------------
  // Model
  // -----------------------------------------------------------------------------------------------

  if (activeFilters.includes(MODEL_ADVISOR_ATTR)) count += 1;
  if (activeFilters.includes(MODEL_IS_BENCHMARK_ATTR)) count += 1;
  if (activeFilters.includes(MODEL_IS_STRATEGY_ATTR)) count += 1;
  if (activeFilters.includes(MODEL_TYPE_ATTR)) count += 1;
  if (activeFilters.includes(MODEL_VISIBILITY_ATTR)) count += 1;

  // -----------------------------------------------------------------------------------------------
  // Security
  // -----------------------------------------------------------------------------------------------

  if (
    activeFilters.includes(SECURITY_RISK_OVERALL_MAX_ATTR) ||
    activeFilters.includes(SECURITY_RISK_OVERALL_MIN_ATTR)
  )
    count += 1;

  // -----------------------------------------------------------------------------------------------
  // General
  // -----------------------------------------------------------------------------------------------

  if (activeFilters.includes(ADVISOR_ATTR)) count += 1;
  if (activeFilters.includes(PRISM_FACTOR_ATTR)) count += 1;
  if (activeFilters.includes(TEAM_ATTR)) count += 1;

  return count;
};

export const getAccountMinMaxPrismFactorAttrs = attr => {
  if (attr === CONCENTRATED_FACTOR_ATTR)
    return [ACCOUNT_PRISM_CONCENTRATED_MIN_ATTR, ACCOUNT_PRISM_CONCENTRATED_MAX_ATTR];
  if (attr === CORRELATION_FACTOR_ATTR)
    return [ACCOUNT_PRISM_CORRELATION_MIN_ATTR, ACCOUNT_PRISM_CORRELATION_MAX_ATTR];
  if (attr === TAIL_FACTOR_ATTR) return [ACCOUNT_PRISM_TAIL_MIN_ATTR, ACCOUNT_PRISM_TAIL_MAX_ATTR];
  if (attr === VOLATILITY_FACTOR_ATTR)
    return [ACCOUNT_PRISM_VOLATILITY_MIN_ATTR, ACCOUNT_PRISM_VOLATILITY_MAX_ATTR];
  return [ACCOUNT_PRISM_OVERALL_MIN_ATTR, ACCOUNT_PRISM_OVERALL_MAX_ATTR];
};

export const getHouseholdMinMaxPrismFactorAttrs = attr => {
  if (attr === CONCENTRATED_FACTOR_ATTR)
    return [HOUSEHOLD_PRISM_CONCENTRATED_MIN_ATTR, HOUSEHOLD_PRISM_CONCENTRATED_MAX_ATTR];
  if (attr === CORRELATION_FACTOR_ATTR)
    return [HOUSEHOLD_PRISM_CORRELATION_MIN_ATTR, HOUSEHOLD_PRISM_CORRELATION_MAX_ATTR];
  if (attr === TAIL_FACTOR_ATTR)
    return [HOUSEHOLD_PRISM_TAIL_MIN_ATTR, HOUSEHOLD_PRISM_TAIL_MAX_ATTR];
  if (attr === VOLATILITY_FACTOR_ATTR)
    return [HOUSEHOLD_PRISM_VOLATILITY_MIN_ATTR, HOUSEHOLD_PRISM_VOLATILITY_MAX_ATTR];
  return [HOUSEHOLD_PRISM_OVERALL_MIN_ATTR, HOUSEHOLD_PRISM_OVERALL_MAX_ATTR];
};

export const getInvestorMinMaxPrismFactorAttrs = attr => {
  if (attr === CONCENTRATED_FACTOR_ATTR)
    return [INVESTOR_PRISM_CONCENTRATED_MIN_ATTR, INVESTOR_PRISM_CONCENTRATED_MAX_ATTR];
  if (attr === CORRELATION_FACTOR_ATTR)
    return [INVESTOR_PRISM_CORRELATION_MIN_ATTR, INVESTOR_PRISM_CORRELATION_MAX_ATTR];
  if (attr === TAIL_FACTOR_ATTR)
    return [INVESTOR_PRISM_TAIL_MIN_ATTR, INVESTOR_PRISM_TAIL_MAX_ATTR];
  if (attr === VOLATILITY_FACTOR_ATTR)
    return [INVESTOR_PRISM_VOLATILITY_MIN_ATTR, INVESTOR_PRISM_VOLATILITY_MAX_ATTR];
  return [INVESTOR_PRISM_OVERALL_MIN_ATTR, INVESTOR_PRISM_OVERALL_MAX_ATTR];
};
