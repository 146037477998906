import ViralLoops from 'app/components/viral-loops';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import DiscussionTopics from './discussion-topics';
import ScoreSummary from './score-summary';
import './styles.scss';

const RTQ_SCORE_SUMMARY_STEP = 0;
const RTQ_DISCUSSION_TOPICS_STEP = 1;
const CHECK_IN_SUMMARY_STEP = 2;

// Used to display non-real content when at the `result` step and waiting
// for the investor creation process to be completed. It's used only when
// it is a default investor (anonymous).
const SCORE_SUMMARY_PLACEHOLDER = {
  appetite: 6.5,
  capacity: 10,
  tolerance: 6.5,
  overall: 6.5
};

const getInitialStep = questionnaire => {
  if (questionnaire.template.is_check_in) return CHECK_IN_SUMMARY_STEP;
  if (questionnaire.template.is_scored) return RTQ_SCORE_SUMMARY_STEP;
  return RTQ_DISCUSSION_TOPICS_STEP;
};

const QuestionnaireResult = ({
  flavor,
  investors,
  isMarketing,
  mode,
  questionnaire,
  questionnaireId,
  questionProvider,
  documentationNotes,
  onUpdateScore,
  user
}) => {
  const [step, setStep] = useState(getInitialStep(questionnaire));

  const [investor] = investors;

  const viralLoopsUser = {
    email: investor?.email,
    firstname: investor?.first_name,
    lastname: investor?.last_name
  };

  const setSummaryStep = () => {
    if (questionnaire.template.is_scored) setStep(RTQ_SCORE_SUMMARY_STEP);
  };

  const setDiscussionTopicsStep = () => {
    setStep(RTQ_DISCUSSION_TOPICS_STEP);
  };

  useEffect(() => {
    if (flavor === 'advisor' && !_.isEmpty(documentationNotes) && documentationNotes.answers_date)
      questionProvider.updateQuestionnaire(
        investor.id,
        questionnaireId,
        {
          answers_source: documentationNotes.answers_source,
          answers_date: documentationNotes.answers_date
        },
        investor.is_prospect
      );
  }, []);

  return (
    <div
      className="prism-questions-card"
      data-investor={flavor === 'investor'}
      data-is-marketing={isMarketing}
    >
      <div className="prism-result" data-is-marketing={isMarketing}>
        {step === CHECK_IN_SUMMARY_STEP && (
          <div className="check-in-summary">
            <h2 className="text-center" style={{ fontSize: '2rem' }}>
              <FormattedMessage id="check-in.thank-you" />
            </h2>
            <p className="text-lg-center">
              <FormattedMessage id="check-in.outro" values={{ br: <br /> }} />
            </p>
            <hr />
            <button
              aria-label="Ask Advisor"
              className="btn btn-link"
              onClick={setDiscussionTopicsStep}
              type="button"
            >
              <FormattedMessage id="check-in.ask-advisor" />
            </button>
          </div>
        )}

        {step === RTQ_SCORE_SUMMARY_STEP && questionnaire.template.is_scored && (
          <ScoreSummary
            flavor={flavor}
            investors={investors}
            mode={mode}
            questionnaireId={questionnaireId}
            scoreSummary={
              investor.is_default ? SCORE_SUMMARY_PLACEHOLDER : questionnaire.score_data
            }
            onUpdateScore={onUpdateScore}
            setDiscussionTopicsStep={setDiscussionTopicsStep}
          />
        )}

        {(step === RTQ_DISCUSSION_TOPICS_STEP ||
          (!questionnaire.template.is_scored && step !== CHECK_IN_SUMMARY_STEP)) && (
          <DiscussionTopics
            flavor={flavor}
            investors={investors}
            questionnaireId={questionnaireId}
            questionProvider={questionProvider}
            hasScoreSummary={questionnaire.template.is_scored}
            setSummaryStep={setSummaryStep}
            user={user}
          />
        )}
      </div>
      <ViralLoops isMarketing={isMarketing} user={viralLoopsUser} />
    </div>
  );
};

QuestionnaireResult.defaultProps = {
  investors: []
};

QuestionnaireResult.propTypes = {
  flavor: PropTypes.string.isRequired,
  investors: PropTypes.array,
  isMarketing: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  documentationNotes: PropTypes.object.isRequired,
  onQuestionnaireRestart: PropTypes.func.isRequired,
  questionnaire: PropTypes.shape({
    id: PropTypes.number.isRequired,
    score_data: PropTypes.object,
    template: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      is_scored: PropTypes.bool.isRequired
    }).isRequired
  }).isRequired,
  onUpdateScore: PropTypes.func.isRequired,
  questionnaireId: PropTypes.number.isRequired,
  questionProvider: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default connect(state => ({
  documentationNotes: state.questions.documentationNotes
}))(QuestionnaireResult);
