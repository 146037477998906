import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const CheckInIcon = ({ color, height, label, src, ...rest }) => {
  if (!src && !label) return null;
  return (
    <div className="check-in-icon" {...rest}>
      {src && (
        <div className="check-in-icon__image">
          <img alt={label} src={src} title={label} style={{ height }} />
          <div style={{ backgroundColor: color }} />
        </div>
      )}
      {label && (
        <div className="check-in-icon__label" style={{ color }}>
          {label}
        </div>
      )}
    </div>
  );
};

CheckInIcon.propTypes = {
  color: PropTypes.string.isRequired,
  height: PropTypes.number,
  label: PropTypes.string,
  src: PropTypes.string
};

CheckInIcon.defaultProps = {
  height: 40,
  label: '',
  src: ''
};

export default CheckInIcon;
