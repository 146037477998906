import { AccountExceptionPropTypes } from 'app/types';
import ScoreLine from 'components/advisor/risk-analysis/score-line';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import './styles.scss';

const AccountException = ({
  investor_is_prospect: isProspect,
  investor,
  openAccountNotesModal,
  prism,
  target_ct: targetType,
  target_name: targetName,
  target,
  tolerance_is_manual: toleranceIsManual,
  tolerance
}) => {
  const investorType = isProspect ? 'prospects' : 'investors';

  const targetLink = {
    account: `/advisor/${investorType}/${investor}/account/${target}/overview`,
    household: `/advisor/households/${target}`,
    investor: `/advisor/${investorType}/${target}/overview`
  };

  return (
    <div className="account-exception line-container">
      <div className="left">
        <strong>1</strong>
      </div>
      <ScoreLine
        score={prism.toFixed(1)}
        badgeSize="large"
        targetScore={tolerance.toFixed(1)}
        manualScore={toleranceIsManual}
        hideNumbers
      />
      <div className="right">
        <strong>10</strong>
      </div>
      <div className="bottom-row">
        <div className="bottom-row-section">
          <span>{targetName}</span>
          <div className="actions">
            <div className="box-left">
              <Link to={targetLink[targetType]} className="btn btn-secondary-2 text-capitalize">
                View {targetType}
              </Link>
            </div>
            <div className="box-right">
              <button
                onClick={openAccountNotesModal}
                className="btn btn-primary account-btn"
                type="button"
              >
                Add a note
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AccountException.propTypes = {
  ...AccountExceptionPropTypes,
  openAccountNotesModal: PropTypes.func.isRequired
};

export default AccountException;
