import CheckInIcon from 'components/advisor/investors/check-ins-history/icon';
import TooltipV2 from 'components/tooltip-v2';
import PropTypes from 'prop-types';
import React from 'react';

const InvestorCheckInCell = ({ investor }) => {
  if (!investor.checkin_data) return null;

  const {
    market_sentiment: {
      color: marketSentimentColor,
      image: marketSentimentImage,
      value: [marketSentimentValue]
    },
    financial_future: {
      color: financialFutureColor,
      image: financialFutureImage,
      value: [financialFutureValue]
    }
  } = investor.checkin_data;

  const marketSentimentTooltipId = `${investor.id}-market-sentiment-tooltip`;
  const financialFutureTooltipId = `${investor.id}-financial-future-tooltip`;

  return (
    <div className="investor-check-in-cell">
      <TooltipV2
        className="check-in-alert-tooltip"
        id={marketSentimentTooltipId}
        label={`Market Sentiment: ${marketSentimentValue}`}
        place="top"
      >
        <CheckInIcon
          color={marketSentimentColor}
          height={20}
          src={marketSentimentImage}
          data-for={marketSentimentTooltipId}
          data-tip=""
        />
      </TooltipV2>

      <TooltipV2
        className="check-in-alert-tooltip"
        id={financialFutureTooltipId}
        label={`Financial Future: ${financialFutureValue}`}
        place="top"
      >
        <CheckInIcon
          color={financialFutureColor}
          height={20}
          src={financialFutureImage}
          data-for={financialFutureTooltipId}
          data-tip=""
        />
      </TooltipV2>
    </div>
  );
};

InvestorCheckInCell.propTypes = {
  investor: PropTypes.object.isRequired
};

export default InvestorCheckInCell;
