import cn from 'classnames';
import { VerboseErrorInput as Input } from 'components/form';
import Choice from 'components/form/choice';
import { getLocale } from 'lang/utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import { reduxForm } from 'redux-form';
import { validation } from 'utils/form';
import { sanitizeUrl } from 'utils/utils';
import validator from 'validator';
import './styles.scss';
import DiscussionTopicsTop from './top';

const DISCUSSION_TOPICS_LIMIT = 3;

const DISCUSSION_TOPICS_FORM_STEP = 0;
const DISCUSSION_TOPICS_SUMMARY_STEP = 1;

const validate = values => {
  const errors = {};
  errors.other_value =
    errors.other_value || (values.other && validation.required(values.other_value));
  return errors;
};

const DiscussionTopicsIconMessage = ({ title, description }) => (
  <>
    <img
      className="discussion-topics-message__icon"
      src="/img/icons/discussion-topics-icon.svg"
      alt="Topics to discuss"
    />
    <p className="discussion-topics-message__title">{title}</p>
    <p className="discussion-topics-message__limit">{description}</p>
  </>
);

DiscussionTopicsIconMessage.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

const DiscussionTopics = ({
  fields,
  flavor,
  handleSubmit,
  intl,
  investors,
  questionnaireId,
  questionProvider,
  hasScoreSummary,
  setSummaryStep,
  user,
  valid
}) => {
  const locale = getLocale();

  const [isSaving, setIsSaving] = useState(false);
  const [selectedDiscussionTopics, setSelectedDiscussionTopics] = useState([]);
  const [step, setStep] = useState(DISCUSSION_TOPICS_FORM_STEP);

  const [investor] = investors;
  const advisor = user?.advisor || user?.investor?.advisor || {};
  const companyDiscussionTopics = useMemo(
    () => advisor?.company?.discussion_topics || {},
    [JSON.stringify(advisor)]
  );

  const updateQuestionnaire = async data => {
    const promises = [];
    investors.forEach(investor => {
      promises.push(
        questionProvider.updateQuestionnaire(
          investor.id,
          questionnaireId,
          data,
          investor.is_prospect
        )
      );
    });
    return Promise.all(promises);
  };

  const onSubmit = values => {
    setIsSaving(true);

    const discussionTopics = values.discussion_topics.map(
      key => companyDiscussionTopics[key][locale]
    );
    if (values.other)
      discussionTopics.push(
        `${intl.formatMessage({ id: 'form.other-field.title' })}: ${values.other_value}`
      );

    // defines the topics to be discussed
    updateQuestionnaire({ discussion_topics: discussionTopics })
      .then(() => {
        setSelectedDiscussionTopics(discussionTopics);
        setStep(DISCUSSION_TOPICS_SUMMARY_STEP);
      })
      .catch(() => {
        toast.error(<FormattedMessage id="rtq.result.topics.saving-error" />);
      })
      .finally(() => setIsSaving(false));
  };

  const onToggleDiscussionTopic = key => event => {
    const discussionTopics = [...fields.discussion_topics.value];
    if (event.target.checked) discussionTopics.push(key);
    else discussionTopics.splice(discussionTopics.indexOf(key), 1);
    return fields.discussion_topics.onChange(discussionTopics);
  };

  useEffect(() => {
    // defines that it's required to contact the advisor
    updateQuestionnaire({ get_in_touch: true });
  }, []);

  useEffect(() => {
    if (!fields.other.checked) fields.other_value.onChange('');
  }, [fields.other.checked]);

  const isGuest = !!user?.investor?.data?.advisor_id;
  const meetingCalendarLink = sanitizeUrl(advisor.meeting_calendar_link);
  const selectedDiscussionTopicsNumber =
    fields.discussion_topics.value.length + (fields.other.checked ? 1 : 0);
  const discussionTopicsLimitReached = selectedDiscussionTopicsNumber >= DISCUSSION_TOPICS_LIMIT;
  const hasSelectedDiscussionTopics = !_.isEmpty(selectedDiscussionTopics);

  const questionnaireURL = `/advisor/${investor.is_prospect ? 'prospects/' : 'investors/'}${
    investor.id
  }/${hasScoreSummary ? 'risk-tolerance' : 'fact-finder-forms'}`;

  return (
    <div id="discussion-topics">
      <div className="discussion-topics-message">
        {step === DISCUSSION_TOPICS_SUMMARY_STEP && (
          <>
            <p
              className={cn('discussion-topics-message__title', {
                'mb-5': isGuest || flavor === 'advisor'
              })}
            >
              <FormattedMessage id="rtq.result.title" />
            </p>
            {!isGuest && flavor === 'investor' && (
              <p className="discussion-topics-message__description">
                <FormattedMessage id="rtq.result.topics.description" values={{ br: <br /> }} />
              </p>
            )}
          </>
        )}

        {step === DISCUSSION_TOPICS_FORM_STEP && (
          <DiscussionTopicsIconMessage
            title={
              <FormattedMessage
                id="rtq.result.topics.topics-to-discuss.title"
                values={{ flavor }}
              />
            }
            description={<FormattedMessage id="rtq.result.topics.topics-to-discuss.description" />}
          />
        )}

        {step === DISCUSSION_TOPICS_SUMMARY_STEP && hasSelectedDiscussionTopics && (
          <DiscussionTopicsIconMessage
            title={
              <FormattedMessage id="rtq.result.topics.chosen-topics.title" values={{ flavor }} />
            }
            description={
              <FormattedMessage
                id="rtq.result.topics.chosen-topics.description"
                values={{ flavor }}
              />
            }
          />
        )}
      </div>

      {step === DISCUSSION_TOPICS_FORM_STEP && (
        <div className="discussion-topics-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="discussion-topics-form__options">
              {Object.keys(companyDiscussionTopics).map(key => (
                <Choice
                  checked={fields.discussion_topics.value.includes(key)}
                  disabled={
                    discussionTopicsLimitReached && !fields.discussion_topics.value.includes(key)
                  }
                  key={key}
                  title={companyDiscussionTopics[key][locale]}
                  toggle={onToggleDiscussionTopic(key)}
                />
              ))}
              <div className="form-group">
                <Choice
                  checked={fields.other.checked}
                  disabled={discussionTopicsLimitReached && !fields.other.checked}
                  title={intl.formatMessage({ id: 'form.other-field.title' })}
                  toggle={fields.other.onChange}
                />
                {fields.other.checked && (
                  <Input
                    {...fields.other_value}
                    type="text"
                    placeholder={intl.formatMessage({ id: 'form.other-field.placeholder' })}
                    className="form-control mt-2"
                    maxLength={100}
                  />
                )}
              </div>
            </div>

            <div className="discussion-topics-form__actions">
              {!isGuest && flavor === 'advisor' && investor && (
                <Link to={questionnaireURL} className="btn btn-outline-primary font-weight-normal">
                  <FormattedMessage id="rtq.common.view-answers" />
                </Link>
              )}

              <button
                className="btn btn-primary"
                disabled={!valid || !selectedDiscussionTopicsNumber || isSaving}
                type="submit"
              >
                {isSaving ? (
                  <FormattedMessage id="rtq.result.topics.saving-button" values={{ flavor }} />
                ) : (
                  <FormattedMessage id="rtq.result.topics.save-button" values={{ flavor }} />
                )}
              </button>
            </div>

            {!isGuest && flavor === 'advisor' && investor && hasScoreSummary && (
              <div className="questionnaire-actions">
                <button type="button" className="btn btn-link btn-back" onClick={setSummaryStep}>
                  &lt; <FormattedMessage id="rtq.common.back" />
                </button>
              </div>
            )}
          </form>
        </div>
      )}

      {step === DISCUSSION_TOPICS_SUMMARY_STEP && (
        <div className="discussion-topics-form">
          <DiscussionTopicsTop discussionTopics={selectedDiscussionTopics} />

          <div className="discussion-topics-form__actions">
            {!isGuest && flavor === 'advisor' && investor && (
              <Link to={questionnaireURL} className="btn btn-outline-primary font-weight-normal">
                <FormattedMessage id="rtq.common.view-answers" />
              </Link>
            )}

            {advisor.share_meeting_calendar_link &&
              validator.isURL(meetingCalendarLink) &&
              hasSelectedDiscussionTopics && (
                <a
                  aria-label="Book a Meeting"
                  className="btn btn-primary"
                  href={meetingCalendarLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FormattedMessage id="rtq.common.book-meeting" />
                </a>
              )}
          </div>

          {!isGuest && flavor === 'advisor' && (
            <div className="questionnaire-actions">
              <button
                type="button"
                className="btn btn-link btn-back"
                onClick={() => {
                  setStep(DISCUSSION_TOPICS_FORM_STEP);
                }}
              >
                &lt; <FormattedMessage id="rtq.common.back" />
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

DiscussionTopics.defaultProps = {
  investors: []
};

DiscussionTopics.propTypes = {
  fields: PropTypes.object.isRequired,
  flavor: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  investors: PropTypes.array,
  questionnaireId: PropTypes.number.isRequired,
  questionProvider: PropTypes.object.isRequired,
  hasScoreSummary: PropTypes.bool.isRequired,
  setSummaryStep: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  valid: PropTypes.bool.isRequired
};

const DiscussionTopicsWithIntl = injectIntl(DiscussionTopics);

export default reduxForm({
  form: 'discussionTopicsForm',
  fields: ['discussion_topics', 'other', 'other_value'],
  initialValues: { discussion_topics: [] },
  validate
})(DiscussionTopicsWithIntl);
