import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import CustomReactDateRangePicker from 'app/components/form/date-range-picker/custom-range';

const CustomOption = props => {
  const {
    data,
    isFocused,
    isSelected,
    getStyles,
    theme,
    customRangeValue,
    onDatePickerChange,
    innerRef,
    innerProps
  } = props;

  if (data.label === 'Custom Range') {
    const style = getStyles('option', { ...props, isFocused, isSelected });

    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          ...style,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: theme.spacing.baseUnit
        }}
        className={`custom-option ${isFocused ? 'focused' : ''} ${isSelected ? 'selected' : ''}`}
      >
        <CustomReactDateRangePicker
          value={customRangeValue}
          onChange={onDatePickerChange}
          feedbackMessage="Custom Range"
        />
      </div>
    );
  }

  // Default rendering for other options
  return <components.Option {...props}>{data.label}</components.Option>;
};

CustomOption.propTypes = {
  data: PropTypes.object.isRequired,
  isFocused: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  getStyles: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  customRangeValue: PropTypes.array.isRequired,
  onDatePickerChange: PropTypes.func.isRequired,
  innerRef: PropTypes.any.isRequired,
  innerProps: PropTypes.object.isRequired
};

const SingleSelectWithDatePickerFilter = ({ defaultValue, onChange, options, placeholder }) => {
  const initialCustomRangeValue =
    defaultValue?.label === 'Custom Range' && Array.isArray(defaultValue.value)
      ? defaultValue.value
      : [null, null];

  const [customRangeValue, setCustomRangeValue] = useState(initialCustomRangeValue);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const containerRef = useRef(null);

  const handleSelectChange = (selectedOption, actionMeta) => {
    if (selectedOption?.label === 'Custom Range')
      // Keep the menu open for "Custom Range"
      setMenuIsOpen(true);
    else {
      // Close the menu for other options
      setMenuIsOpen(false);
      onChange(selectedOption, actionMeta);
    }
  };

  const handleDatePickerChange = dateRange => {
    setCustomRangeValue(dateRange);
    // Pass "Custom Range" selection back with the updated value
    onChange({ label: 'Custom Range', value: dateRange });
  };

  // Close dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = event => {
      if (containerRef.current && !containerRef.current.contains(event.target))
        setMenuIsOpen(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="single-select-with-range--container" ref={containerRef}>
      <Select
        className="dropdown dropdown__single-select"
        classNamePrefix="select__with-date-Picker"
        defaultValue={defaultValue}
        isSearchable={false}
        menuPortalTarget={document.body}
        onChange={handleSelectChange}
        options={options}
        placeholder={placeholder}
        menuIsOpen={menuIsOpen} // Control menu visibility with state
        onMenuOpen={() => setMenuIsOpen(true)} // Allow reopening the menu explicitly
        components={{
          Option: props => (
            <CustomOption
              {...props}
              customRangeValue={customRangeValue}
              onDatePickerChange={handleDatePickerChange}
            />
          )
        }}
        styles={{
          control: styles => ({ ...styles, fontSize: '0.9rem', minHeight: 42 }),
          option: styles => ({
            ...styles,
            fontSize: '0.85rem'
          }),
          menuList: styles => ({
            ...styles,
            overflowY: 'inherit'
          }),
          menuPortal: styles => ({ ...styles, zIndex: 1051 }),
          menu: styles => ({
            ...styles,
            zIndex: 1051
          })
        }}
      />
    </div>
  );
};

SingleSelectWithDatePickerFilter.propTypes = {
  defaultValue: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string
};

SingleSelectWithDatePickerFilter.defaultProps = {
  defaultValue: {},
  placeholder: ''
};

export default SingleSelectWithDatePickerFilter;
