/* eslint-disable import/prefer-default-export */
import { QUESTIONNAIRE_TEMPLATE_URL_PARAM } from 'constants/questionnaire';

export const getExternalCheckInURL = (investor, questionnaireTemplateId) => {
  const url = new URL(investor.checkin_url);

  if (url && questionnaireTemplateId)
    url.searchParams.set(QUESTIONNAIRE_TEMPLATE_URL_PARAM, questionnaireTemplateId);

  return url?.href;
};
