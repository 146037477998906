import { Circle, Defs, G, LinearGradient, Rect, Stop, Svg, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React, { useMemo, useContext } from 'react';
import _ from 'lodash'; // Import lodash for utility functions
import {
  LIGHT_GRAPHITE_COLOR,
  PRISM_COLOR_0,
  PRISM_COLOR_5,
  PRISM_COLOR_9
} from 'reports/base/styles';
import { AdvisorContext } from 'app/containers/advisor';
import getMarks from '../constants';
import RiskRangeTooltip from './risk-range-tooltip';
import styles from './styles';

const MAX_SCORE = 10;
const SCORE_LINE_WIDTH = 400;

const RiskRanges = ({ score }) => {
  const { user } = useContext(AdvisorContext);

  const companyRiskLevels = user?.advisor?.company?.risk_levels;

  const roundedScore = Math.round(score);

  const markPositions = useMemo(() => {
    const marks = getMarks(companyRiskLevels || []);
    return Object.keys(marks).reduce((acc, key) => {
      const mark = marks[key];
      return [...acc, { ...mark, score: Number(key) }];
    }, []);
  }, [companyRiskLevels]);

  return (
    <View style={styles.container}>
      <Text style={styles.title}>What do these risk ranges mean?</Text>

      <View style={styles.riskRangeContainer}>
        <View style={styles.riskRange}>
          {markPositions
            .filter(mark => mark.position === 'top')
            .map(mark => (
              <RiskRangeTooltip
                key={mark.score}
                label={mark.label}
                range={`${mark.range[0]} to ${mark.range[1]}`}
                score={mark.score}
                style={{ position: 'absolute', left: `${mark.score * 10}%` }}
              />
            ))}
        </View>

        <View style={styles.riskRangeLineContainer}>
          <Svg
            viewBox={`0 0 ${SCORE_LINE_WIDTH} 15`}
            width={SCORE_LINE_WIDTH}
            style={styles.riskRangeLine}
          >
            <Defs>
              <LinearGradient id="prism-linear-gradient">
                <Stop offset="5%" stopColor={PRISM_COLOR_0} />
                <Stop offset="50%" stopColor={PRISM_COLOR_5} />
                <Stop offset="90%" stopColor={PRISM_COLOR_9} />
              </LinearGradient>
            </Defs>
            <G>
              <Rect
                width={SCORE_LINE_WIDTH - 5}
                x={2.5}
                y={5}
                height={5}
                fill="url('#prism-linear-gradient')"
              />
              <Circle cx={2.5} cy={7.5} r={2.5} fill={PRISM_COLOR_0} />
              <Circle cx={SCORE_LINE_WIDTH - 2.5} cy={7.5} r={2.5} fill={PRISM_COLOR_9} />
              <Rect
                width={SCORE_LINE_WIDTH}
                x={(score * SCORE_LINE_WIDTH) / MAX_SCORE}
                y={4}
                height={7}
                fill="white"
                opacity={0.75}
              />
            </G>
            <G>
              <Circle
                cx={(score * SCORE_LINE_WIDTH) / MAX_SCORE}
                cy={7.5}
                r={7.5}
                stroke={LIGHT_GRAPHITE_COLOR}
                strokeWidth={0.1}
                style={styles.score}
              />
              <Text
                textAnchor="middle"
                x={(score * SCORE_LINE_WIDTH) / MAX_SCORE}
                y={9.5}
                style={[styles.scoreText, styles[`fColor${roundedScore}`]]}
              >
                {score}
              </Text>
            </G>
          </Svg>
        </View>

        <View style={styles.riskRange}>
          {markPositions
            .filter(mark => mark.position === 'bottom')
            .map(mark => (
              <RiskRangeTooltip
                key={mark.score}
                label={mark.label}
                range={`${mark.range[0]} to ${mark.range[1]}`}
                reverse
                score={mark.score}
                style={{ position: 'absolute', left: `${mark.score * 10}%` }}
              />
            ))}
        </View>
      </View>
    </View>
  );
};

RiskRanges.propTypes = {
  score: PropTypes.number.isRequired
};

export default RiskRanges;
