/* eslint-disable import/no-cycle, react/no-did-update-set-state */
import ClientSelectAccounts from 'components/advisor/investors/client-select-accounts-modal';
import TargetScoreAction from 'components/advisor/target-score-action';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import {
  CLICK_QUESTIONNAIRE_LINK,
  SENT_QUESTIONNAIRE,
  SENT_QUESTIONNAIRE_EMAIL
} from 'constants/actstream';
import { QUESTIONNAIRE_TEMPLATE_URL_PARAM } from 'constants/questionnaire';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { trackAmplitudeEvent } from 'utils/tracking';
import './styles.scss';

const INITIAL_STATE = {
  loading: false,
  mode: null,
  action: null,
  selectingAccounts: false,
  selectedAccountIds: []
};

export class TargetScoreWizard extends Component {
  /**
   * There are different scopes or modes to generate a target score.
   *
   * The goal if this component is to determine the appropiate mode using the props and
   * the user selections, and provide an easy-to-consume way to generate the target score.
   *
   * The wizard is composed of 2 steps:
   *   - ClientSelectAccounts where the user selects the accounts related to the score.
   *   - TargetScoreAction where the user decides how to gather the target score information.
   *
   * The modes are:
   *   - investors:
   *     - when MULTIPLE INVESTORS are received.
   *   - investor:
   *     - only ONE INVESTOR received, WITHOUT ACCOUNTS.
   *     - only ONE INVESTOR received, MANY ACCOUNTS and ALL SELECTED.
   *   - account:
   *     - only ONE INVESTOR received, ONE ACCOUNT.
   *     - only ONE INVESTOR received, MANY ACCOUNTS and ONE SELECTED.
   *   - accounts:
   *     - only ONE INVESTOR received, MANY ACCOUNTS and SOME SELECTED.
   *
   * @param {investors} props list of investors/prospects.
   * @param {show} props boolean that indicates if the modal is visible or not.
   */
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.fetchAccounts = this.fetchAccounts.bind(this);
    this.hide = this.hide.bind(this);
    this.onAnwerQuestionnaire = this.onAnwerQuestionnaire.bind(this);
    this.onCopyURL = this.onCopyURL.bind(this);
    this.onHide = this.onHide.bind(this);
    this.onPrint = this.onPrint.bind(this);
    this.onSaveManualTarget = this.onSaveManualTarget.bind(this);
    this.onSelectAccounts = this.onSelectAccounts.bind(this);
    this.onSelectSpecificAccounts = this.onSelectSpecificAccounts.bind(this);
    this.onSendMail = this.onSendMail.bind(this);
    this.processAccounts = this.processAccounts.bind(this);
    this.setAction = this.setAction.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    /**
     * Update only if the modal is or was visible.
     */
    const { show } = this.props;
    return show || nextProps.show;
  }

  componentDidUpdate(prevProps) {
    /**
     * If the mode is already set OR the modal is hidden OR the modal visibility did't changed: skip.
     * If there are multiple investors: set mode
     * If there is 1 investor with accunts: process accounts
     * If there is 1 investor without: fetch accounts
     */
    const { investors, show } = this.props;
    const { mode } = this.state;
    if (mode || !show || prevProps.show) return;
    if (investors.length > 1) this.setState({ mode: 'investors' });
    else if (investors.length === 1) this.setState({ mode: 'investor' });
  }

  fetchAccounts() {
    /**
     * Fetch accounts for the given investor.
     * If it's already loading: skip
     * If the onvestor has NO accounts or ONE account: set mode
     * Otherwise activate the ClientSelectAccounts (selectingAccounts: true)
     */
    const { loading } = this.state;
    if (loading) return;

    const {
      investors: [{ id: investorId }]
    } = this.props;
    const { prospectProvider, investorProvider, isProspectSection } = this.context;
    const provider = isProspectSection ? prospectProvider : investorProvider;

    this.setState({ loading: true });

    provider
      .getAccounts(investorId)
      .then(({ data, error }) => {
        if (error || !data) toast.error(() => <div>Sorry, something went wrong.</div>);
        else this.processAccounts(data);
      })
      .catch(() => {
        toast.error(() => <div>Sorry, something went wrong.</div>);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  processAccounts(accounts) {
    /**
     * Given a list of accounts determine if the mode is "investor" (1 or no accounts)
     * or if the advisor must select the accounts that will receive target score.
     */
    const { targetAccountIds } = this.props;
    if (targetAccountIds && targetAccountIds.length) this.onSelectAccounts(targetAccountIds, true);
    else if (accounts.length > 1) this.setState({ selectingAccounts: true });
    else this.setState({ mode: 'investor' });
  }

  hide() {
    /**
     * Hide the modal
     */
    this.modal.hide();
  }

  setAction(action) {
    this.setState({ action });
  }

  onHide() {
    /**
     * Reset the modal state.
     */
    const { onHide } = this.props;
    this.setState(INITIAL_STATE, onHide);
  }

  onSelectAccounts(selectedAccountIds, force = false) {
    /**
     * Method called when ClientSelectAccounts submits a list of selected accounts.
     * Depending on the number of accounts selected the mode is set.
     * Also the ClientSelectAccounts is disabled (selectingAccounts: false)
     */
    const {
      investors: [{ accounts }]
    } = this.props;
    let mode;
    if (selectedAccountIds.length === accounts.length && !force) mode = 'investor';
    else if (selectedAccountIds.length === 1) mode = 'account';
    else mode = 'accounts';
    this.setState({ mode, selectedAccountIds, selectingAccounts: false });
  }

  onSelectSpecificAccounts() {
    const { investors } = this.props;
    const [{ accounts }] = investors;
    if (accounts) this.processAccounts(accounts);
    else this.fetchAccounts();
    this.setState({ mode: null });
  }

  onCopyURL() {
    /**
     * Method called when the advisor selects to copy the questionnaire URL.
     */
    this.log(CLICK_QUESTIONNAIRE_LINK);
  }

  onAnwerQuestionnaire(questionnaireTemplateId, isCheckInTemplate = false) {
    /**
     * Method called when the advisor selects to anwser the questionnaire.
     */
    const { routerActions } = this.context;
    const {
      investors,
      investors: [investor]
    } = this.props;
    const { mode, selectedAccountIds: accountIds } = this.state;

    const [accountId] = accountIds;
    const questionnairePath = isCheckInTemplate ? 'check-in' : 'target-rating';

    let url;

    if (mode === 'investors') {
      url = new URL(`/advisor/${questionnairePath}`, window.location.origin);
      url.searchParams.set('investors', investors.map(({ id }) => id).join(','));
    } else {
      url = new URL(
        `/advisor/${investor.is_prospect ? 'prospects' : 'investors'}/${investor.id}/${
          mode === 'account' ? `account/${accountId}/${questionnairePath}` : questionnairePath
        }`,
        window.location.origin
      );
      if (mode === 'accounts') url.searchParams.set('accounts', accountIds.join(','));
    }
    if (url && questionnaireTemplateId)
      url.searchParams.set(QUESTIONNAIRE_TEMPLATE_URL_PARAM, questionnaireTemplateId);

    this.hide();
    routerActions.push(url?.href);
  }

  onSaveManualTarget({ score }) {
    /**
     * Method called when the advisor saves a manual target score.
     */
    const { mode, selectedAccountIds } = this.state;
    const { investors, refresh } = this.props;
    const { accountProvider, investorProvider, isProspectSection, prospectProvider } = this.context;

    let items;
    let provider;
    if (mode.startsWith('investor')) {
      items = investors;
      provider = isProspectSection ? prospectProvider : investorProvider;
    } else if (mode.startsWith('account')) {
      items = investors[0].accounts.filter(a => selectedAccountIds.includes(a.id));
      provider = accountProvider;
    }

    const data = { overall: parseFloat(score) };
    Promise.all(items.map(({ id }) => provider.addManualRiskScore(id, data))).then(() => {
      refresh();
      this.hide();
      trackAmplitudeEvent('tolerance_modal.set_score', { score: data.overall });
    });
  }

  onSendMail(mails) {
    /**
     * Method called when the advisor sends an questionnaire email.
     */
    const { advisorProvider, prospectProvider, investorProvider, isProspectSection } = this.context;
    const provider = isProspectSection ? prospectProvider : investorProvider;
    const { refresh } = this.props;

    const promises = mails.map(({ investor, ...mail }) =>
      provider.sendQuestionnaireMail(investor.id, mail)
    );
    return Promise.all(promises).then(results => {
      const { success, errors } = results.reduce(
        (acum, r, index) => {
          const { investor } = mails[index];
          const name = investor.full_name;
          if (r.error) return { ...acum, errors: [...acum.errors, name] };
          return { ...acum, success: [...acum.success, investor.id] };
        },
        { success: [], errors: [] }
      );

      if (errors.length)
        toast.error(() => (
          <div>Sorry! Something went wrong while sending email to {errors.join(', ')}.</div>
        ));

      if (success.length) {
        advisorProvider.sendConfirmationEmail(
          'PRISM risk tolerance questionnaires sent successfully',
          success
        );
        this.log(SENT_QUESTIONNAIRE_EMAIL);
        refresh();
      }
      return success;
    });
  }

  /**
   * Method called when the advisor prints an empty questionnaire.
   */
  onPrint(questionnaireTemplateId) {
    const { printRiskToleranceQuestionnaire } = this.context;
    printRiskToleranceQuestionnaire(questionnaireTemplateId);
    this.log(SENT_QUESTIONNAIRE);
  }

  log(verb) {
    const { actionProvider } = this.context;
    actionProvider.storeAction({ verb });
    actionProvider.slack({ verb });
  }

  render() {
    const { action, loading, mode, selectedAccountIds, selectingAccounts } = this.state;
    const {
      investors,
      investors: [investor],
      isCheckInTemplate,
      isScoredQuestionnaireTemplate,
      show
    } = this.props;

    if (!show || loading || _.isEmpty(investor) || (!mode && !selectingAccounts)) return null;

    return (
      <Modal
        id="TargetScoreWizard"
        ref={c => (this.modal = c)}
        onHidden={this.onHide}
        show={show}
        className={action ? `modal-dialog--action-${action}` : ''}
      >
        <ModalHeader />
        <ModalBody>
          {!mode && selectingAccounts && (
            <ClientSelectAccounts
              accounts={investor.accounts}
              onSelectAccounts={this.onSelectAccounts}
            />
          )}

          {mode && (
            <TargetScoreAction
              investors={
                mode === 'investors'
                  ? investors
                  : [
                      {
                        ...investor,
                        accounts:
                          selectedAccountIds && selectedAccountIds.length
                            ? investor.accounts.filter(a => selectedAccountIds.includes(a.id))
                            : investor.accounts
                      }
                    ]
              }
              isCheckInTemplate={isCheckInTemplate}
              isScoredQuestionnaireTemplate={isScoredQuestionnaireTemplate}
              mode={mode}
              onAction={this.setAction}
              onAnwerQuestionnaire={this.onAnwerQuestionnaire}
              onClose={this.hide}
              onCopyURL={this.onCopyURL}
              onPrint={this.onPrint}
              onSaveManualTarget={this.onSaveManualTarget}
              onSelectSpecificAccounts={this.onSelectSpecificAccounts}
              onSendMail={this.onSendMail}
            />
          )}
        </ModalBody>
      </Modal>
    );
  }
}

TargetScoreWizard.propTypes = {
  investorAccounts: PropTypes.array,
  investors: PropTypes.array,
  isCheckInTemplate: PropTypes.bool,
  isScoredQuestionnaireTemplate: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  prospectAccounts: PropTypes.array,
  refresh: PropTypes.func,
  show: PropTypes.bool,
  targetAccountIds: PropTypes.arrayOf(PropTypes.number)
};

TargetScoreWizard.defaultProps = {
  investorAccounts: [],
  investors: [],
  isCheckInTemplate: false,
  isScoredQuestionnaireTemplate: true,
  prospectAccounts: [],
  refresh: () => {},
  show: false,
  targetAccountIds: []
};

TargetScoreWizard.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  actionProvider: PropTypes.object.isRequired,
  advisorProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  isProspectSection: PropTypes.bool.isRequired,
  printRiskToleranceQuestionnaire: PropTypes.func.isRequired,
  prospectProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired
};

export default connect(
  state => ({
    investorAccounts: state.investors.viewAccounts,
    prospectAccounts: state.prospects.viewAccounts
  }),
  null,
  (stateProps, dispatchProps, ownProps) => {
    /**
     * Makes sure that the investor/prospect has the accounts as a property.
     */
    const { investors, show } = ownProps;
    const { investorAccounts, prospectAccounts } = stateProps;
    if (show && investors && investors.length === 1 && !investors[0].accounts) {
      const accounts = investors[0].is_prospect ? prospectAccounts : investorAccounts;
      investors[0].accounts = accounts.filter(a => a.investor_id === investors[0].id);
    }
    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      investors
    };
  }
)(TargetScoreWizard);
