/* eslint-disable react/no-unstable-nested-components */
import PropTypes from 'prop-types';
import { AuthenticationContext } from 'containers/auth';
import React, { useContext } from 'react';
import { LANGUAGE_URL_PARAM, ENGLISH_LANGUAGE, getLocaleFromUrl } from 'lang/constants';
import { FormattedMessage } from 'react-intl';
import '../styles.scss';
import { personalityInfo } from '../utils/helpers';

const PersonalityType = ({ className, displayIcon, flavor, investorName, score, style }) => {
  const { authProvider, user } = useContext(AuthenticationContext);

  const companyRiskLevels = authProvider.isInvestor(user)
    ? user?.investor?.advisor?.company?.risk_levels
    : user?.advisor?.company?.risk_levels;

  const currentLocale = getLocaleFromUrl(LANGUAGE_URL_PARAM, ENGLISH_LANGUAGE);
  const personality = personalityInfo(score, currentLocale, companyRiskLevels);

  const level = Math.ceil(score);

  return (
    <div className={`personality-type ${className}`} style={style}>
      {displayIcon && personality.icon && (
        <img
          alt={personality.label}
          className="personality-type__icon"
          src={personality.icon}
          width={50}
        />
      )}
      <h4 className="personality-type__title">
        <FormattedMessage id="score.personality.title" values={{ flavor, investorName }} />
        <b className={`-level-${level}`}>{personality.label}</b>
      </h4>
      <p className="personality-type__meaning">
        <FormattedMessage
          id="score.personality.meaning"
          values={{
            bold: str => <span className="font-weight-bold">{str}</span>,
            flavor,
            investorName,
            maxRange: personality.max,
            minRange: personality.min,
            meaning: personality.description
          }}
        />
      </p>
    </div>
  );
};

PersonalityType.defaultProps = {
  className: '',
  displayIcon: true,
  style: {}
};

PersonalityType.propTypes = {
  className: PropTypes.string,
  displayIcon: PropTypes.bool,
  investorName: PropTypes.string.isRequired,
  flavor: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  style: PropTypes.object
};

export default PersonalityType;
