export const ENGLISH_LANGUAGE = 'en';
export const SPANISH_LANGUAGE = 'es';

export const ALLOWED_LOCALES = [ENGLISH_LANGUAGE, SPANISH_LANGUAGE];

export const LANGUAGE_URL_PARAM = 'lang';
export const SOURCE_URL_PARAM = 'source';

export const getLocaleFromUrl = (paramName, defaultLocale = ENGLISH_LANGUAGE) => {
  const url = new URL(window.location.href);
  return url.searchParams.get(paramName) || defaultLocale;
};
