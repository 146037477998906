import {
  HOUSEHOLD_GET,
  HOUSEHOLD_GET_DRIFT_LEVELS_DISTRIBUTION,
  HOUSEHOLD_GET_MEMBER_SUGGESTIONS,
  HOUSEHOLD_GET_PRISM_DISTRIBUTION,
  HOUSEHOLD_LIST,
  HOUSEHOLD_LIST_ES,
  HOUSEHOLD_UPDATE,
  HOUSEHOLD_UPDATE_SELECTED_IDS
} from 'constants/actions';
import { getUniqueObjectsArray } from 'utils/utils';

const initialState = {
  list: [],
  listMeta: {},
  listTimestamp: 0,
  selectedHouseholdIds: [],
  selectedHouseholds: [],
  suggestions: []
};

export default function households(state = initialState, action) {
  switch (action.type) {
    case HOUSEHOLD_LIST:
      return {
        ...state,
        list: action.data.data,
        listMeta: action.data.meta
      };

    case HOUSEHOLD_LIST_ES:
      return { ...state, esList: action.data };

    case HOUSEHOLD_GET:
      return { ...state, view: action.data };

    case HOUSEHOLD_UPDATE:
      return state;

    case HOUSEHOLD_GET_PRISM_DISTRIBUTION:
      return { ...state, prismDistribution: action.data };

    case HOUSEHOLD_UPDATE_SELECTED_IDS: {
      const { selectedHouseholdIds } = action.data;
      const households = getUniqueObjectsArray([...state.selectedHouseholds, ...state.list]);
      return {
        ...state,
        selectedHouseholdIds,
        selectedHouseholds: households.filter(household =>
          selectedHouseholdIds.includes(String(household.id))
        )
      };
    }

    case HOUSEHOLD_GET_DRIFT_LEVELS_DISTRIBUTION:
      return { ...state, driftLevelDistribution: action.data };

    case HOUSEHOLD_GET_MEMBER_SUGGESTIONS:
      return { ...state, suggestions: action.data };

    default:
      return state;
  }
}
