import PropTypes from 'prop-types';
import React from 'react';

const ExceptionTargetCell = ({ exception }) => {
  const iconSrc = `/img/icons/surveillance-exceptions/${exception.target_ct}.png`;
  return (
    <>
      <img src={iconSrc} alt={exception.target_ct} height={17} />
      <span>{exception.target_name}</span>
      {(exception.account_tags ?? []).map(tag => (
        <span className="account-tag">{tag}</span>
      ))}
    </>
  );
};

ExceptionTargetCell.propTypes = {
  exception: PropTypes.object.isRequired
};

export default ExceptionTargetCell;
