import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import ExtraDataSection from 'reports/base/sections/extra';
import Row from 'reports/base/table';

import { MetadataPropTypes, ProposalPropTypes } from '../../types';
import {
  BENCHMARK_ANALYTICS,
  RECOMMENDED_ANALYTICS,
  TARGET_ANALYTICS,
  getAnnualizedReturnsFromSource,
  getCumulativeReturnsFromSource,
  getHistoricalReturnsHeaders
} from '../utils';
import styles from './styles';

const ProposalHistoricalReturn = ({ breakSection, metadata, proposal }) => {
  const { body, title } = metadata;

  const cumulativeHeaders = getHistoricalReturnsHeaders(proposal.start, 'Cumulative Total Return');
  const annualizedHeaders = getHistoricalReturnsHeaders(proposal.start, 'Annualized Return');

  const targetCumulativeReturns = getCumulativeReturnsFromSource(proposal, TARGET_ANALYTICS);
  const targetAnnualizedReturns = getAnnualizedReturnsFromSource(proposal, TARGET_ANALYTICS);

  const hasRecommended = !!proposal.recommended;
  const recommendedCumulativeReturns = hasRecommended
    ? getCumulativeReturnsFromSource(proposal, RECOMMENDED_ANALYTICS)
    : null;

  const recommendedAnnualizedReturns = hasRecommended
    ? getAnnualizedReturnsFromSource(proposal, RECOMMENDED_ANALYTICS)
    : null;

  const hasBenchmark = !!proposal.benchmark;
  const benchmarkCumulativeReturns = hasBenchmark
    ? getCumulativeReturnsFromSource(proposal, BENCHMARK_ANALYTICS)
    : null;
  const benchmarkAnnualizedReturns = hasBenchmark
    ? getAnnualizedReturnsFromSource(proposal, BENCHMARK_ANALYTICS)
    : null;

  return (
    <BaseSection body={body} breakSection={breakSection} title={title} wrap={false}>
      <ExtraDataSection proposal={proposal} />
      <View style={styles.historicalReturnBox}>
        <Row
          elements={cumulativeHeaders}
          cellStyle={styles.headerCell}
          firstCellStyle={styles.headerFirstCell}
          rowStyle={styles.firstRow}
        />
        <Row
          elements={[`${proposal.target_label || 'Current Portfolio'}`, ...targetCumulativeReturns]}
          cellStyle={styles.contentCell}
          firstCellStyle={{ ...styles.contentFirstCell, ...styles.targetCell }}
        />
        {hasRecommended && (
          <Row
            elements={[proposal.recommended_label, ...recommendedCumulativeReturns]}
            cellStyle={styles.contentCell}
            firstCellStyle={{ ...styles.contentFirstCell, ...styles.recommendedCell }}
          />
        )}
        {hasBenchmark && (
          <Row
            elements={[proposal.benchmark_label, ...benchmarkCumulativeReturns]}
            cellStyle={styles.contentCell}
            firstCellStyle={{ ...styles.contentFirstCell, ...styles.benchmarkCell }}
          />
        )}
      </View>

      <View style={styles.historicalReturnBox}>
        <Row
          elements={annualizedHeaders}
          cellStyle={styles.headerCell}
          firstCellStyle={styles.headerFirstCell}
          rowStyle={styles.firstRow}
        />
        <Row
          elements={[`${proposal.target_label || 'Current Portfolio'}`, ...targetAnnualizedReturns]}
          cellStyle={styles.contentCell}
          firstCellStyle={{ ...styles.contentFirstCell, ...styles.targetCell }}
        />
        {hasRecommended && (
          <Row
            elements={[proposal.recommended_label, ...recommendedAnnualizedReturns]}
            cellStyle={styles.contentCell}
            firstCellStyle={{ ...styles.contentFirstCell, ...styles.recommendedCell }}
          />
        )}
        {hasBenchmark && (
          <Row
            elements={[proposal.benchmark_label, ...benchmarkAnnualizedReturns]}
            cellStyle={styles.contentCell}
            firstCellStyle={{ ...styles.contentFirstCell, ...styles.benchmarkCell }}
          />
        )}
      </View>
    </BaseSection>
  );
};

ProposalHistoricalReturn.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired
};

ProposalHistoricalReturn.defaultProps = {
  breakSection: false
};

export default ProposalHistoricalReturn;
