/* eslint-disable import/prefer-default-export */

const mergeCommonPositions = positions => {
  const mergedPositions = {};
  positions.forEach(position => {
    const { ticker } = position;
    if (!mergedPositions[ticker]) mergedPositions[ticker] = { ...position };
    else {
      mergedPositions[ticker].weight += position.weight;
      mergedPositions[ticker].weighted_expense_ratio += position.weighted_expense_ratio;
      mergedPositions[ticker].expense_value += position.expense_value;
    }
  });
  return Object.values(mergedPositions);
};

const getUnderlyingPositionsWithExpenses = (positions, parentWeight) =>
  positions
    .filter(position => position.expense_ratio)
    // The weight of the positions of the `security_underlying_model` is based on 100%
    // of the security, but this is not always true, so it requires adjustments based
    // on the weight of the parent position to correctly take the percentage needed.
    .map(position => ({
      ...position,
      weight: position.weight * parentWeight,
      weighted_expense_ratio: position.weighted_expense_ratio * parentWeight,
      expense_value: position.expense_value * parentWeight
    }));

export const getProcessedPositions = (proposal, breakdownCustomSecurities, type) => {
  if (!proposal[type]) return [];

  const positions = proposal[type].positions.reduce((acc, position) => {
    if (breakdownCustomSecurities && position.is_custom && position.security_underlying_model)
      return [
        ...acc,
        ...getUnderlyingPositionsWithExpenses(
          position.security_underlying_model.positions,
          position.weight
        )
      ];
    if (position.expense_ratio) return [...acc, position];
    return acc;
  }, []);

  if (breakdownCustomSecurities) return mergeCommonPositions(positions);
  return positions;
};
