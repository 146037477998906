/* global STANDARD_DATE_FORMAT */
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SECURITY_TYPE } from '../../portfolio-selector/weighted/utils';
import {
  DEFAULT_END_DATE,
  DEFAULT_STARTING_VALUE,
  DEFAULT_START_DATE,
  DEFAULT_YEARLY_WITHDRAWAL_RATE
} from '../utils';
import ProposalForm from './form';

const ProposalHeaderSecurity = ({
  loading,
  onGenerate,
  ready,
  selectedModelId,
  scope,
  templateOptions
}) => {
  const [templateSuggestions, setTemplateSuggestions] = useState([]);

  const { user, proposalProvider } = useContext(AdvisorContext);

  const handleSubmit = values => {
    values.target = [{ id: scope.id, weight: 100, type: SECURITY_TYPE }];
    if (!values.recommended) delete values.recommended;
    return onGenerate(values);
  };

  // set template suggestions
  useEffect(() => {
    const suggestions = templateOptions.map(({ id, name }) => ({ value: id, label: name }));
    setTemplateSuggestions(suggestions);
  }, [JSON.stringify(templateOptions)]);

  const initialValues = useMemo(() => {
    const initialValues = {
      benchmarkManagementFee:
        (user.advisor.company.default_benchmark_management_fee ??
          user.advisor.benchmark_management_fee) * 100,
      period: [DEFAULT_START_DATE, DEFAULT_END_DATE],
      recommendedManagementFee:
        (user.advisor.company.default_recommended_management_fee ??
          user.advisor.recommended_management_fee) * 100,
      startingValue: DEFAULT_STARTING_VALUE,
      targetManagementFee:
        (user.advisor.company.default_target_management_fee ?? user.advisor.target_management_fee) *
        100,
      template: proposalProvider.defaultTemplate ?? templateSuggestions?.[0]?.value,
      yearlyWithdrawalAmount: DEFAULT_STARTING_VALUE * (DEFAULT_YEARLY_WITHDRAWAL_RATE / 100),
      yearlyWithdrawalRate: DEFAULT_YEARLY_WITHDRAWAL_RATE
    };

    if (initialValues?.targetManagementFee)
      initialValues.targetManagementFee = Number.parseFloat(
        initialValues.targetManagementFee.toFixed(2)
      );
    if (initialValues?.recommendedManagementFee)
      initialValues.recommendedManagementFee = Number.parseFloat(
        initialValues.recommendedManagementFee.toFixed(2)
      );
    if (initialValues?.benchmarkManagementFee)
      initialValues.benchmarkManagementFee = Number.parseFloat(
        initialValues.benchmarkManagementFee.toFixed(2)
      );

    return initialValues;
  }, [
    scope,
    selectedModelId,
    proposalProvider.defaultTemplate,
    JSON.stringify(templateSuggestions)
  ]);

  return (
    <ProposalForm
      allowPrint={!loading && ready}
      initialValues={initialValues}
      loading={loading}
      onGenerate={handleSubmit}
      scope={scope}
      templateOptions={templateOptions}
      templateSuggestions={templateSuggestions}
    />
  );
};

ProposalHeaderSecurity.defaultProps = {
  selectedModelId: null
};

ProposalHeaderSecurity.propTypes = {
  loading: PropTypes.bool.isRequired,
  ready: PropTypes.bool.isRequired,
  onGenerate: PropTypes.func.isRequired,
  scope: PropTypes.object.isRequired,
  selectedModelId: PropTypes.number,
  templateOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.object.isRequired
};

export default ProposalHeaderSecurity;
