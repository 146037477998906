import cn from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { AuthenticationContext } from 'app/containers/auth';
import React, { useContext } from 'react';
import { CAPACITY_RANGES, RISK_LEVELS } from '../score-context/utils/constants';
import './styles.scss';

const Legend = ({ parentClassName, withCapacity }) => {
  const { authProvider, user } = useContext(AuthenticationContext);

  const companyRiskLevels = authProvider.isInvestor(user)
    ? user?.investor?.advisor?.company?.risk_levels
    : user?.advisor?.company?.risk_levels;

  const riskLevels = !_.isEmpty(companyRiskLevels) ? companyRiskLevels : RISK_LEVELS;

  return (
    <div className={cn('risk-legend', parentClassName)}>
      <div className="risk-legend__section">
        <h2 className="risk-legend__section-title">Risk Willingness</h2>
      </div>
      <div className="risk-legend__container">
        {riskLevels
          ? riskLevels.map((riskLevel, index) => (
              <div className="risk-legend__item" key={index}>
                <h3 className={`risk-legend__item-title risk-${riskLevel.max}`}>
                  {riskLevel.label.en}
                </h3>
                <p className="risk-legend__item-text">{riskLevel.description.en}</p>{' '}
              </div>
            ))
          : RISK_LEVELS.map((riskLevel, index) => (
              <div className="risk-legend__item" key={index}>
                <h3 className={`risk-legend__item-title risk-${riskLevel.max}`}>
                  {riskLevel.label.en}
                </h3>
                <p className="risk-legend__item-text">{riskLevel.description.en}</p>{' '}
              </div>
            ))}
      </div>
      {withCapacity && (
        <>
          <div className="risk-legend__section">
            <h2 className="risk-legend__section-title">Risk Ability</h2>
          </div>
          <div className="risk-legend__container">
            {Object.values(CAPACITY_RANGES)
              .map(capacityRange => (
                <div className="risk-legend__item" key={capacityRange.label}>
                  <img
                    className="financial-capacity__icon"
                    src={capacityRange.src}
                    alt={capacityRange.label}
                    width={30}
                  />
                  <h3 className={`risk-legend__item-title capacity-${capacityRange.max}`}>
                    {capacityRange.reportIcon}
                    {capacityRange.label}
                  </h3>
                  <p className="risk-legend__item-text">{capacityRange.description}</p>
                </div>
              ))
              .reverse()}
          </div>
        </>
      )}
    </div>
  );
};

Legend.defaultProps = {
  parentClassName: '',
  withCapacity: false
};

Legend.propTypes = {
  parentClassName: PropTypes.string,
  withCapacity: PropTypes.bool
};

export default Legend;
