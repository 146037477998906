import {
  RISK_LEVELS,
  CAPACITY_RANGES
} from 'app/containers/risk-tolerance-questionnaire/result/score-context/utils/constants';
import { ENGLISH_LANGUAGE } from 'lang/constants';

/*
  This function is used to get the score level based on the score
  @param {number} score
  @return {object} score level
*/
export const getCapacityScoreLevel = (score, levels = CAPACITY_RANGES) =>
  Object.values(levels).find(range => score >= range.min && score <= range.max) || {};

export const getRiskScoreLevel = (score, locale = ENGLISH_LANGUAGE, levels = RISK_LEVELS) => {
  const range = levels.find(range => score >= range.min && score <= range.max) || {};
  return {
    ...range,
    label: range.label?.[locale] || '',
    description: range.description?.[locale] || ''
  };
};
