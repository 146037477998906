import TooltipV2 from 'components/tooltip-v2';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const CheckInAlertIcon = ({ latestCheckIn }) => {
  if (!latestCheckIn?.get_in_touch) return null;

  const tooltipId = 'check-in-alert-tooltip';

  return (
    <TooltipV2
      className="check-in-alert-tooltip"
      id={tooltipId}
      label="The client requested to get in touch with the advisor when filling the Check-in"
      place="top"
    >
      <span
        className="fs-icon-exclamation-circle investor-overview__warning-icon"
        data-for={tooltipId}
        data-tip=""
      />
    </TooltipV2>
  );
};

CheckInAlertIcon.propTypes = {
  latestCheckIn: PropTypes.object
};

CheckInAlertIcon.defaultProps = {
  latestCheckIn: null
};

export default CheckInAlertIcon;
