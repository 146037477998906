import SendEmailForm from 'components/advisor/investors/email-form';
import { QUESTIONNAIRE_TEMPLATE_URL_PARAM } from 'constants/questionnaire';
import { AdvisorContext } from 'containers/advisor';
import { DEFAULT_CHECK_IN_EMAIL_TEMPLATE } from 'containers/advisor/templates/defaults';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

const EmailCheckInForm = ({ investor, onCancel, onSuccess, questionnaireTemplateId }) => {
  const {
    investorProvider,
    prospectProvider,
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);

  const onSubmit = async ({ email, cc, sender, subject, message }) => {
    const provider = investor.is_prospect ? prospectProvider : investorProvider;
    const data = {
      email,
      cc,
      sender,
      subject,
      message,
      investor,
      url_query_params: { [QUESTIONNAIRE_TEMPLATE_URL_PARAM]: questionnaireTemplateId }
    };
    return provider.sendCheckInMail(investor.id, data).then(() => onSuccess());
  };

  return (
    <SendEmailForm
      action={{ text: 'Begin' }}
      className="target-score-questionnaire-email"
      emailDisabled={false}
      initialValues={{
        ...DEFAULT_CHECK_IN_EMAIL_TEMPLATE,
        ...company.checkins_email,
        email: investor.email
      }}
      investor={investor}
      onCancel={onCancel}
      submit={onSubmit}
      template={{ text: 'Edit Email Template', url: '/advisor/templates/emails/' }}
      title="Send Form"
    />
  );
};

EmailCheckInForm.propTypes = {
  investor: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  questionnaireTemplateId: PropTypes.number
};

EmailCheckInForm.defaultProps = {
  investor: null,
  questionnaireTemplateId: undefined
};

export default EmailCheckInForm;
