import config from 'app/config';
import classnames from 'classnames';
import RiskGaugeChart from 'components/charts/risk-gauge-chart';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { AdvisorContext } from 'app/containers/advisor';
import { RISK_LEVELS } from 'app/containers/risk-tolerance-questionnaire/result/score-context/utils/constants';

import { isNum, numToRiskScaleString } from 'utils/utils';
import './styles.scss';

const RiskOverall = ({ id, score, target, options, compact, small, smaller, noLabels, style }) => {
  const { user } = useContext(AdvisorContext);

  const riskLevels = user?.advisor?.company?.risk_levels || RISK_LEVELS;

  if (!isNum(score)) return 'Portfolio without score';

  const scoreOverall = Math.round(score);

  const targetOverall = target ? Math.round(target) : null;

  const commonParams = {
    id,
    value: scoreOverall,
    ...{ ...(small && { radius: 70, innerRadius: 54 }) },
    ...{
      ...(smaller && {
        radius: 56,
        innerRadius: 43,
        arrowRadius: '35%',
        arrowNailRadius: 5,
        arrowStartWith: 10
      })
    },
    ...options
  };

  const withTargetParams = {
    options: {
      ...config.chart.gauge.prismTargetScoreOverall,
      ...{ ...(noLabels && { allLabels: [] }) }
    },
    target: targetOverall
  };
  const withoutTargetParams = {
    options: config.chart.gauge.targetScoreResult
  };

  return (
    <div className={classnames('RiskOverall', { compact, small: small || smaller, smaller })}>
      <div className="speedometer">
        {target ? (
          <RiskGaugeChart {...commonParams} {...withTargetParams} style={style} />
        ) : (
          <RiskGaugeChart {...commonParams} {...withoutTargetParams} style={style} />
        )}
      </div>
      <div
        className={`score-representation score-representation-${Math.ceil(
          score
        )} score-representation-full-prism`}
      >
        <div className={`overall-prism-full prism-${targetOverall ? 'with' : 'without'}-target`}>
          <span className="number">{`${Number(score).toFixed(1)} `}</span>
          {!compact && <span className="text">{numToRiskScaleString(score, riskLevels)}</span>}
        </div>
        {targetOverall && (
          <div className="overall-prism-target">
            <div className="rect-target-overall" />
            <div className="overall-target-text">
              {`Tolerance: `}
              <span className={`score-target-${targetOverall}`}>
                {`${Number(target).toFixed(1)}`}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

RiskOverall.defaultProps = {
  compact: false,
  id: null,
  noLabels: false,
  options: {},
  small: false,
  smaller: false,
  style: {},
  target: null
};

RiskOverall.propTypes = {
  compact: PropTypes.bool,
  id: PropTypes.string,
  noLabels: PropTypes.bool,
  options: PropTypes.object,
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  small: PropTypes.bool,
  smaller: PropTypes.bool,
  style: PropTypes.object,
  target: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default React.memo(RiskOverall);
