import { setBreakdownCustomSecuritiesState } from 'components/form/breakdown-custom-securities-toggle-title/utils';
import {
  ACCOUNT_UPDATE,
  GET_PROSPECT_ACCOUNTS,
  GET_PROSPECT_ACCOUNTS_WITH_HOUSEHOLD,
  GET_PROSPECT_PRISM_DISTRIBUTION,
  PROSPECTS_LIST,
  PROSPECT_CREATE,
  PROSPECT_GET,
  PROSPECT_GET_DRIFT_LEVELS_DISTRIBUTION,
  PROSPECT_LIST_ES,
  PROSPECT_NOTE_LIST,
  PROSPECT_SET_BREAKDOWN_CUSTOM_SECURITIES,
  PROSPECT_UPDATE_SELECTED_IDS
} from 'constants/actions';
import { getUniqueObjectsArray } from 'utils/utils';

export const initialState = {
  breakdownCustomSecurities: {},
  list: [],
  listMeta: {},
  notes: {},
  selectedProspectIds: [],
  selectedProspects: [],
  viewAccounts: []
};

export default function prospects(state = initialState, action) {
  switch (action.type) {
    case PROSPECTS_LIST:
      return { ...state, list: action.data.data, listMeta: action.data.meta };

    case ACCOUNT_UPDATE: {
      const incomingAccount = action.data;
      const accounts = state.viewAccounts.map(a => {
        if (a.id === incomingAccount.id) return { ...a, ...incomingAccount };
        return a;
      });
      return { ...state, viewAccounts: [...accounts] };
    }

    case PROSPECT_LIST_ES:
      return { ...state, esList: action.data };

    case PROSPECT_UPDATE_SELECTED_IDS: {
      const { selectedProspectIds } = action.data;
      const prospects = getUniqueObjectsArray([...state.selectedProspects, ...state.list]);
      return {
        ...state,
        selectedProspectIds,
        selectedProspects: prospects.filter(prospect =>
          selectedProspectIds.includes(String(prospect.id))
        )
      };
    }

    case PROSPECT_GET:
      return {
        ...state,
        breakdownCustomSecurities: setBreakdownCustomSecuritiesState(
          action.data.id,
          state.breakdownCustomSecurities
        ),
        view: action.data
      };

    case GET_PROSPECT_ACCOUNTS:
      return { ...state, viewAccounts: action.accounts };

    case GET_PROSPECT_ACCOUNTS_WITH_HOUSEHOLD:
      return { ...state, viewAccountsWithHousehold: action.accounts };

    case PROSPECT_CREATE:
      return { ...state, prospect: action.data };

    case GET_PROSPECT_PRISM_DISTRIBUTION:
      return { ...state, prismDistribution: action.data };

    case PROSPECT_GET_DRIFT_LEVELS_DISTRIBUTION:
      return { ...state, driftLevelDistribution: action.data };

    case PROSPECT_NOTE_LIST:
      return { ...state, notes: { ...state.notes, [action.target]: action.data } };

    case PROSPECT_SET_BREAKDOWN_CUSTOM_SECURITIES:
      return {
        ...state,
        breakdownCustomSecurities: { ...state.breakdownCustomSecurities, [action.id]: action.data }
      };

    default:
      return state;
  }
}
