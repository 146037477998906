import { customButton, digest, getEditorOptions } from 'components/utils/wysiwyg/options';
import {
  IPS_REVIEW_EMAIL,
  QUESTIONNAIRE_EMAIL
} from 'containers/advisor/templates/company-meta/contants';
import { validation } from 'utils/form';

export const QUESTIONNAIRE_URL = '%QUESTIONNAIRE_URL%';
export const REVIEW_URL = '%REVIEW_URL%';

export const emailEditorOptions = (target = '') => {
  const buttons = {};
  if (target === QUESTIONNAIRE_EMAIL)
    buttons.questionnaireURL = customButton('Questionnaire URL', QUESTIONNAIRE_URL);
  if (target === IPS_REVIEW_EMAIL) buttons.reviewURL = customButton('Review URL', REVIEW_URL);
  return getEditorOptions({ buttons });
};

export const validateMessage = message =>
  validation.required(message) || validation.nonEmptyHtml(message);
