import SendEmailForm from 'components/advisor/investors/email-form';
import { QUESTIONNAIRE_TEMPLATE_URL_PARAM } from 'constants/questionnaire';
import { AdvisorContext } from 'containers/advisor';
import { DEFAULT_QUESTIONNAIRE_EMAIL_TEMPLATE } from 'containers/advisor/templates/defaults';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { DUMMY_INVESTOR } from './utils';

const EmailToleranceForm = ({
  investors,
  mode,
  onCancel,
  onClose,
  onSend,
  questionnaireTemplateId,
  sent
}) => {
  const {
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);

  const onSubmit = async ({ email, cc, sender, subject, message }) => {
    const mails = investors.map(investor => ({
      email: mode === 'investors' ? investor.email : email,
      cc,
      sender,
      subject,
      message,
      investor,
      accounts: mode.startsWith('account') ? investor.accounts.map(a => a.id) : null,
      url_query_params: { [QUESTIONNAIRE_TEMPLATE_URL_PARAM]: questionnaireTemplateId }
    }));
    return onSend(mails);
  };

  const investor = mode === 'investors' ? DUMMY_INVESTOR : investors[0];

  // TODO: remove this unused snippet
  if (sent && sent.length)
    return (
      <div>
        <h3 className="text-sm-center">Thank You!</h3>
        {mode === 'investors' ? (
          <div className="text-sm-center email-copy">
            <span>Email has been sent to:</span>
            <ul>
              {investors
                .filter(i => sent.includes(i.id))
                .map(i => (
                  <li key={i.id}>
                    {i.full_name} (<a href={`mailto:${i.email}`}>{i.email}</a>)
                  </li>
                ))}
            </ul>
          </div>
        ) : (
          <div className="text-sm-center email-copy">
            Email has been sent to {investor.full_name}.
          </div>
        )}
        <div className="text-sm-center mt-2">
          <button
            type="submit"
            className="btn btn-primary btn-shadow btn-submit-email"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    );

  return (
    <SendEmailForm
      action={{ text: 'Begin' }}
      className="target-score-questionnaire-email"
      emailDisabled={mode === 'investors'}
      initialValues={{
        ...DEFAULT_QUESTIONNAIRE_EMAIL_TEMPLATE,
        ...company.questionnaire_email,
        email: investor.email
      }}
      investor={mode === 'investors' ? { ...DUMMY_INVESTOR, id: investor.id } : investor}
      onCancel={onCancel}
      submit={onSubmit}
      template={{ text: 'Edit Email Template', url: '/advisor/templates/emails/' }}
      title="Send Form"
    />
  );
};

EmailToleranceForm.propTypes = {
  account: PropTypes.object,
  accounts: PropTypes.array,
  investor: PropTypes.object,
  investors: PropTypes.array,
  mode: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
  questionnaireTemplateId: PropTypes.number,
  sent: PropTypes.arrayOf(PropTypes.number)
};

EmailToleranceForm.defaultProps = {
  account: null,
  accounts: [],
  investor: null,
  investors: [],
  questionnaireTemplateId: undefined,
  sent: []
};

export default EmailToleranceForm;
