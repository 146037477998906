import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import './styles.scss';

const CustomReactDateRangePicker = ({ value, onChange, className, feedbackMessage, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Default values
  const startDateDefault = value[0] ? moment(value[0]).toDate() : null;
  const endDateDefault = value[1] ? moment(value[1]).toDate() : null;

  // Maximum and minimum values
  const maxEndDate = moment().subtract(1, 'days').toDate();
  const minStartDate = moment('1990-01-01', 'YYYY-MM-DD').toDate();

  const handleOnChange = date => {
    let formattedDate = [];
    if (date) {
      const formattedStartDate = date[0] ? moment(date[0]).format('YYYY-MM-DD') : null;
      const formattedEndDate = date[1] ? moment(date[1]).format('YYYY-MM-DD') : null;
      formattedDate = [formattedStartDate, formattedEndDate];
    }
    onChange(formattedDate);
  };

  const formatSelectedRange = () => {
    if (startDateDefault && endDateDefault)
      return `${moment(startDateDefault).format('MMM D, YYYY')} - ${moment(endDateDefault).format(
        'MMM D, YYYY'
      )}`;

    return ''; // Default range message
  };

  const dates = [startDateDefault, endDateDefault];

  const toggleCalendar = () => {
    setIsOpen(prev => !prev); // Toggle isOpen state
  };

  return (
    <button
      type="button" // Specify button type to prevent default form submission
      id="date-range-picker--container"
      aria-expanded={isOpen} // Indicates the expanded/collapsed state
      onClick={toggleCalendar} // Toggle the calendar when clicked
    >
      {/* Span to trigger the picker */}
      <span style={{ cursor: 'pointer' }}>
        {`${feedbackMessage || 'Custom Range'}: ${formatSelectedRange()}`}
      </span>

      {/* DateRangePicker */}
      <div className="date-range-picker__calendar-container" onClick={e => e.stopPropagation()}>
        <DateRangePicker
          value={dates}
          dayPlaceholder="dd"
          yearPlaceholder="yyyy"
          format="yyyy-MM-dd"
          monthPlaceholder="MM"
          maxDate={maxEndDate}
          minDate={minStartDate}
          onChange={handleOnChange}
          rangeDivider={<span> - </span>}
          className={`stratifi-date-picker ${className}`}
          clearIcon={null}
          isOpen={isOpen} // Control the visibility of the calendar
          onCalendarClose={() => setIsOpen(false)} // Close the calendar when it loses focus
          {...rest}
        />
      </div>
    </button>
  );
};

CustomReactDateRangePicker.defaultProps = {
  className: '',
  feedbackMessage: 'Custom Range'
};

CustomReactDateRangePicker.propTypes = {
  className: PropTypes.string,
  feedbackMessage: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired
};

export default CustomReactDateRangePicker;
