import CheckInAlert from 'components/advisor/investors/check-in-alert';
import CheckInsHistory from 'components/advisor/investors/check-ins-history';
import { investorSelector } from 'components/advisor/investors/selectors';
import { AdvisorContext } from 'containers/advisor';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle';
import './styles.scss';

const CheckIns = ({ investor }) => {
  const { clientProvider, showTargetScoreWizard } = useContext(AdvisorContext);

  const [loading, setLoading] = useState(false);
  const [latestCheckIn, setLatestCheckIn] = useState(null);

  const onToggleCheckIns = () => {
    setLoading(true);
    return clientProvider
      .update({ id: investor.id, checkins_enabled: !investor.checkins_enabled })
      .then(({ data: investor }) => {
        toast.success(
          `Check-ins have been ${investor.checkins_enabled ? 'enabled' : 'disabled'} for ${
            investor.full_name
          }`
        );
        clientProvider.getLastCheckIn(investor.id).then(({ data }) => {
          if (data) setLatestCheckIn(data);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!investor) return null;

  return (
    <>
      <CheckInAlert latestCheckIn={latestCheckIn} />
      <div id="check-ins" className="content card">
        <div className="check-ins__title">
          <h1>Client Check-In History</h1>
          <div className="title__actions">
            <div className="actions__toggle">
              <div className="togggle-container">
                <Toggle
                  disabled={loading}
                  defaultChecked={investor.checkins_enabled}
                  className="toggle-primary"
                  id="toggle-benchmark-unit"
                  onChange={onToggleCheckIns}
                />
                <p className="toggle-label">
                  <span className="font-weight-bolder">Automate check-ins</span>
                  {latestCheckIn?.next_checkin_date && (
                    <span className="check-ins__next-date">
                      Next check-in: {moment(latestCheckIn.next_checkin_date).format('ll')}
                    </span>
                  )}
                </p>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => showTargetScoreWizard({ isCheckInTemplate: true })}
            >
              Send Check-in
            </button>
          </div>
        </div>
        <hr />
        <CheckInsHistory investor={investor} setLatestCheckIn={setLatestCheckIn} />
      </div>
    </>
  );
};

CheckIns.propTypes = {
  investor: PropTypes.object
};

CheckIns.defaultProps = {
  investor: null
};

export default connect(state => ({
  investor: investorSelector(state)
}))(CheckIns);
