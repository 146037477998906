import cn from 'classnames';
import CheckInAlertIcon from 'components/advisor/investors/check-in-alert/icon';
import { HIDE_MEEETING_CALENDAR_ALERT } from 'components/advisor/meeting-calendar-alert';
import DisplayScore from 'components/utils/DisplayScore';
import Link from 'components/utils/link';
import tabs from 'constants/tabs';
import NewTag from 'containers/advisor/new-tag';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { trackAmplitudeEvent } from 'utils/tracking';
import { getInvestorTargetScore } from 'utils/utils';

class InvestorDetailsView extends Component {
  constructor(props) {
    super(props);
    this.state = { latestCheckIn: null, tab: tabs.OVERVIEW };
  }

  componentDidMount() {
    const { clientProvider } = this.context;
    const { investor } = this.props;
    const { tab } = this.state;

    this.handleAmplitudeEvent(tab);
    this.setCurrentTab();

    clientProvider.getLastCheckIn(investor.id).then(({ data }) => {
      this.setState({ latestCheckIn: data });
    });
  }

  componentDidUpdate() {
    this.setCurrentTab();
  }

  handleAmplitudeEvent(tabName) {
    const { investor, location } = this.props;
    const eventName = location?.pathname?.includes('advisor/investors')
      ? 'client.viewed'
      : 'prospect.viewed';

    trackAmplitudeEvent(eventName, { id: investor?.advisor?.user?.id, tab: tabName });
  }

  setCurrentTab() {
    const { location } = this.props;
    const { tab } = this.state;

    Object.entries(tabs).some(([_, value]) => {
      if (location.pathname.includes(value) && tab !== value) {
        this.handleAmplitudeEvent(value);
        this.goTo(value);
        return null;
      }
      return null;
    });
  }

  goTo = tab => {
    this.setState({ tab });
  };

  render() {
    const {
      user,
      user: {
        advisor: { meeting_calendar_link: meetingCalendarLink }
      },
      userProvider
    } = this.context;
    const { accounts, investor, children } = this.props;
    const { latestCheckIn, tab } = this.state;

    const { aggregated_prism_scores: aggregatedPrismScores, total_exceptions: totalException } =
      investor;

    const isEntry = userProvider.isEntry(user);
    const prismOverall = aggregatedPrismScores && aggregatedPrismScores.overall;
    const targetScore = getInvestorTargetScore(investor);
    const targetOverall = targetScore.data ? targetScore.data.overall : null;
    const clientType = investor.is_prospect ? 'prospects' : 'investors';
    const somePrismOverall = _.some(accounts, 'prism_score_summary');
    const isSomeAccountStrategy = _.some(accounts, 'target_model.is_strategy');
    const showMeetingCalendarAlert =
      !meetingCalendarLink &&
      !(
        window.localStorage && window.localStorage.getItem(HIDE_MEEETING_CALENDAR_ALERT) === 'true'
      );

    return (
      <div className="investor-details-view">
        <ul className="tabs">
          <li className={cn({ active: tab === tabs.OVERVIEW })}>
            <Link to={`/advisor/${clientType}/${investor.id}/${tabs.OVERVIEW}`}>Overview</Link>
          </li>

          <li
            className={cn({ active: tab === tabs.RISK_TOLERANCE })}
            style={{ position: 'relative' }}
          >
            <Link to={`/advisor/${clientType}/${investor.id}/${tabs.RISK_TOLERANCE}`}>
              {targetOverall && (
                <DisplayScore
                  score={targetOverall}
                  className="header"
                  manualScore={targetScore.manual}
                />
              )}{' '}
              Risk Tolerance
            </Link>
            {showMeetingCalendarAlert && (
              <span className="fs-icon-exclamation-circle investor-overview__warning-icon" />
            )}
          </li>

          {user.advisor.company.checkins_enabled && user.advisor.company.checkins_available && (
            <li className={cn({ active: tab === tabs.CHECK_INS })} style={{ position: 'relative' }}>
              <Link
                to={`/advisor/${clientType}/${investor.id}/${tabs.CHECK_INS}`}
                disabled={!prismOverall}
              >
                Check-Ins
                {!latestCheckIn?.get_in_touch && (
                  <div className="tabs__new-tag new-tag--check-ins">
                    <NewTag />
                  </div>
                )}
              </Link>
              <CheckInAlertIcon latestCheckIn={latestCheckIn} />
            </li>
          )}

          <li className={cn({ active: tab === tabs.PRISM })}>
            <Link
              to={`/advisor/${clientType}/${investor.id}/${tabs.PRISM}`}
              disabled={!prismOverall}
            >
              {prismOverall && (
                <DisplayScore
                  score={prismOverall}
                  className="header"
                  isStrategy={isSomeAccountStrategy}
                />
              )}{' '}
              Portfolio Risk
            </Link>
          </li>

          <li className={cn({ active: tab === tabs.PORTFOLIO_INSIGHTS })}>
            <Link
              to={`/advisor/${clientType}/${investor.id}/${tabs.PORTFOLIO_INSIGHTS}`}
              disabled={!prismOverall}
            >
              Portfolio Insights
              <div className="tabs__new-tag">
                <NewTag />
              </div>
            </Link>
          </li>

          <li className={cn({ active: tab === tabs.FACT_FINDER_FORMS })}>
            <Link to={`/advisor/${clientType}/${investor.id}/${tabs.FACT_FINDER_FORMS}`}>
              Fact Finder Forms
            </Link>
          </li>

          <li className={cn({ active: tab === tabs.IPS })}>
            <Link
              to={`/advisor/${clientType}/${investor.id}/${tabs.IPS}`}
              disabled={!somePrismOverall}
            >
              IPS
            </Link>
          </li>

          <li className={cn({ active: tab === tabs.PROPOSAL })}>
            <Link
              to={`/advisor/${clientType}/${investor.id}/${tabs.PROPOSAL}`}
              disabled={!somePrismOverall}
            >
              Proposal
            </Link>
          </li>

          <li className={cn({ active: tab === tabs.INVESTMENT_OBJECTIVES })}>
            <Link to={`/advisor/${clientType}/${investor.id}/${tabs.INVESTMENT_OBJECTIVES}`}>
              Objectives
            </Link>
          </li>

          <li className={cn({ active: tab === tabs.COMPLIANCE_NOTES })}>
            <Link
              to={`/advisor/${clientType}/${investor.id}/${tabs.COMPLIANCE_NOTES}`}
              disabled={isEntry}
            >
              <span className={totalException ? 'total red' : 'total'}>{totalException || 0}</span>{' '}
              Exception(s) &amp; Notes
            </Link>
          </li>
        </ul>
        {children}
      </div>
    );
  }
}

InvestorDetailsView.contextTypes = {
  clientProvider: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userProvider: PropTypes.object.isRequired
};

InvestorDetailsView.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.object,
  investor: PropTypes.object.isRequired,
  investorAccounts: PropTypes.array,
  location: PropTypes.object.isRequired
};

InvestorDetailsView.defaultProps = {
  accounts: null,
  children: null,
  investorAccounts: []
};

export default withRouter(InvestorDetailsView);
