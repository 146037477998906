/* eslint-disable react/no-unstable-nested-components */
import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from '@tanstack/react-table';
import cn from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import DynamicTableActionButton from './action-button';
import DynamicTableDeleteModal from './delete-modal';
import DynamicTableFiltersModal from './filters-modal';
import './styles.scss';
import {
  PAGINATION_VALUES,
  expanderColumn,
  getDefaultPageSize,
  initializeTableState,
  selectColumn,
  setDefaultPageSize
} from './utils';

/**
 * Base component used to create a dynamic content table (server-side) capable of abstracting
 * selection, sorting, filtering and searching.
 *
 * https://tanstack.com/table/v8/docs/framework/react/guide/table-state
 * https://tanstack.com/table/v8/docs/framework/react/examples/expanding
 * https://tanstack.com/table/v8/docs/framework/react/examples/pagination-controlled
 * https://tanstack.com/table/v8/docs/framework/react/examples/row-selection
 * https://tanstack.com/table/v8/docs/framework/react/examples/sorting
 */
const DynamicTable = ({
  columns: sourceColumns,
  data,
  debugTable,
  enableExpanding,
  enableRowSelection,
  enableSorting,
  externalFilters,
  filterComponents,
  forbiddenDelete,
  headerFixedActions,
  headerSelectionActions,
  initialParams,
  label,
  meta,
  hasFilter,
  onDeleteSelectedItems,
  onFetchData,
  onSelectItems,
  selectedItemIds,
  subComponent: SubComponent,
  subComponentProps,
  withAdvisorFilter,
  withTeamFilter
}) => {
  const [expanded, setExpanded] = useState({});
  const [initialLoad, setInitialLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: getDefaultPageSize() });
  const [previousOrNextPageChange, setPreviousOrNextPageChange] = useState(false);
  const [rowCount, setRowCount] = useState(1);
  const [rowSelection, setRowSelection] = useState({});

  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState([]);

  const columns = [
    ...sourceColumns
      .filter(sc => !sc.hidden)
      .map(sc => ({
        id: sc.id,
        accessorFn: sc.accessorFn,
        enableSorting: sc.enableSorting,
        meta: sc?.meta ?? {},
        header: () => <span>{sc.name}</span>,
        cell: data => {
          if (sc.cell) return sc.cell(data.row.original);
          return data.getValue();
        },
        footer: ({ column }) => column.id
      }))
  ];
  if (enableRowSelection) columns.unshift(selectColumn);
  if (enableExpanding) columns.push(expanderColumn);

  const table = useReactTable({
    columns,
    data,
    debugTable,
    enableExpanding,
    // enable row selection for all rows: `enableRowSelection: true`
    // or enable row selection conditionally per row:
    // `enableRowSelection: row => row.original.age > 18`
    enableRowSelection,
    enableSorting,
    enableSubRowSelection: false,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowId: row => row.id,
    getSubRows: row => row.subRows,
    manualPagination: true,
    manualSorting: true,
    onExpandedChange: setExpanded,
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    rowCount,
    state: { expanded, pagination, rowSelection, sorting }
  });

  const onFetchDataHandler = async (newParams = {}) => {
    setLoading(true);
    const oldParams = meta?.params || {};
    const params = {
      ...oldParams,
      ordering: sorting.map(s => `${s.desc ? '-' : ''}${s.id}`),
      page_size: pagination.pageSize,
      page: pagination.pageIndex + 1,
      search: search || undefined,
      ...newParams
    };
    return onFetchData(params)
      .then(data => {
        setRowCount(data.count ?? 1);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // -----------------------------------------------------------------------------------------------
  // Search
  // -----------------------------------------------------------------------------------------------

  const debouncedSearch = useCallback(
    _.debounce(value => {
      onFetchDataHandler({ search: value || undefined, page: 1 });
    }, 750),
    [JSON.stringify(meta?.params), JSON.stringify(pagination), JSON.stringify(sorting)]
  );

  const onSearchHandler = event => {
    setSearch(event.target.value);
    setPagination(prevPagination => ({ ...prevPagination, pageIndex: 0 }));
    debouncedSearch(event.target.value);
  };

  useEffect(() => {
    if (meta?.params?.search)
      setExpanded(data.reduce((acc, item) => ({ ...acc, [item.id]: true }), {}));
    else table.resetExpanded();
  }, [JSON.stringify(meta?.params)]);

  // -----------------------------------------------------------------------------------------------
  // Pagination
  // -----------------------------------------------------------------------------------------------

  const debouncedPageChange = useCallback(
    _.debounce(value => {
      onFetchDataHandler({ page: value + 1 });
    }, 500),
    [pagination.pageSize, search, JSON.stringify(meta?.params), JSON.stringify(sorting)]
  );

  const onNextPageChangeHandler = () => {
    setPreviousOrNextPageChange(true);
    table.nextPage();
  };

  const onPreviousPageChangeHandler = () => {
    setPreviousOrNextPageChange(true);
    table.previousPage();
  };

  const onPageChangeHandler = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    table.setPageIndex(page);
    debouncedPageChange(page);
  };

  const onPageSizeChangeHandler = event => {
    const pageSize = Number(event.target.value);
    table.setPageSize(pageSize);
    setDefaultPageSize(pageSize);
  };

  const getRecordsCount = () => {
    if (rowCount === 0) return 'No records';
    if (rowCount === 1) return `${rowCount} record`;
    return `${rowCount} records`;
  };

  useEffect(() => {
    if (!initialLoad) onFetchDataHandler();
  }, [pagination.pageSize, JSON.stringify(sorting)]);

  useEffect(() => {
    if (previousOrNextPageChange) {
      onFetchDataHandler();
      setPreviousOrNextPageChange(false);
    }
  }, [pagination.pageIndex]);

  // -----------------------------------------------------------------------------------------------
  // Selection
  // -----------------------------------------------------------------------------------------------

  const selectedRows = Object.keys(rowSelection);

  const clearAllSelectedRows = () => {
    setRowSelection({});
  };

  useEffect(() => {
    if (onSelectItems) onSelectItems(selectedRows);
  }, [JSON.stringify(rowSelection)]);

  useEffect(() => {
    if (!selectedItemIds.length && !!selectedRows.length) setRowSelection({});
  }, [JSON.stringify(selectedItemIds)]);

  // -----------------------------------------------------------------------------------------------
  // Sorting & Filtering
  // -----------------------------------------------------------------------------------------------

  useEffect(() => {
    if (!initialLoad) onFetchDataHandler(externalFilters);
  }, [JSON.stringify(externalFilters)]);

  const getSorting = header => {
    if (enableSorting && header.column.getCanSort()) {
      const order = header.column.getIsSorted();
      return (
        <img
          src={order ? `/img/sorting-${order}.svg` : '/img/sorting.svg'}
          className="column-sorting"
          alt="Sorting"
        />
      );
    }
    return null;
  };

  const onChangeFiltersHandler = filters => {
    setLoadingFilters(true);
    return onFetchDataHandler(filters).finally(() => setLoadingFilters(false));
  };

  // -----------------------------------------------------------------------------------------------
  // Initialization
  // -----------------------------------------------------------------------------------------------

  useEffect(() => {
    const initialization = async () => {
      const params = meta?.params || {};
      initializeTableState(params, initialParams, setPagination, setSearch, setSorting);
      await onFetchDataHandler(params);
      setInitialLoad(false);
    };
    initialization();
  }, []);

  return (
    <div className="sdt">
      <div className="sdt__filters">
        {!!selectedRows.length && (
          <div className="filters__selected">
            <DynamicTableActionButton
              iconId="xmark-solid"
              label="Clear selection"
              onClick={clearAllSelectedRows}
            />
            <span>{selectedRows.length} selected</span>
          </div>
        )}

        <div className="filters__center">
          {onDeleteSelectedItems && (
            <DynamicTableDeleteModal
              forbiddenDelete={forbiddenDelete}
              items={selectedRows}
              label={label}
              onDelete={onDeleteSelectedItems}
              setRowSelection={setRowSelection}
            />
          )}
          {headerSelectionActions}
        </div>

        <div className="filters__right">
          <div className="right__search">
            <input
              className="form-control"
              disabled={loadingFilters || initialLoad}
              onChange={onSearchHandler}
              placeholder="Search ..."
              value={search}
            />
            <span className="icon-search_icn" />
          </div>
          {headerFixedActions(loading)}
          {hasFilter && (
            <DynamicTableFiltersModal
              filterComponents={filterComponents}
              initialParams={initialParams}
              label={label}
              loading={loading}
              meta={meta}
              onChangeFilters={onChangeFiltersHandler}
              table={table}
              withAdvisorFilter={withAdvisorFilter}
              withTeamFilter={withTeamFilter}
            />
          )}
        </div>
      </div>

      <div className={cn('sdt__table', { 'sdt__table--loading': loading })}>
        <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const meta = header.column.columnDef?.meta || {};
                  const columnProps = {
                    className: meta?.className ? meta.className(header.getContext()) : undefined,
                    style: meta?.headerStyle ? meta.headerStyle(header.getContext()) : {}
                  };
                  if (enableSorting && header.column.getCanSort())
                    columnProps.style = { ...columnProps.style, cursor: 'pointer' };
                  return (
                    <th
                      {...columnProps}
                      colSpan={header.colSpan}
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                        {getSorting(header)}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {!table.getRowModel().rows.length && (
              <tr>
                <td colSpan={table.getAllColumns().length} className="text-center">
                  No data available
                </td>
              </tr>
            )}
            {table.getRowModel().rows.map(row => (
              <Fragment key={row.id}>
                {/* the condition `row.depth === 0` avoids rendering the child rows with the parent structure */}
                {row.depth === 0 && (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => {
                      const meta = cell.column.columnDef?.meta || {};
                      const columnProps = {
                        className: meta?.className ? meta.className(cell.getContext()) : undefined,
                        style: meta?.style ? meta.style(cell.getContext()) : {}
                      };
                      return (
                        <td {...columnProps} key={cell.id}>
                          <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
                        </td>
                      );
                    })}
                  </tr>
                )}
                {row.getIsExpanded() && SubComponent && (
                  <SubComponent data={row.original} {...subComponentProps} />
                )}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>

      <div className="sdt__pagination">
        <div className="pagination__options">
          <div className="options__records">
            <span>{getRecordsCount()}</span>
          </div>
          <button
            type="button"
            className="btn btn-primary"
            disabled={!table.getCanPreviousPage()}
            onClick={onPreviousPageChangeHandler}
          >
            Previous
          </button>
          <div className="options__page">
            <span>Page</span>
            <input
              className="form-control"
              max={Number(table.getPageCount())}
              min={1}
              onChange={onPageChangeHandler}
              type="number"
              value={table.getState().pagination.pageIndex + 1}
            />
            <span>of {table.getPageCount()}</span>
          </div>
          <button
            type="button"
            className="btn btn-primary"
            disabled={!table.getCanNextPage()}
            onClick={onNextPageChangeHandler}
          >
            Next
          </button>
          <div className="options__page-size">
            <select
              disabled={loading}
              onChange={onPageSizeChangeHandler}
              value={table.getState().pagination.pageSize}
            >
              {PAGINATION_VALUES.map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize} / page
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

DynamicTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  debugTable: PropTypes.bool,
  enableExpanding: PropTypes.bool,
  enableRowSelection: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  enableSorting: PropTypes.bool,
  externalFilters: PropTypes.object,
  filterComponents: PropTypes.array,
  forbiddenDelete: PropTypes.bool,
  headerFixedActions: PropTypes.func,
  headerSelectionActions: PropTypes.oneOfType([PropTypes.object, PropTypes.element]),
  initialParams: PropTypes.object,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object,
  hasFilter: PropTypes.bool,
  onDeleteSelectedItems: PropTypes.func,
  onFetchData: PropTypes.func.isRequired,
  onSelectItems: PropTypes.func,
  selectedItemIds: PropTypes.array,
  subComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  subComponentProps: PropTypes.object,
  withAdvisorFilter: PropTypes.bool,
  withTeamFilter: PropTypes.bool
};

DynamicTable.defaultProps = {
  columns: [],
  data: [],
  debugTable: false,
  enableExpanding: false,
  enableRowSelection: false,
  enableSorting: true,
  externalFilters: {},
  filterComponents: [[]],
  forbiddenDelete: false,
  headerFixedActions: () => {},
  headerSelectionActions: null,
  initialParams: {},
  meta: {},
  hasFilter: true,
  onDeleteSelectedItems: null,
  onSelectItems: null,
  selectedItemIds: [],
  subComponent: null,
  subComponentProps: {},
  withAdvisorFilter: true,
  withTeamFilter: true
};

export default DynamicTable;
