/* global STANDARD_DATE_FORMAT */
import { ACCOUNT_PROPOSAL_TYPE } from 'components/advisor/proposal/constants';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { capFirstChar, getSuggestedModels } from 'utils/utils';
import { MODEL_PORTFOLIO_TYPE } from '../../portfolio-selector/weighted/utils';
import '../common/styles.scss';
import {
  DEFAULT_END_DATE,
  DEFAULT_STARTING_VALUE,
  DEFAULT_START_DATE,
  DEFAULT_YEARLY_WITHDRAWAL_RATE,
  portfoliosToSuggestions
} from '../utils';
import ProposalForm from './form';
import { REPORTS_BASE_URL_ENDS_WITH_ID } from './form/constants';
import { getRecommendedModelPortfolios } from './utils';

const ProposalHeaderInvestor = ({
  accountOptions,
  entityId,
  isIPS,
  loading,
  managers,
  modelOptions,
  onGenerate,
  proposalType,
  ready,
  scope,
  templateOptions
}) => {
  const { user, ipsProvider, proposalProvider } = useContext(AdvisorContext);

  const investorOptions = accountOptions.filter(a => a.investor_id === scope.id) || [];
  const investor = proposalType === ACCOUNT_PROPOSAL_TYPE ? scope.investor : scope;

  const [targetSuggestions, setTargetSuggestions] = useState([]);
  const [selectedTarget, setSelectedTarget] = useState(investorOptions);

  const getRecommendedModelPortfolioByUrl = targetValue => {
    const params = new URLSearchParams(window.location.search);
    const recommendedId = params.get('recommended');

    if (!recommendedId) return undefined;

    const model = modelOptions.find(model => model.id === Number(recommendedId));
    if (model) model.value = targetValue;
    return model;
  };

  const handleSubmit = values => {
    onGenerate(values);
  };

  const handleTargetChange = (portfolios, newTargetSuggestions) => {
    const selectedTargets =
      accountOptions.filter(a => portfolios.find(p => p.value === a.id)) || [];
    setSelectedTarget(selectedTargets);
    // condition to overwrite the current suggestions
    if (newTargetSuggestions) setTargetSuggestions(newTargetSuggestions);
  };

  // set target suggestions
  useEffect(() => {
    const investorSuggestions = portfoliosToSuggestions(investorOptions, 'account');
    const householdOptions = accountOptions.filter(a => a.investor_id !== scope.id) || [];
    const householdSuggestions = portfoliosToSuggestions(householdOptions, 'account', true);
    const suggestions = [
      { label: `${capFirstChar(proposalType)}'s accounts`, options: investorSuggestions }
    ];

    if (householdSuggestions.length && scope.household)
      suggestions.push({
        label: 'HOUSEHOLD',
        options: [
          {
            amount: householdOptions.reduce((a, b) => a + b.value, 0),
            label: `${scope.household.name} (Entire Household)`,
            suggestions: householdSuggestions,
            score: scope.household.aggregated_prism_scores.overall.toFixed(1),
            type: 'household',
            value: 0,
            isFullHousehold: true,
            first_name: '',
            full_name: '',
            id: 0
          }
        ]
      });

    setTargetSuggestions(suggestions);
  }, [JSON.stringify(accountOptions), proposalType, scope]);

  // set template suggestions
  const templateSuggestions = useMemo(() => {
    const published = { label: 'APPROVED', options: [] };
    const draft = { label: 'NOT APPROVED', options: [] };

    templateOptions.forEach(template => {
      if (template.status === 'published') published.options.push(template);
      if (template.status === 'draft') draft.options.push(template);
    });

    return [published, draft];
  }, [JSON.stringify(templateOptions)]);

  const initialValues = useMemo(() => {
    const targetValue =
      selectedTarget.reduce((acum, t) => acum + t.value, 0) || DEFAULT_STARTING_VALUE;

    // Get the matching models for the selected target and format them as a list of suggestions.
    // If there is a model portfolio recommended through the URL, it will have priority.
    const recommendedByUrl = getRecommendedModelPortfolioByUrl(targetValue);
    const [, matchingModels] = getSuggestedModels(selectedTarget, modelOptions);
    const defaultRecommended = portfoliosToSuggestions(
      recommendedByUrl ? [recommendedByUrl] : matchingModels,
      MODEL_PORTFOLIO_TYPE
    );

    const defaultTemplate =
      (isIPS ? ipsProvider.defaultTemplate : proposalProvider.defaultTemplate) ??
      templateSuggestions?.[0]?.options?.[0]?.value;

    const initialValues = {
      benchmarkManagementFee:
        (user.advisor.company.default_benchmark_management_fee ??
          user.advisor.benchmark_management_fee) * 100,
      benchmarkWithPercentages: true,
      period: [DEFAULT_START_DATE, DEFAULT_END_DATE],
      recommended: getRecommendedModelPortfolios(defaultRecommended),
      recommendedManagementFee:
        (user.advisor.company.default_recommended_management_fee ??
          user.advisor.recommended_management_fee) * 100,
      recommendedWithPercentages: true,
      startingValue: targetValue,
      targetManagementFee:
        (user.advisor.company.default_target_management_fee ?? user.advisor.target_management_fee) *
        100,
      template: defaultTemplate,
      yearlyWithdrawalAmount: targetValue * (DEFAULT_YEARLY_WITHDRAWAL_RATE / 100),
      yearlyWithdrawalRate: DEFAULT_YEARLY_WITHDRAWAL_RATE
    };

    // if there are recommended models by default, this means that there are existing matching models
    // that should be reflected, so the form will be configured to show $ instead of % by default,
    // and will assign as the weight of each model, its respective amount instead
    const requiresFormInitialization = REPORTS_BASE_URL_ENDS_WITH_ID.test(window.location.pathname);
    if (!requiresFormInitialization && !!initialValues?.recommended?.length) {
      initialValues.recommendedWithPercentages = false;
      initialValues.recommended = initialValues.recommended.map(model => ({
        ...model,
        weight: model.amount
      }));
    }

    if (initialValues?.targetManagementFee)
      initialValues.targetManagementFee = Number.parseFloat(
        initialValues.targetManagementFee.toFixed(2)
      );
    if (initialValues?.recommendedManagementFee)
      initialValues.recommendedManagementFee = Number.parseFloat(
        initialValues.recommendedManagementFee.toFixed(2)
      );
    if (initialValues?.benchmarkManagementFee)
      initialValues.benchmarkManagementFee = Number.parseFloat(
        initialValues.benchmarkManagementFee.toFixed(2)
      );

    initialValues.target =
      proposalType === ACCOUNT_PROPOSAL_TYPE
        ? [
            {
              label: scope.display_name || scope.name,
              score: scope.prism_score_summary?.overall,
              amount: scope.value,
              value: scope.id,
              weight: 100,
              type: 'account'
            }
          ]
        : portfoliosToSuggestions(investorOptions, 'account');

    return initialValues;
  }, [
    proposalType,
    JSON.stringify(investorOptions),
    JSON.stringify(modelOptions),
    JSON.stringify(selectedTarget),
    JSON.stringify(templateSuggestions),
    JSON.stringify(user)
  ]);

  return (
    <ProposalForm
      allowPrint={!loading && ready}
      entityId={entityId}
      initialValues={initialValues}
      investor={investor}
      isIPS={isIPS}
      loading={loading}
      managers={managers}
      onGenerate={handleSubmit}
      onTargetChange={handleTargetChange}
      proposalType={proposalType}
      scope={selectedTarget}
      targetSuggestions={targetSuggestions}
      templateOptions={templateOptions}
      templateSuggestions={templateSuggestions}
    />
  );
};

ProposalHeaderInvestor.defaultProps = {
  entityId: null,
  isIPS: false,
  selectedModelId: null
};

ProposalHeaderInvestor.propTypes = {
  accountOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  entityId: PropTypes.number,
  isIPS: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  managers: PropTypes.array.isRequired,
  modelOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onGenerate: PropTypes.func.isRequired,
  proposalType: PropTypes.string.isRequired,
  ready: PropTypes.bool.isRequired,
  scope: PropTypes.object.isRequired,
  selectedModelId: PropTypes.number,
  templateOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ProposalHeaderInvestor;
