/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { ROLES } from 'providers/auth';
import Impersonate from 'components/advisor/impersonate';
import './styles.scss';

class NavigationAdvisor extends Component {
  static contextTypes = {
    authProvider: PropTypes.object.isRequired,
    routerActions: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    userProvider: PropTypes.object.isRequired
  };

  static mixins = [History];

  componentDidMount() {
    const { authProvider } = this.context;
    if (!authProvider.checkStoredUser()) authProvider.logout();
  }

  onSignout() {
    const { authProvider, routerActions } = this.context;
    authProvider.logout();
    routerActions.push('/');
  }

  render() {
    const { authProvider, userProvider, user } = this.context;
    const { loggedUser } = authProvider;

    if (!authProvider.isAuthenticated(loggedUser)) return null;

    const role = ROLES.labels[loggedUser.advisor.role];

    return (
      <div id="user-advisor-navigation-bar">
        <Impersonate />

        <ul className="nav navbar-nav flex align-stretch navbar-expand-lg">
          {!userProvider.isEntry(user) && (
            <li className="onboarding-item">
              <Link to="/advisor/me/onboarding" className="dropdown-item">
                Onboarding Checklist
              </Link>
            </li>
          )}
          <li className="nav-item dropdown dropdown-two-line">
            <button
              type="button"
              className="nav-link dropdown-toggle flex align-stretch btn btn-link text-sm-right d-block mr-md-3 ml-md-3"
              id="dropdownUser"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span className="text-uppercase text-gray-black">
                {loggedUser.first_name} {loggedUser.last_name}
              </span>
              {role && (
                <span>
                  {role.toUpperCase()}
                  <i className="text-sm-center icon-arrow" />
                </span>
              )}
            </button>

            <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownUser">
              <button
                type="button"
                className="dropdown-item link"
                onClick={this.onSignout.bind(this)}
              >
                Sign out
              </button>
            </div>
          </li>
        </ul>

        {loggedUser.advisor.image && (
          <div className="nav-image-wrapper">
            <img alt="user" src={loggedUser.advisor.image} />
          </div>
        )}
      </div>
    );
  }
}

export default NavigationAdvisor;
