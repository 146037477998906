import { loadingBarReducer } from 'react-redux-loading-bar';
import accounts from 'reducers/accounts';
import advisors from 'reducers/advisors';
import aiAssistant from 'reducers/ai-assistant';
import auth from 'reducers/auth';
import cards from 'reducers/cards';
import charges from 'reducers/charges';
import company from 'reducers/company';
import customSecurity from 'reducers/custom-security';
import form from 'reducers/form';
import households from 'reducers/households';
import integrations from 'reducers/integrations';
import investorsGoals from 'reducers/investor-goals';
import investors from 'reducers/investors';
import invitations from 'reducers/invitations';
import ips from 'reducers/ips';
import loading from 'reducers/loading';
import market from 'reducers/market';
import models from 'reducers/models';
import notifications from 'reducers/notifications';
import portfolios from 'reducers/portfolios';
import prices from 'reducers/prices';
import prism from 'reducers/prism';
import promoCode from 'reducers/promo-code';
import proposals from 'reducers/proposals';
import prospects from 'reducers/prospects';
import questions from 'reducers/questions';
import serverErrors from 'reducers/server-errors';
import staffCompanies from 'reducers/staff-companies';
import subscription from 'reducers/subscription';
import surveillanceExceptions from 'reducers/surveillance-exceptions';
import teams from 'reducers/teams';
import templates from 'reducers/templates';

const reducers = {
  accounts,
  advisors,
  aiAssistant,
  auth,
  cards,
  charges,
  company,
  customSecurity,
  form,
  households,
  integrations,
  investors,
  investorsGoals,
  invitations,
  ips,
  loading,
  loadingBar: loadingBarReducer,
  market,
  models,
  notifications,
  portfolios,
  prices,
  prism,
  promoCode,
  proposals,
  prospects,
  questions,
  serverErrors,
  staffCompanies,
  subscription,
  surveillanceExceptions,
  teams,
  templates
};

export default reducers;
