import React, { useContext } from 'react';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-table/react-table.css';
import './styles.scss';
import { AdvisorContext } from 'containers/advisor';
import { DELETE_COMPANY, SUCCESS_DELETE, DELETE_INFORMATION } from './constants';
import CompanyDeleteModal from './delete-staff-company';
import TooltipV2 from 'components/tooltip-v2';

const TableActions = ({ company }) => {
  const { staffCompaniesProvider } = useContext(AdvisorContext);

  const cellEditLink = companyId => (
    <Link to={`advisor/companies/${companyId}`} style={{ marginRight: '10%', color: 'inherit' }}>
      <i className="icon-pencil-line icon-size" style={{ cursor: 'pointer' }} />
    </Link>
  );

  const onDelete = company => {
    staffCompaniesProvider.delete(company.id).then(data => {
      toast.success(() => (
        <div>
          {SUCCESS_DELETE.replace(
            '{date}',
            moment(data.data.delete_scheduled_datetime).format('lll')
          )}
        </div>
      ));
    });
  };

  const renderDelete = company => {
    if (company.delete_scheduled_datetime) {
      const tooltipId = `delete-company-tooltip-${company.id}`;
      return (
        <TooltipV2
          id={tooltipId}
          label={DELETE_INFORMATION.replace(
            '{date}',
            moment(company.delete_scheduled_datetime).format('lll')
          )}
        >
          <i
            className="fs-icon-exclamation-circle icon-size icon-border text-danger"
            data-for={tooltipId}
            data-tip=""
          />
        </TooltipV2>
      );
    }

    return (
      <CompanyDeleteModal
        label={company.name}
        onDelete={() => onDelete(company)}
        title={DELETE_COMPANY}
      />
    );
  };

  return (
    <>
      {!company.delete_scheduled_datetime && cellEditLink(company?.id)}
      {!company.delete_protected && renderDelete(company)}
    </>
  );
};

TableActions.propTypes = {
  compnay: PropTypes.object.isRequired
};

export default TableActions;
