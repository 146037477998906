import React from 'react';
import { EXCEPTION_TYPE } from 'utils/drift';
import FilterContainer from '../common/filter-container';
import MultiSelectFilter from '../common/multi-select';
import { EXCEPTION_TARGET_ATTR } from '../constants';
import { filterDefaultProps, filterPropTypes } from '../types';

const options = [
  { label: 'Account', value: EXCEPTION_TYPE.ACCOUNT },
  { label: 'Client', value: EXCEPTION_TYPE.INVESTOR }
  // { label: 'Household', value: EXCEPTION_TYPE.HOUSEHOLD }
];

const ExceptionTargetFilter = ({ filters, setFilters, setRequiresPageIndexReset }) => {
  const getDefaultValue = () => {
    if (!filters[EXCEPTION_TARGET_ATTR]) return [];
    const params = new URLSearchParams(
      `${EXCEPTION_TARGET_ATTR}=${filters[EXCEPTION_TARGET_ATTR]}`
    );
    return params.get(EXCEPTION_TARGET_ATTR).split('__');
  };

  const defaultValue = getDefaultValue();

  const onChangeHandler = options => {
    const selected = options.map(option => option.value);
    setFilters(prevFilters => ({
      ...prevFilters,
      [EXCEPTION_TARGET_ATTR]: selected.length ? selected.join('__') : undefined
    }));
    setRequiresPageIndexReset(true);
  };

  return (
    <FilterContainer attrs={[EXCEPTION_TARGET_ATTR]} filters={filters} label="Monitoring Level">
      <MultiSelectFilter
        defaultOptions={options.filter(option => defaultValue.includes(option.value))}
        onChange={onChangeHandler}
        options={options}
        placeholder="View All"
      />
    </FilterContainer>
  );
};

ExceptionTargetFilter.propTypes = { ...filterPropTypes };

ExceptionTargetFilter.defaultProps = { ...filterDefaultProps };

export default ExceptionTargetFilter;
