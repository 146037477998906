/* eslint-disable react/no-unstable-nested-components */
import ScoreBubble from 'components/advisor/utils/score-bubble';
import Disclosure from 'components/disclosure';
import { AdvisorContext } from 'containers/advisor';
import DynamicTable from 'containers/table/dynamic';
import SecurityPrismOverallFilter from 'containers/table/dynamic/filters/security/prism-overall';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import CustomSecuritiesCsvUpdate from '../security-csv-update';
import BulkUpdateSecuritiesModal from '../bulk-table/bulk-update-securities-modal';
import './styles.scss';

const CustomSecurityList = ({ customSecurities, meta }) => {
  const { customSecurityProvider } = useContext(AdvisorContext);

  const [showBulkUpdate, setShowBulkUpdate] = useState(false);

  const onFetchData = params => customSecurityProvider.list({ ...(meta?.params || {}), ...params });

  const columns = [
    {
      accessorFn: row => row.ticker_name,
      id: 'ticker_name',
      name: 'Name',
      cell: security => (
        <Link to={`/advisor/securities/${security.id}/`}>{security.ticker_name}</Link>
      ),
      meta: { style: () => ({ width: 350 }) }
    },
    {
      accessorFn: row => row.ticker,
      id: 'ticker',
      name: 'Ticker',
      meta: { className: () => 'text-center', style: () => ({ width: 125 }) }
    },
    {
      accessorFn: row => row.cusip || '-',
      id: 'cusip',
      name: 'CUSIP',
      meta: { className: () => 'text-center', style: () => ({ width: 125 }) }
    },
    {
      accessorFn: row => row.type_name || '-',
      id: 'type__name',
      name: 'Asset Class',
      meta: { className: () => 'text-center', style: () => ({ width: 150 }) }
    },
    {
      accessorFn: row => row.subtype_name || '-',
      id: 'subtype__name',
      name: 'Subtype',
      meta: { className: () => 'text-center', style: () => ({ width: 150 }) }
    },
    {
      accessorFn: row => row.sector_name || '-',
      id: 'sector__name',
      name: 'Segment',
      meta: { className: () => 'text-center', style: () => ({ width: 150 }) }
    },
    {
      accessorFn: row => row.prism_overall,
      id: 'prism_score__overall',
      name: 'PRISM',
      cell: security => <ScoreBubble score={security.prism_overall} className="risk-bubble" />,
      meta: { className: () => 'text-center', style: () => ({ width: 95 }) }
    }
  ];
  const showModal = () => {
    setShowBulkUpdate(true);
  };
  const hideModal = () => {
    setShowBulkUpdate(false);
  };

  return (
    <div className="custom-security-list">
      <div className="custom-security-list__header">
        <div className="title-container">
          <h2>Security Master</h2>
        </div>
        <div className="btn-container__header">
          <Link to="/advisor/securities/create" className="btn btn-primary security-btn">
            Create new custom security
          </Link>
          <CustomSecuritiesCsvUpdate
            downloading={meta?.downloading || false}
            getSecuritiesList={onFetchData}
            securitiesMeta={meta}
          />

          <button
            className="btn btn-secondary bulk-update-btn"
            onClick={() => showModal()}
            type="button"
          >
            Bulk Update Returns
          </button>
        </div>
      </div>

      <DynamicTable
        columns={columns}
        data={customSecurities}
        filterComponents={[[SecurityPrismOverallFilter]]}
        initialParams={{ ordering: ['-created'] }}
        label="Security"
        meta={meta}
        onFetchData={onFetchData}
        withAdvisorFilter={false}
        withTeamFilter={false}
      />

      <BulkUpdateSecuritiesModal
        onHide={hideModal}
        selectedPortfolioIds={customSecurities}
        show={showBulkUpdate}
      />

      <Disclosure />
    </div>
  );
};

CustomSecurityList.propTypes = {
  customSecurities: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired
};

export default connect(state => ({
  customSecurities: state.customSecurity.list,
  meta: state.customSecurity.meta
}))(CustomSecurityList);
