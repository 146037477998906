import { AdvisorContext } from 'containers/advisor';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router';
import CheckInIcon from './icon';
import './styles.scss';

const HISTORY_MAX_LENGTH = 5;
const ICON_BASE_HEIGHT = 40;
const ICON_LARGER_HEIGHT = 50;

const CheckInsHistory = ({ investor, setLatestCheckIn }) => {
  const { clientProvider } = useContext(AdvisorContext);

  const [checkIns, setCheckIns] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    clientProvider
      .getCheckIns(investor.id)
      .then(({ data, error }) => {
        if (!error) setCheckIns(data.results);
      })
      .finally(() => {
        setLoading(false);
      });
    clientProvider.getLastCheckIn(investor.id).then(({ data }) => {
      if (data) setLatestCheckIn(data);
    });
  }, []);

  if (!checkIns.length)
    return (
      <div id="check-ins-history">
        <p className="text-center m-0 p-3">{loading ? 'Loading ...' : 'No data available'}</p>
      </div>
    );

  return (
    <div id="check-ins-history">
      <div className="history-grid">
        <div className="history-grid__column">
          <div className="history-grid__cell">&nbsp;</div>
          <div className="history-grid__cell">Market Sentiment</div>
          <div className="history-grid__cell">Financial Future</div>
        </div>

        {checkIns.slice(0, HISTORY_MAX_LENGTH).map(({ id, created, data }, idx) => {
          const {
            market_sentiment: {
              color: marketSentimentColor,
              image: marketSentimentImage,
              value: [marketSentimentValue]
            },
            financial_future: {
              color: financialFutureColor,
              image: financialFutureImage,
              value: [financialFutureValue]
            }
          } = data;
          return (
            <div key={id} className="history-grid__column">
              <div className="history-grid__cell">{moment.utc(created).format('ll')}</div>
              <div className="history-grid__cell">
                <CheckInIcon
                  color={marketSentimentColor}
                  height={idx === 0 ? ICON_LARGER_HEIGHT : ICON_BASE_HEIGHT}
                  label={marketSentimentValue}
                  src={marketSentimentImage}
                />
              </div>
              <div className="history-grid__cell">
                <CheckInIcon
                  color={financialFutureColor}
                  height={idx === 0 ? ICON_LARGER_HEIGHT : ICON_BASE_HEIGHT}
                  label={financialFutureValue}
                  src={financialFutureImage}
                />
              </div>
            </div>
          );
        })}
      </div>

      <div className="check-ins-history__view-all">
        {checkIns.length > HISTORY_MAX_LENGTH && (
          <Link
            to={`/advisor/${investor.is_prospect ? 'prospects' : 'investors'}/${
              investor.id
            }/fact-finder-forms`}
          >
            View all Check-Ins
          </Link>
        )}
      </div>
    </div>
  );
};

CheckInsHistory.propTypes = {
  investor: PropTypes.object.isRequired,
  setLatestCheckIn: PropTypes.func.isRequired
};

export default CheckInsHistory;
