import Disclosure from 'components/disclosure';
import { QUESTIONNAIRE_TEMPLATE_URL_PARAM } from 'constants/questionnaire';
import { AdvisorContext } from 'containers/advisor';
import { AuthenticationContext } from 'containers/auth';
import { InvestorContext } from 'containers/investor';
import QuestionnaireWizard from 'containers/risk-tolerance-questionnaire';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { getQuestionnaireIdFromTemplate } from 'utils/questionnaire';
import { recordLogRocketEvent } from 'utils/tracking';

const CheckInQuestionnaire = ({
  isProspect,
  location: { query },
  questionnaires,
  params: { id: investorId, token },
  route: { flavor }
}) => {
  const params = new URLSearchParams(window.location.search);

  const [investors, setInvestors] = useState({});
  const { authProvider, user } = useContext(AuthenticationContext);
  const Context = useMemo(
    () => (authProvider.isInvestor(user) ? InvestorContext : AdvisorContext),
    [JSON.stringify(user)]
  );
  const { clientProvider, questionProvider } = useContext(Context);

  const [contentLoaded, setContentLoaded] = useState(false);
  const [questionnaireTemplateId, setQuestionnaireTemplateId] = useState(
    params.get(QUESTIONNAIRE_TEMPLATE_URL_PARAM)
  );

  // get the corresponding list of questions for this questionnaire
  const questions =
    useMemo(() => {
      if (!contentLoaded || !questionnaireTemplateId) return [];
      const questionnaireId = getQuestionnaireIdFromTemplate(questionnaireTemplateId, investorId);
      return questionnaires[questionnaireId];
    }, [contentLoaded, questionnaireTemplateId, JSON.stringify(questionnaires)]) ?? [];

  const loadTargets = async () => {
    if (!token && !authProvider.isAuthenticated(user)) return;

    // load target & questions
    setContentLoaded(false);
    const investor = await clientProvider.list(
      { id: investorId },
      {},
      false // avoids updating the meta attributes of previous requests
    );
    if (questionnaireTemplateId)
      questionProvider.getTemplateQuestions(questionnaireTemplateId, investorId).catch(() => {
        // if an error occurs because the template used is in draft or invalid, set
        // the `questionnaireTemplateId` to `null` to allow the use of the default template
        setQuestionnaireTemplateId(null);
      });
    setInvestors(investor.results);
    setContentLoaded(true);
  };

  const onQuestionnaireSave = useCallback(success => {
    if (success) loadTargets(); // reload questionnaire target to get the fresh score
  }, []);

  useEffect(() => {
    if (authProvider.isInvestor(user)) recordLogRocketEvent('Check-in');
  }, []);

  // fetch the investor's data
  useEffect(() => {
    const fetchData = async () => {
      await loadTargets();
    };
    fetchData();
  }, [JSON.stringify(query.investors), token, user?.id]);

  // sets the default template if no template has been provided
  useEffect(() => {
    const company = user.advisor?.company || user.investor?.advisor?.company || {};
    const { default_check_in_questionnaire_template_id: defaultCheckInQuestionnaireTemplateId } =
      company;

    if (!questionnaireTemplateId)
      questionProvider
        .listQuestionnaireTemplates({ is_check_in: true })
        .then(questionnaireTemplates => {
          // gets the default questionnaire for the widget, and otherwise, the default questionnaire
          const questionnaireTemplate =
            questionnaireTemplates.find(qt => qt.id === defaultCheckInQuestionnaireTemplateId) ||
            questionnaireTemplates.find(qt => qt.is_check_in && qt.is_default);
          if (questionnaireTemplate) {
            setQuestionnaireTemplateId(questionnaireTemplate.id);
            questionProvider.getTemplateQuestions(questionnaireTemplate.id, investorId);
            const url = new URL(window.location.href);
            url.searchParams.set(QUESTIONNAIRE_TEMPLATE_URL_PARAM, questionnaireTemplate.id);
            window.history.replaceState(null, '', url);
          }
        });
  }, [questionnaireTemplateId]);

  return (
    <div>
      <div className="prism-questions-container prism-questions-begin-container prism-target-questionnaire-start">
        <QuestionnaireWizard
          flavor={flavor}
          initialStep={query.step}
          investors={investors}
          isProspect={isProspect}
          mode="investor"
          onQuestionnaireSave={onQuestionnaireSave}
          questions={questions}
        />
      </div>
      <Disclosure />
    </div>
  );
};

CheckInQuestionnaire.propTypes = {
  investors: PropTypes.array,
  isProspect: PropTypes.bool,
  location: PropTypes.object.isRequired,
  params: PropTypes.object,
  prospects: PropTypes.array,
  questionnaires: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

CheckInQuestionnaire.defaultProps = {
  investors: [],
  isProspect: false,
  params: null,
  prospects: []
};

function mergeProps({ ...stateProps }, dispatchProps, ownProps) {
  const {
    location: { pathname }
  } = ownProps;
  const { user } = stateProps;

  const isProspect =
    user && user.investor ? user.investor.is_prospect : pathname.includes('advisor/prospects/');

  return { ...stateProps, ...ownProps, isProspect };
}

export default connect(
  state => ({
    questionnaires: state.questions.questionnaires,
    user: state.auth.user
  }),
  null,
  mergeProps
)(React.memo(CheckInQuestionnaire));
