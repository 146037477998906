// -------------------------------------------------------------------------------------------------
// Account
// -------------------------------------------------------------------------------------------------

export const ACCOUNT_DRIFT_LEVEL_ATTR = 'account_drift_level';
export const ACCOUNT_DRIFT_OVERALL_MAX_ATTR = 'account_drift_overall_max';
export const ACCOUNT_DRIFT_OVERALL_MIN_ATTR = 'account_drift_overall_min';
export const ACCOUNT_RISK_LEVEL_ATTR = 'account_risk_level';
export const ACCOUNT_TOTAL_MAX_ATTR = 'account_total_max';
export const ACCOUNT_TOTAL_MIN_ATTR = 'account_total_min';

export const ACCOUNT_PRISM_CONCENTRATED_MAX_ATTR = 'account_prism_concentrated_max';
export const ACCOUNT_PRISM_CONCENTRATED_MIN_ATTR = 'account_prism_concentrated_min';
export const ACCOUNT_PRISM_CORRELATION_MAX_ATTR = 'account_prism_correlation_max';
export const ACCOUNT_PRISM_CORRELATION_MIN_ATTR = 'account_prism_correlation_min';
export const ACCOUNT_PRISM_OVERALL_MAX_ATTR = 'account_prism_overall_max';
export const ACCOUNT_PRISM_OVERALL_MIN_ATTR = 'account_prism_overall_min';
export const ACCOUNT_PRISM_TAIL_MAX_ATTR = 'account_prism_tail_max';
export const ACCOUNT_PRISM_TAIL_MIN_ATTR = 'account_prism_tail_min';
export const ACCOUNT_PRISM_VOLATILITY_MAX_ATTR = 'account_prism_volatility_max';
export const ACCOUNT_PRISM_VOLATILITY_MIN_ATTR = 'account_prism_volatility_min';

export const ACCOUNT_PRISM_FACTOR_ATTRS = [
  ACCOUNT_PRISM_CONCENTRATED_MAX_ATTR,
  ACCOUNT_PRISM_CONCENTRATED_MIN_ATTR,
  ACCOUNT_PRISM_CORRELATION_MAX_ATTR,
  ACCOUNT_PRISM_CORRELATION_MIN_ATTR,
  ACCOUNT_PRISM_OVERALL_MAX_ATTR,
  ACCOUNT_PRISM_OVERALL_MIN_ATTR,
  ACCOUNT_PRISM_TAIL_MAX_ATTR,
  ACCOUNT_PRISM_TAIL_MIN_ATTR,
  ACCOUNT_PRISM_VOLATILITY_MAX_ATTR,
  ACCOUNT_PRISM_VOLATILITY_MIN_ATTR
];

// -------------------------------------------------------------------------------------------------
// Exception
// -------------------------------------------------------------------------------------------------

export const EXCEPTION_AGE_RANGE_ATTR = 'age__range';
export const EXCEPTION_CREATED_RANGE_ATTR = 'created__range';
export const EXCEPTION_DRIFT_RANGE_ATTR = 'drift__range';
export const EXCEPTION_STATUS_TERMS_ATTR = 'status__terms';
export const EXCEPTION_TARGET_ATTR = 'target_type__terms';
export const EXCEPTION_TYPE_ATTR = 'tags';
export const EXCEPTION_VALUE_RANGE_ATTR = 'value__range';

// -------------------------------------------------------------------------------------------------
// Household
// -------------------------------------------------------------------------------------------------

export const HOUSEHOLD_DRIFT_LEVEL_ATTR = 'household_drift_level';
export const HOUSEHOLD_DRIFT_OVERALL_MAX_ATTR = 'household_drift_overall_max';
export const HOUSEHOLD_DRIFT_OVERALL_MIN_ATTR = 'household_drift_overall_min';
export const HOUSEHOLD_RISK_LEVEL_ATTR = 'household_risk_level';
export const HOUSEHOLD_TOTAL_MAX_ATTR = 'household_total_max';
export const HOUSEHOLD_TOTAL_MIN_ATTR = 'household_total_min';

export const HOUSEHOLD_PRISM_CONCENTRATED_MAX_ATTR = 'household_prism_concentrated_max';
export const HOUSEHOLD_PRISM_CONCENTRATED_MIN_ATTR = 'household_prism_concentrated_min';
export const HOUSEHOLD_PRISM_CORRELATION_MAX_ATTR = 'household_prism_correlation_max';
export const HOUSEHOLD_PRISM_CORRELATION_MIN_ATTR = 'household_prism_correlation_min';
export const HOUSEHOLD_PRISM_OVERALL_MAX_ATTR = 'household_prism_overall_max';
export const HOUSEHOLD_PRISM_OVERALL_MIN_ATTR = 'household_prism_overall_min';
export const HOUSEHOLD_PRISM_TAIL_MAX_ATTR = 'household_prism_tail_max';
export const HOUSEHOLD_PRISM_TAIL_MIN_ATTR = 'household_prism_tail_min';
export const HOUSEHOLD_PRISM_VOLATILITY_MAX_ATTR = 'household_prism_volatility_max';
export const HOUSEHOLD_PRISM_VOLATILITY_MIN_ATTR = 'household_prism_volatility_min';

export const HOUSEHOLD_PRISM_FACTOR_ATTRS = [
  HOUSEHOLD_PRISM_CONCENTRATED_MAX_ATTR,
  HOUSEHOLD_PRISM_CONCENTRATED_MIN_ATTR,
  HOUSEHOLD_PRISM_CORRELATION_MAX_ATTR,
  HOUSEHOLD_PRISM_CORRELATION_MIN_ATTR,
  HOUSEHOLD_PRISM_OVERALL_MAX_ATTR,
  HOUSEHOLD_PRISM_OVERALL_MIN_ATTR,
  HOUSEHOLD_PRISM_TAIL_MAX_ATTR,
  HOUSEHOLD_PRISM_TAIL_MIN_ATTR,
  HOUSEHOLD_PRISM_VOLATILITY_MAX_ATTR,
  HOUSEHOLD_PRISM_VOLATILITY_MIN_ATTR
];

// -------------------------------------------------------------------------------------------------
// Investor
// -------------------------------------------------------------------------------------------------

export const INVESTOR_TARGET_MODIFIED_AFTER_ATTR = 'target_modified_after';
export const INVESTOR_TARGET_MODIFIED_BEFORE_ATTR = 'target_modified_before';
export const INVESTOR_DRIFT_LEVEL_ATTR = 'investor_drift_level';
export const INVESTOR_DRIFT_OVERALL_MAX_ATTR = 'investor_drift_overall_max';
export const INVESTOR_DRIFT_OVERALL_MIN_ATTR = 'investor_drift_overall_min';
export const INVESTOR_RISK_LEVEL_ATTR = 'investor_risk_level';
export const INVESTOR_TOTAL_MAX_ATTR = 'investor_total_max';
export const INVESTOR_TOTAL_MIN_ATTR = 'investor_total_min';

export const INVESTOR_PRISM_CONCENTRATED_MAX_ATTR = 'investor_prism_concentrated_max';
export const INVESTOR_PRISM_CONCENTRATED_MIN_ATTR = 'investor_prism_concentrated_min';
export const INVESTOR_PRISM_CORRELATION_MAX_ATTR = 'investor_prism_correlation_max';
export const INVESTOR_PRISM_CORRELATION_MIN_ATTR = 'investor_prism_correlation_min';
export const INVESTOR_PRISM_OVERALL_MAX_ATTR = 'investor_prism_overall_max';
export const INVESTOR_PRISM_OVERALL_MIN_ATTR = 'investor_prism_overall_min';
export const INVESTOR_PRISM_TAIL_MAX_ATTR = 'investor_prism_tail_max';
export const INVESTOR_PRISM_TAIL_MIN_ATTR = 'investor_prism_tail_min';
export const INVESTOR_PRISM_VOLATILITY_MAX_ATTR = 'investor_prism_volatility_max';
export const INVESTOR_PRISM_VOLATILITY_MIN_ATTR = 'investor_prism_volatility_min';

export const INVESTOR_PRISM_FACTOR_ATTRS = [
  INVESTOR_PRISM_CONCENTRATED_MAX_ATTR,
  INVESTOR_PRISM_CONCENTRATED_MIN_ATTR,
  INVESTOR_PRISM_CORRELATION_MAX_ATTR,
  INVESTOR_PRISM_CORRELATION_MIN_ATTR,
  INVESTOR_PRISM_OVERALL_MAX_ATTR,
  INVESTOR_PRISM_OVERALL_MIN_ATTR,
  INVESTOR_PRISM_TAIL_MAX_ATTR,
  INVESTOR_PRISM_TAIL_MIN_ATTR,
  INVESTOR_PRISM_VOLATILITY_MAX_ATTR,
  INVESTOR_PRISM_VOLATILITY_MIN_ATTR
];

// -------------------------------------------------------------------------------------------------
// Model
// -------------------------------------------------------------------------------------------------

export const MODEL_ADVISOR_ATTR = 'advisor';
export const MODEL_IS_BENCHMARK_ATTR = 'is_benchmark';
export const MODEL_IS_STRATEGY_ATTR = 'is_strategy';
export const MODEL_TYPE_ATTR = 'type';
export const MODEL_VISIBILITY_ATTR = 'visibility';

// -------------------------------------------------------------------------------------------------
// Security
// -------------------------------------------------------------------------------------------------

export const SECURITY_RISK_OVERALL_MAX_ATTR = 'risk_overall_max';
export const SECURITY_RISK_OVERALL_MIN_ATTR = 'risk_overall_min';

// -------------------------------------------------------------------------------------------------
// General
// -------------------------------------------------------------------------------------------------

export const ADVISOR_ATTR = 'by_advisor';
export const PRISM_FACTOR_ATTR = 'prism_factor';
export const TEAM_ATTR = 'team';

export const FILTERS = [
  ACCOUNT_DRIFT_LEVEL_ATTR,
  ACCOUNT_DRIFT_OVERALL_MAX_ATTR,
  ACCOUNT_DRIFT_OVERALL_MIN_ATTR,
  ACCOUNT_PRISM_CONCENTRATED_MAX_ATTR,
  ACCOUNT_PRISM_CONCENTRATED_MIN_ATTR,
  ACCOUNT_PRISM_CORRELATION_MAX_ATTR,
  ACCOUNT_PRISM_CORRELATION_MIN_ATTR,
  ACCOUNT_PRISM_OVERALL_MAX_ATTR,
  ACCOUNT_PRISM_OVERALL_MIN_ATTR,
  ACCOUNT_PRISM_TAIL_MAX_ATTR,
  ACCOUNT_PRISM_TAIL_MIN_ATTR,
  ACCOUNT_PRISM_VOLATILITY_MAX_ATTR,
  ACCOUNT_PRISM_VOLATILITY_MIN_ATTR,
  ACCOUNT_RISK_LEVEL_ATTR,
  ACCOUNT_TOTAL_MAX_ATTR,
  ACCOUNT_TOTAL_MIN_ATTR,
  ADVISOR_ATTR,
  EXCEPTION_AGE_RANGE_ATTR,
  EXCEPTION_CREATED_RANGE_ATTR,
  EXCEPTION_DRIFT_RANGE_ATTR,
  EXCEPTION_STATUS_TERMS_ATTR,
  EXCEPTION_TARGET_ATTR,
  EXCEPTION_TYPE_ATTR,
  EXCEPTION_VALUE_RANGE_ATTR,
  HOUSEHOLD_DRIFT_LEVEL_ATTR,
  HOUSEHOLD_DRIFT_OVERALL_MAX_ATTR,
  HOUSEHOLD_DRIFT_OVERALL_MIN_ATTR,
  HOUSEHOLD_PRISM_CONCENTRATED_MAX_ATTR,
  HOUSEHOLD_PRISM_CONCENTRATED_MIN_ATTR,
  HOUSEHOLD_PRISM_CORRELATION_MAX_ATTR,
  HOUSEHOLD_PRISM_CORRELATION_MIN_ATTR,
  HOUSEHOLD_PRISM_OVERALL_MAX_ATTR,
  HOUSEHOLD_PRISM_OVERALL_MIN_ATTR,
  HOUSEHOLD_PRISM_TAIL_MAX_ATTR,
  HOUSEHOLD_PRISM_TAIL_MIN_ATTR,
  HOUSEHOLD_PRISM_VOLATILITY_MAX_ATTR,
  HOUSEHOLD_PRISM_VOLATILITY_MIN_ATTR,
  HOUSEHOLD_RISK_LEVEL_ATTR,
  HOUSEHOLD_TOTAL_MAX_ATTR,
  HOUSEHOLD_TOTAL_MIN_ATTR,
  INVESTOR_DRIFT_LEVEL_ATTR,
  INVESTOR_DRIFT_OVERALL_MAX_ATTR,
  INVESTOR_DRIFT_OVERALL_MIN_ATTR,
  INVESTOR_PRISM_CONCENTRATED_MAX_ATTR,
  INVESTOR_PRISM_CONCENTRATED_MIN_ATTR,
  INVESTOR_PRISM_CORRELATION_MAX_ATTR,
  INVESTOR_PRISM_CORRELATION_MIN_ATTR,
  INVESTOR_PRISM_OVERALL_MAX_ATTR,
  INVESTOR_PRISM_OVERALL_MIN_ATTR,
  INVESTOR_PRISM_TAIL_MAX_ATTR,
  INVESTOR_PRISM_TAIL_MIN_ATTR,
  INVESTOR_PRISM_VOLATILITY_MAX_ATTR,
  INVESTOR_PRISM_VOLATILITY_MIN_ATTR,
  INVESTOR_RISK_LEVEL_ATTR,
  INVESTOR_TARGET_MODIFIED_AFTER_ATTR,
  INVESTOR_TARGET_MODIFIED_BEFORE_ATTR,
  INVESTOR_TOTAL_MAX_ATTR,
  INVESTOR_TOTAL_MIN_ATTR,
  MODEL_ADVISOR_ATTR,
  MODEL_IS_BENCHMARK_ATTR,
  MODEL_IS_STRATEGY_ATTR,
  MODEL_TYPE_ATTR,
  MODEL_VISIBILITY_ATTR,
  PRISM_FACTOR_ATTR,
  SECURITY_RISK_OVERALL_MAX_ATTR,
  SECURITY_RISK_OVERALL_MIN_ATTR,
  TEAM_ATTR
];
