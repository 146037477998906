import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { RISK_LEVELS_COLORS } from 'app/utils/colors';
import cn from 'classnames';

const RiskLevelColorSelector = ({ color, prevColor, nextColor, onChange }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const colorSelectorRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (colorSelectorRef.current && !colorSelectorRef.current.contains(event.target))
        setShowTooltip(false);
    };

    if (showTooltip) document.addEventListener('mousedown', handleClickOutside);
    else document.removeEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showTooltip]);

  // Get index positions of previous and next colors
  const prevIndex = prevColor ? RISK_LEVELS_COLORS.indexOf(prevColor) : -1;
  const nextIndex = nextColor ? RISK_LEVELS_COLORS.indexOf(nextColor) : RISK_LEVELS_COLORS.length;

  const allowedColors = RISK_LEVELS_COLORS.slice(
    prevIndex !== -1 ? prevIndex : 0, // If no prevColor, start from index 0
    nextIndex !== RISK_LEVELS_COLORS.length ? nextIndex + 1 : RISK_LEVELS_COLORS.length // If no nextColor, allow till last color
  );

  return (
    <div className="color-selector__wrapper" ref={colorSelectorRef}>
      <div
        className="color-selector"
        style={{ backgroundColor: color }}
        onClick={() => setShowTooltip(true)}
      >
        <i className="color-selector__edit-icon icon-edit" />
      </div>

      {showTooltip && (
        <div className="color-options">
          {RISK_LEVELS_COLORS.map(c => (
            <button
              key={c}
              type="button"
              className={cn('color-option', {
                selected: c === color,
                disabled: !allowedColors.includes(c)
              })}
              style={{
                backgroundColor: c,
                opacity: allowedColors.includes(c) ? 1 : 0.3,
                cursor: allowedColors.includes(c) ? 'pointer' : 'not-allowed'
              }}
              aria-label={`Select color ${c}`}
              onClick={() => {
                if (allowedColors.includes(c)) {
                  onChange(c);
                  setShowTooltip(false);
                }
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

RiskLevelColorSelector.defaultProps = {
  prevColor: '',
  nextColor: ''
};

RiskLevelColorSelector.propTypes = {
  color: PropTypes.string.isRequired,
  prevColor: PropTypes.string,
  nextColor: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default RiskLevelColorSelector;
