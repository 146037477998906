/* eslint-disable no-underscore-dangle */
import {
  COMPANY_LIMITS_GET,
  COMPANY_LIST_COLLEAGUES,
  COMPANY_TEMPLATES_GET,
  COMPANY_TEMPLATES_UPDATE
} from 'constants/actions';
import config from '../config';
import AuthProvider from './auth';
import CompanyElasticProvider from './company.es';
import DataProvider from './data';

class CompanyProvider extends DataProvider {
  get es() {
    if (!this._es) this._es = new CompanyElasticProvider({ dispatch: this._dispatch });
    return this._es;
  }

  get auth() {
    if (!this._auth) this._auth = new AuthProvider({ dispatch: this._dispatch });
    return this._auth;
  }

  async get() {
    return this.auth.getCompany();
  }

  async update(values) {
    return this.auth.updateCompany(values);
  }

  async updateImage(file) {
    return this.auth.updateCompanyLogo(file);
  }

  listAdvisors() {
    return this.provider.get(`${config.apiBase}me/company/advisors/`).then(({ data }) => {
      if (!data.error) this.dispatch(COMPANY_LIST_COLLEAGUES, { data });
      return data;
    });
  }

  getTemplates() {
    return this.provider.get(`${config.apiBase}me/company/templates/`).then(({ data }) => {
      if (data) return this.dispatch(COMPANY_TEMPLATES_GET, { data });
      return null;
    });
  }

  saveTemplate(target, template) {
    return this.provider
      .patch(`${config.apiBase}me/company/templates/`, { [target]: template })
      .then(({ data }) => {
        if (data && data[target])
          return this.dispatch(COMPANY_TEMPLATES_UPDATE, {
            data: { ...data, [target]: data[target] }
          });
        return null;
      });
  }

  getLimits() {
    return this.provider
      .get(`${config.apiBase}me/company/limits/`)
      .then(({ data }) => this.dispatch(COMPANY_LIMITS_GET, { data }));
  }
}

export default CompanyProvider;
