import { Image, Text, View } from '@react-pdf/renderer';
import UpsideDownsideChart, { THEME } from 'components/charts/upside-downside-chart/pdf';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import ExtraDataSection from 'reports/base/sections/extra';
import React, { useContext } from 'react';
import BaseSection from 'reports/base/sections/base';
import Legend from 'reports/base/sections/legend';
import { CYAN_COLOR, GRAPHITE_COLOR, LILAC_COLOR } from 'reports/base/styles';
import Row from 'reports/base/table';
import { MetadataPropTypes, ProposalPropTypes } from '../../types';
import { CLUSTERED_COLUMN_CHART_ID } from '../constants';
import ScenarioPerformance from './scenario-performance';
import styles from './styles';
import { getScenariosPerformance } from '../utils';

const ProposalMarketScenarioAnalysis = ({ breakSection, metadata, proposal }) => {
  const {
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);

  const { body, title } = metadata;

  const market = {
    up: company.market_upside_performance,
    down: company.market_downside_performance
  };

  const targetName = proposal.target_label || 'Current Portfolio';
  const recommendedName = proposal.recommended_label || 'Model';
  const benchmarkName = proposal.benchmark_label || 'Benchmark';

  const upCapturePercentage = Math.round(
    proposal.target.prism_score_summary.upside_capture_ratio * market.up
  );
  const downCapturePercentage = Math.round(
    proposal.target.prism_score_summary.downside_capture_ratio * market.down
  );
  const scenarios = getScenariosPerformance(
    proposal.target.scenarios,
    proposal.target,
    proposal.recommended,
    proposal.benchmark
  );

  const hasRecommended = !!proposal.recommended;
  const hasBenchmark = !!proposal.benchmark;

  const upsideDownsideChartData = {
    target: {
      upside: proposal.target.prism_score_summary.upside_capture_ratio / 10,
      downside: proposal.target.prism_score_summary.downside_capture_ratio / 10,
      name: targetName
    },
    recommended: hasRecommended
      ? {
          upside: proposal.recommended.prism_score_summary.upside_capture_ratio / 10,
          downside: proposal.recommended.prism_score_summary.downside_capture_ratio / 10,
          name: recommendedName
        }
      : null,
    benchmark: hasBenchmark
      ? {
          upside: proposal.benchmark.prism_score_summary.upside_capture_ratio / 10,
          downside: proposal.benchmark.prism_score_summary.downside_capture_ratio / 10,
          name: benchmarkName
        }
      : null
  };

  return (
    <BaseSection body={body} breakSection={breakSection} title={title}>
      <View wrap={false}>
        <Text style={styles.text}>
          Historically, the S&amp;P 500 has been between -{market.down}% and +{market.up}% about 90% of
          the time. If the S&amp;P 500 was down {market.down}% then this portfolio&apos;s performance
          may be {downCapturePercentage ? `-${downCapturePercentage}%` : '-'} and if the S&amp;P 500 was
          up {market.up}% then this portfolio&apos;s performance may be{' '}
          {upCapturePercentage ? `${upCapturePercentage}%` : '-'}.
        </Text>

        <ExtraDataSection proposal={proposal} />

        <Legend
          targetName={targetName}
          recommendedName={recommendedName}
          benchmarkName={benchmarkName}
          withBenchmark={hasBenchmark}
          withRecommended={hasRecommended}
        />
      </View>

      <View style={styles.chartContainer} wrap={false}>
        <Row
          elements={scenarios.map(scenario => scenario.title)}
          cellStyle={styles.headerCell}
          rowStyle={styles.headerRow}
        />
        <Row
          elements={scenarios.map(scenario => (
            <View>
              <ScenarioPerformance
                color={GRAPHITE_COLOR}
                percentage={scenario.target}
                startingValue={proposal.starting_value}
                style={styles.scenario}
              />
              {hasRecommended && (
                <ScenarioPerformance
                  color={CYAN_COLOR}
                  percentage={scenario.recommended}
                  startingValue={proposal.starting_value}
                  style={styles.scenario}
                />
              )}
              {hasBenchmark && (
                <ScenarioPerformance
                  color={LILAC_COLOR}
                  percentage={scenario.benchmark}
                  startingValue={proposal.starting_value}
                  style={{ ...styles.scenario, paddingBottom: 0 }}
                />
              )}
            </View>
          ))}
          cellStyle={styles.contentCell}
          rowStyle={styles.headerRow}
          withCustomElements
        />
        <Image src={proposal[CLUSTERED_COLUMN_CHART_ID]} style={styles.chart} />
      </View>

      <View wrap={false}>
        <Text style={styles.separator}>Next 12 Months</Text>
        <UpsideDownsideChart
          {...upsideDownsideChartData}
          benchmarkName={benchmarkName}
          recommendedName={benchmarkName}
          targetName={targetName}
          theme={THEME.proposal}
          withRecommended={hasRecommended}
        />
      </View>

      <View style={styles.performanceContainer} wrap={false}>
        <Row elements={['Downside performance', 'Upside performance']} />
        <Row
          elements={[
            <ScenarioPerformance
              color={GRAPHITE_COLOR}
              percentage={Math.round(
                -1 * proposal.target.prism_score_summary.downside_capture_ratio * market.down
              )}
              startingValue={proposal.starting_value}
              negative
            />,
            <ScenarioPerformance
              color={GRAPHITE_COLOR}
              percentage={Math.round(
                proposal.target.prism_score_summary.upside_capture_ratio * market.up
              )}
              startingValue={proposal.starting_value}
            />
          ]}
          withCustomElements
        />
        {hasRecommended && (
          <Row
            elements={[
              <ScenarioPerformance
                color={CYAN_COLOR}
                percentage={Math.round(
                  -1 * proposal.recommended.prism_score_summary.downside_capture_ratio * market.down
                )}
                startingValue={proposal.starting_value}
                negative
              />,
              <ScenarioPerformance
                color={CYAN_COLOR}
                percentage={Math.round(
                  proposal.recommended.prism_score_summary.upside_capture_ratio * market.up
                )}
                startingValue={proposal.starting_value}
              />
            ]}
            withCustomElements
          />
        )}
        {hasBenchmark && (
          <Row
            elements={[
              <ScenarioPerformance
                color={LILAC_COLOR}
                percentage={Math.round(
                  -1 * proposal.benchmark.prism_score_summary.downside_capture_ratio * market.down
                )}
                startingValue={proposal.starting_value}
                negative
              />,
              <ScenarioPerformance
                color={LILAC_COLOR}
                percentage={Math.round(
                  proposal.benchmark.prism_score_summary.upside_capture_ratio * market.up
                )}
                startingValue={proposal.starting_value}
              />
            ]}
            withCustomElements
          />
        )}
      </View>
    </BaseSection>
  );
};

ProposalMarketScenarioAnalysis.propTypes = {
  breakSection: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired
};

ProposalMarketScenarioAnalysis.defaultProps = {
  breakSection: false
};

export default ProposalMarketScenarioAnalysis;
