import { Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import Legend from 'reports/base/sections/legend';
import { CYAN_COLOR, GRAPHITE_COLOR } from 'reports/base/styles';
import styles from './styles';

const DOWNSIDE_FACTOR = 'downside';
const UPSIDE_FACTOR = 'upside';

const GRAPH_BAR_CUSTOM_BACKGROUND_COLOR = {
  0: GRAPHITE_COLOR,
  1: CYAN_COLOR
};
const GRAPH_BAR_MAX_PERCENTAGE_WIDTH = 100;
const GRAPH_BAR_TOP_VALUE = {
  0: 17,
  1: 36
};
const PRISM_MAX_SCORE = 10;

const FactorRow = ({ lastRow, title, type, values, withRecommended }) => {
  const bars = values.map(value => {
    const score = Number.parseFloat((value || 0).toFixed(1));
    return `${(score / PRISM_MAX_SCORE) * GRAPH_BAR_MAX_PERCENTAGE_WIDTH}%`;
  });
  const barsAlignment = type === DOWNSIDE_FACTOR ? 'right' : 'left';

  return (
    <View style={[styles.factorRow, styles[`${type}FactorRow`]]}>
      <View style={[styles.factorRowTitle, styles[`${type}FactorRowTitle`]]}>
        <Text>{title}</Text>
      </View>
      <View style={styles.factorRowSeparatorWrapper}>
        {bars.map((width, i) => (
          <View
            // eslint-disable-next-line react/no-array-index-key
            key={`bar-${i}`}
            style={[
              styles.factorBar,
              styles[`${type}FactorBar`],
              withRecommended
                ? {
                    backgroundColor: GRAPH_BAR_CUSTOM_BACKGROUND_COLOR[i],
                    top: GRAPH_BAR_TOP_VALUE[i]
                  }
                : { top: 26 },
              {
                [barsAlignment]: 0,
                width
              }
            ]}
          />
        ))}
        <View style={[styles.factorRowSeparator, styles[`${type}FactorRowSeparator`]]} />
        <View style={[styles.factorRowSeparator, styles[`${type}FactorRowSeparator`]]} />
        {lastRow && (
          <View style={[styles.factorScale, styles[`${type}FactorScale`]]}>
            <Text style={[styles.factorScaleText, styles[`${type}FactorScaleText`]]}>
              {PRISM_MAX_SCORE}
            </Text>
            <Text style={[styles.factorScaleText, styles[`${type}FactorScaleText`]]}>
              {PRISM_MAX_SCORE / 2}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

FactorRow.propTypes = {
  lastRow: PropTypes.bool,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired,
  withRecommended: PropTypes.object
};

FactorRow.defaultProps = {
  lastRow: false,
  withRecommended: null
};

const FocusedPrismFactorsChart = ({
  recommendedName,
  recommendedSummary,
  targetName,
  targetSummary
}) => {
  const concentrated = [targetSummary.concentrated];
  const correlation = [targetSummary.correlation];
  const tail = [targetSummary.tail];
  const volatility = [targetSummary.volatility];

  if (recommendedSummary) {
    concentrated.push(recommendedSummary.concentrated);
    correlation.push(recommendedSummary.correlation);
    tail.push(recommendedSummary.tail);
    volatility.push(recommendedSummary.volatility);
  }

  return (
    <View style={styles.body}>
      {recommendedSummary && (
        <Legend
          targetName={targetName || 'Current portfolio'}
          recommendedName={recommendedName || 'Model'}
          withRecommended
        />
      )}
      <View style={styles.table}>
        <View style={styles.row}>
          <View style={[styles.headerCell, styles.downsideHeaderCell]}>
            <Text style={styles.headerText}>Downside Focused PRISM Factors</Text>
          </View>
          <View style={[styles.headerCell, styles.upsideHeaderCell]}>
            <Text style={styles.headerText}>Upside Focused PRISM Factors</Text>
          </View>
        </View>
        <View style={[styles.row, { marginBottom: 10 }]}>
          <View style={[styles.cell, { padding: 0 }]}>
            <View style={styles.factorRowWrapper}>
              <FactorRow
                title="Tail Event Risk"
                type={DOWNSIDE_FACTOR}
                values={tail}
                withRecommended={recommendedSummary}
              />
              <FactorRow
                title="Diversification Risk"
                type={DOWNSIDE_FACTOR}
                values={correlation}
                withRecommended={recommendedSummary}
                lastRow
              />
            </View>
          </View>
          <View style={[styles.cell, { padding: 0, marginRight: 0 }]}>
            <View style={styles.factorRowWrapper}>
              <FactorRow
                title="Volatility Risk"
                type={UPSIDE_FACTOR}
                values={volatility}
                withRecommended={recommendedSummary}
              />
              <FactorRow
                title="Concentrated Stock Risk"
                type={UPSIDE_FACTOR}
                values={concentrated}
                withRecommended={recommendedSummary}
                lastRow
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

FocusedPrismFactorsChart.propTypes = {
  recommendedName: PropTypes.string,
  recommendedSummary: PropTypes.object,
  targetName: PropTypes.string,
  targetSummary: PropTypes.object.isRequired
};

FocusedPrismFactorsChart.defaultProps = {
  recommendedName: '',
  recommendedSummary: null,
  targetName: ''
};

export default FocusedPrismFactorsChart;
