import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { AdvisorContext } from 'app/containers/advisor';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import { numToRiskScaleString } from 'utils/utils';
import { RISK_LEVELS } from 'app/containers/risk-tolerance-questionnaire/result/score-context/utils/constants';
import './styles.scss';

const ScoreBubbleDescription = ({ score, scoreDisplay }) => {
  const { user } = useContext(AdvisorContext);

  const riskLevels = user?.advisor?.company?.risk_levels || RISK_LEVELS;

  const scoreOverall = Math.ceil(score);
  return (
    <div className="bubble-score-container">
      <ScoreBubble score={score} scoreDisplay={scoreDisplay} />
      <div
        className={`bubble-score-container__score score-representation-${scoreOverall} score-representation-full-prism `}
      >
        <span className="text">{numToRiskScaleString(score, riskLevels)}</span>
      </div>
    </div>
  );
};

ScoreBubbleDescription.propTypes = {
  score: PropTypes.number.isRequired,
  scoreDisplay: PropTypes.number.isRequired
};

export default ScoreBubbleDescription;
