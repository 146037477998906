import cn from 'classnames';
import EmailTemplate from 'components/advisor/templates/email';
import TextTemplate from 'components/advisor/templates/text/';
import {
  CHECK_IN_EMAIL,
  EMAIL_DISCLOSURE,
  IPS_REVIEW_EMAIL,
  QUESTIONNAIRE_EMAIL
} from 'containers/advisor/templates/company-meta/contants';
import DEFAULT_TEMPLATES from 'containers/advisor/templates/defaults';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import EmailCard from './email-card';
import './styles.scss';

const EmailsButtonsAndDisclosure = ({ templates, handleSaveTemplate }) => {
  const [showEmailTemplate, setShowEmailTemplate] = useState(false);
  const [currentTemplateProps, setCurrentTemplateProps] = useState(null);

  const getTemplateContent = key => templates[key] || DEFAULT_TEMPLATES[key];

  const handleModifyTemplate = templateProps => {
    setShowEmailTemplate(true);
    setCurrentTemplateProps(templateProps);
  };

  const emailTemplates = [
    {
      title: 'Questionnaire Email',
      key: QUESTIONNAIRE_EMAIL
    },
    {
      title: 'IPS Review Email',
      key: IPS_REVIEW_EMAIL
    },
    {
      title: 'Check-in Email',
      key: CHECK_IN_EMAIL
    }
  ];

  const disclosureTemplateProps = {
    target: EMAIL_DISCLOSURE,
    content: getTemplateContent(EMAIL_DISCLOSURE),
    handleSaveTemplate
  };

  return (
    <div id="emails-buttons-and-disclosure">
      {showEmailTemplate && currentTemplateProps ? (
        <>
          <div className="modfy-email__container">
            <h3>
              {currentTemplateProps.target
                .replaceAll('_', ' ')
                .replace(/\b\w/g, char => char.toUpperCase())}
            </h3>
            <button
              type="button"
              className="btn btn-transparent btn-go-back-tools"
              onClick={() => {
                setShowEmailTemplate(false);
                setCurrentTemplateProps(null);
              }}
            >
              <i className="icon-up icon-arrow-rotate-Left" /> Back
            </button>
          </div>
          <div
            className={cn({
              'email-template-hidden': !showEmailTemplate
            })}
          >
            <EmailTemplate {...currentTemplateProps} />
          </div>
        </>
      ) : (
        <>
          <h3>Emails</h3>
          <div className="disclosure-section">
            <div className="buttons-group">
              {emailTemplates.map(({ title, key }) => {
                const templateProps = {
                  target: key,
                  content: getTemplateContent(key),
                  handleSaveTemplate
                };
                return (
                  <EmailCard
                    key={key}
                    title={title}
                    subject={templateProps.content.subject}
                    onModify={() => handleModifyTemplate(templateProps)}
                  />
                );
              })}
            </div>
            <hr />
            <h3>Email Disclosure</h3>
            <TextTemplate {...disclosureTemplateProps} />
          </div>
        </>
      )}
    </div>
  );
};

EmailsButtonsAndDisclosure.propTypes = {
  templates: PropTypes.object,
  handleSaveTemplate: PropTypes.func.isRequired
};

EmailsButtonsAndDisclosure.defaultProps = {
  templates: {}
};

export default EmailsButtonsAndDisclosure;
