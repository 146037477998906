/* eslint-disable no-underscore-dangle */
/* @flow */

import { compareVersions } from 'compare-versions';
import {
  GET_IN_TOUCH_URL_PARAM,
  HAS_CHECKIN_URL_PARAM,
  NEGATIVE,
  POSITIVE,
  SENTIMENT_URL_PARAM
} from 'components/advisor/dashboard/widgets/check-ins-review-widget/utils';
import { DRIFT_LEVELS_DATA_OPTIONS } from 'components/advisor/dashboard/widgets/drift-level-widget/config';
import { RISK_LEVELS } from 'containers/risk-tolerance-questionnaire/result/score-context/utils/constants';
import Dinero from 'dinero.js';
import DOMPurify from 'dompurify';
import { ENGLISH_LANGUAGE } from 'lang/constants';
import _ from 'lodash';
import moment from 'moment';
import { getRiskScoreLevel } from 'utils/scores';
import config from '../config';

type Browser = {
  name: string,
  version: string
};

/**
 * Returns browser information
 * @returns Browser
 */
export function getBrowser(): Browser {
  const ua = navigator.userAgent;

  let tem;

  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];

    return {
      name: 'IE',
      version: tem[1] || ''
    };
  }

  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/);

    if (tem)
      return {
        name: 'Opera',
        version: tem[1]
      };
  }

  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];

  tem = ua.match(/version\/(\d+)/i);
  if (tem) M.splice(1, 1, tem[1]);

  return {
    name: M[0],
    version: M[1]
  };
}

/**
 * Returns -1 - unspecified browser, 0 - unsupported browser version, 1 - supported browser
 * @returns {number}
 */
export function checkBrowserSupport(): -1 | 0 | 1 {
  const { supportedBrowsers } = config;
  const browser = getBrowser();
  let isSupported = false;
  let isUnspecifiedBrowser = true;

  Object.keys(supportedBrowsers).forEach(browserName => {
    if (browserName.toUpperCase() == browser.name.toUpperCase()) {
      const cmp = compareVersions(browser.version, supportedBrowsers[browserName]);

      isSupported = cmp >= 0;
      isUnspecifiedBrowser = false;
    }
  });

  return isUnspecifiedBrowser ? -1 : isSupported ? 1 : 0;
}

export function numToRiskScaleString(num: number, riskLevels = RISK_LEVELS): string {
  const level = getRiskScoreLevel(num, ENGLISH_LANGUAGE, riskLevels);
  return level.label || '';
}

export function snakeToCamel(s: string): string {
  return s.replace(/(_\w)/g, m => m[1].toUpperCase());
}

type Position = {
  type: number,
  subtype: number,
  sector: number
};

export function capFirstChar(string: string) {
  return string[0].toUpperCase() + string.slice(1);
}

export function range(q: number, end: number): Array<number> {
  let start;
  let stop;
  if (end) {
    start = q;
    stop = end;
  } else {
    start = 0;
    stop = q;
  }
  const result = [];
  for (let i = start; i <= stop; ++i) result.push(i);
  return result;
}

export function setPrecision(number: number, digits: number): number {
  const helper = 10 ** digits;
  return Math.round(number * helper) / helper;
}

type DateRange = {
  start_date: string,
  end_date: string
};

export function parseDate(dateString: string, yearFirst: boolean = true): Date {
  let y;
  let m;
  let d;
  if (yearFirst)
    [y, m, d] = dateString.split('-').map((val: string): number => Number.parseInt(val));
  else [m, d, y] = dateString.split('-').map((val: string): number => Number.parseInt(val));

  return new Date(y, m, d);
}

export const dateDiffOps = {
  SECONDS: 1000,
  MINUTES: 1000 * 60,
  HOURS: 1000 * 60 * 60,
  DAYS: 1000 * 60 * 60 * 24
};

export function dateDiff(date1: Date, date2: Date, option: number) {
  return Math.floor(Math.abs(date1.getTime() - date2.getTime()) / option);
}

export function isNum(val: any): boolean {
  return !Number.isNaN(Number.parseFloat(val));
}

export function buildQueryString(params: {}): string {
  const keys = Object.keys(params);
  return keys.length ? `?${keys.map(k => `${k}=${params[k]}`).join('&')}` : '';
}

export function getSubstring(string, char1, char2) {
  return string ? string.slice(string.indexOf(char1) + 1, string.lastIndexOf(char2)) : '';
}

export function isSharpeRatio(val: string): boolean {
  return val.toLowerCase() === 'sharpe ratio';
}

export function formattedNumber(number: number, decimals: number): string {
  if (!decimals) decimals = 2;
  return number.toFixed(decimals).replace(/[.,]00$/, '');
}

export function renameProp(
  oldProp: string,
  newProp: string,
  // $FlowFixMe
  { [oldProp]: old, ...others }: Object
): Object {
  return { [newProp]: old, ...others };
}

export function getUserName(user: Object, me: boolean): string {
  const meTag = me ? `[Me] ` : '';
  return user ? `${meTag}${user.first_name} ${user.last_name}` : '';
}

export function formatMoney(value: any): string {
  return `${Dinero({ amount: parseInt(value * 100, 10) }).toFormat('$0,0')}`;
}

export function formatPercentage(
  value: any,
  multiplier: number = 100,
  decimals: number = 2
): string {
  if (value === 0) return '-';
  return `${parseFloat(value * multiplier).toFixed(decimals)}%`;
}

export function riskLevel(score: number): string {
  // https://stratifi.atlassian.net/wiki/spaces/ENP/pages/208502785/PRISM+rating+across+accounts+-+What+is+Expected
  if (score < 4.5) return 'low';
  if (score < 7.5) return 'neutral';
  /* if (score >= 7.5) */ return 'high';
}

export function selectPrismScore(scores: Object, scoreName: string): number | null {
  if (!scores || _.isEmpty(scores)) return null;

  let score;
  switch (scoreName) {
    case 'Volatility':
      score = scores.volatility;
      break;
    case 'Diversification':
      score = scores.correlation;
      break;
    case 'Concentrated Stock':
      score = scores.concentrated;
      break;
    case 'Tail':
      score = scores.tail;
      break;
    case 'PRISM':
    default:
      score = scores.overall;
  }
  return score;
}

export function selectTargetScore(scores: Object, scoreName: string): number | null {
  if (!scores || _.isEmpty(scores)) return null;
  return scores.overall;
}

export function getInvestorTargetScore(investor) {
  const emptyScore = { data: {} };

  if (!investor) return emptyScore;

  if (!_.isEmpty(investor.aggregated_target_scores))
    return {
      data: investor.aggregated_target_scores,
      manual: !investor.target_questionnaire_score
    };

  if (!_.isEmpty(investor.investor_target_score)) return investor.investor_target_score;

  return emptyScore;
}

export function getInvestorRiskToleranceLatestUpdate(investor) {
  if (!_.isEmpty(investor.investor_target_score))
    if (investor.investor_target_score.modified) return investor.investor_target_score.modified;

  return null;
}

export function getAccountsRiskToleranceLatestUpdate(accounts) {
  if (!_.isEmpty(accounts)) {
    const dates = accounts
      .map(a => a.target_score_updated_at && moment.utc(a.target_score_updated_at))
      .filter(b => b && b);

    if (dates && !_.isEmpty(dates)) return moment.max(dates).format();
  }

  return null;
}

export function getRiskToleranceLatestUpdate(investor, accounts, extraDates = []) {
  const dates = [
    getInvestorRiskToleranceLatestUpdate(investor),
    getAccountsRiskToleranceLatestUpdate(accounts),
    ...extraDates
  ]
    .filter(d => !!d)
    .map(d => moment.utc(d));

  if (dates && !_.isEmpty(dates)) return moment.max(dates).format();

  return null;
}

export function getInvestorRtqSentTimestamp(investor) {
  if (investor.target_questionnaire_mail_sent) {
    const sentTimestamp = investor.target_questionnaire_mail_sent_date;

    if (!sentTimestamp && investor.accounts && !_.isEmpty(investor.accounts)) {
      const tsAccounts = investor.accounts
        .map(
          a => a.target_questionnaire_mail_sent && moment.utc(a.target_questionnaire_mail_sent_date)
        )
        .filter(b => b && b);
      if (tsAccounts && !_.isEmpty(tsAccounts)) return moment.max(tsAccounts).format();
    }

    return sentTimestamp;
  }

  return null;
}

export const getAccountQuestionnaires = (accounts, questionnaires) => {
  const accountQuestionnaires = accounts.reduce(
    (acc, account) => ({ ...acc, [account.id]: null }),
    {}
  );
  questionnaires.forEach(questionnaire => {
    const questionnaireWithAccountAnswers =
      questionnaire && questionnaire?.accounts && questionnaire.accounts.length > 0;
    if (questionnaireWithAccountAnswers)
      questionnaire.accounts.forEach(accountId => {
        if (!accountQuestionnaires[accountId]) accountQuestionnaires[accountId] = questionnaire;
      });
    else {
      const missingAccountIds = Object.keys(accountQuestionnaires).filter(
        accountId => !accountQuestionnaires[accountId]
      );
      missingAccountIds.forEach(accountId => {
        accountQuestionnaires[accountId] = questionnaire;
      });
    }
  });
  return accountQuestionnaires;
};

const sumArrayValues = values => values.reduce((p, c) => p + c, 0);

export function weightedMean(factorsArray: Array<any>, weightsArray: Array<any>): number {
  return (
    sumArrayValues(factorsArray.map((factor, index) => factor * weightsArray[index])) /
    sumArrayValues(weightsArray)
  );
}

export function getScoreAgg(accounts: Array<any>, type: string = 'prism_score_summary'): any {
  const values = [];
  const overall = [];
  const concentrated = [];
  const correlation = [];
  const tail = [];
  const volatility = [];

  accounts.forEach(a => {
    const summary = a[type];
    if (!summary) return null;
    values.push(a.value);
    overall.push(summary.overall);
    concentrated.push(summary.concentrated ? summary.concentrated : summary.overall);
    correlation.push(summary.correlation ? summary.correlation : summary.overall);
    tail.push(summary.tail ? summary.tail : summary.overall);
    volatility.push(summary.volatility ? summary.volatility : summary.overall);
  });
  return {
    overall: parseFloat(weightedMean(overall, values).toFixed(1)),
    concentrated: parseFloat(weightedMean(concentrated, values).toFixed(1)),
    correlation: parseFloat(weightedMean(correlation, values).toFixed(1)),
    tail: parseFloat(weightedMean(tail, values).toFixed(1)),
    volatility: parseFloat(weightedMean(volatility, values).toFixed(1))
  };
}

export function combinePortfolios(selectedTargetMultiple: Array<any>): any {
  const combinedPositions = [];
  const combinedPortfolio = {
    value: 0
  };

  // Prevent changing the original array
  const clonedPortfolios = _.cloneDeep(selectedTargetMultiple);

  clonedPortfolios.forEach(account => {
    account.positions.forEach(position => {
      const idx = combinedPositions.findIndex(combined => combined.ticker === position.ticker);
      if (idx < 0) combinedPositions.push(position);
      else combinedPositions[idx].value += position.value;
    });
    combinedPortfolio.value += account.value;
  });

  const prismAgg = getScoreAgg(clonedPortfolios);
  const targetAgg = getScoreAgg(clonedPortfolios, 'target_score_summary');

  return {
    ...combinedPortfolio,
    display_name: clonedPortfolios.map(p => p.display_name).join(', '),
    positions: combinedPositions.map(position => ({
      ...position,
      // defines the weight of the positions to be used when displaying
      // the breakdown of the custom securities
      weight: position.value / combinedPortfolio.value
    })),
    prism_score_summary: prismAgg,
    target_score_summary: targetAgg
  };
}

/**
 * Return the closest models to the target account score. One above and one bellow (If exists)
 * @param {Array} models
 * @param {number} targetAccountValue
 * @param {number} prismAccountValue
 * @returns Array of closest under/above
 */
export function getMatchingPrismModels(
  models: Array<any>,
  targetAccountValue: number,
  prismAccountValue: number
): Array<any> {
  if (targetAccountValue === prismAccountValue) return [];
  let closestUnder;
  let closestAbove;
  if (prismAccountValue) {
    models.forEach(model => {
      const modelValue = model.prism_overall;
      const closestUnderValue = closestUnder?.prism_overall;
      const closestAboveValue = closestAbove?.prism_overall;

      // only consider values with up to difference of MATCH_MODELS_DIFFERENCE
      if (modelValue && Math.abs(targetAccountValue - modelValue) <= config.MATCH_MODELS_DIFFERENCE)
        if (
          targetAccountValue >= modelValue &&
          (!closestUnderValue || modelValue > closestUnderValue)
        )
          closestUnder = model;
        else if (
          targetAccountValue < modelValue &&
          (!closestAboveValue || modelValue < closestAboveValue)
        )
          closestAbove = model;
    });
    return [closestUnder, closestAbove].filter(model => model);
  }
  return [];
}

export const getScores = (source: Array<any>) => {
  /*  Given a source element, most likely an account or a list of accounts,
      return its prism and target score.

      If the source is a list of accounts the returned scores are the correspondent
      weightedMean of the accounts in the list.
  */
  let prism;
  let target;
  if (Array.isArray(source)) {
    const validSources = source.filter(a => a.prism_score_summary && a.target_score_summary);
    prism = weightedMean(
      validSources.map(a => a.prism_score_summary.overall),
      validSources.map(a => a.value)
    );
    target = weightedMean(
      validSources.map(a => a.target_score_summary.overall),
      validSources.map(a => a.value)
    );
  } else if (source.prism_score_summary && source.target_score_summary) {
    prism = source.prism_score_summary.overall;
    target = source.target_score_summary.overall;
  } else return null;
  return { prism, target };
};

/**
 * Given a source element, most likely an account or a list of accounts,
 * and a list of model portfolios, split the last one into the suggested
 * models according the source prism and target, and the rest.
 * @param {Array} source
 * @param {Array} models
 * @returns Array of replicas, matching, others and recommended models
 */
export const getSuggestedModels = (source: Array<any>, models: Array<any>) => {
  const replicas = [];
  const matching = [];
  let recommendedModels = [];
  if (!_.isEmpty(source) && models.length) {
    // get the replica models (model.source === source.id)
    const sourceIds = Array.isArray(source) ? source.map(item => item.id) : [source.id];

    /* Matching Models */
    if (source.length)
      source.forEach(({ target_model: targetModel, value }) => {
        if (targetModel) {
          const matchingModel = models.find(t => t.id === targetModel.id);
          if (matchingModel) matching.push({ ...matchingModel, value });
        }
      });
    matching.forEach(matchingModel => {
      const find = models.find(m => m.id === matchingModel.id);
      if (find) models = models.filter(m => m.id !== matchingModel.id);
    });

    /* Proposed Models */
    models.forEach(model => {
      if (model.source && sourceIds.includes(model.source)) {
        replicas.push(model);
        models = models.filter(m => m.id !== model.id);
      }
    });

    /* Recommended Models */
    if (!matching.length) {
      // automatically get matching models using scores (model.score matches source.tolerance)
      const scores = getScores(source);
      if (scores) {
        recommendedModels = getMatchingPrismModels(models, scores.target, scores.prism);
        recommendedModels.forEach(element => {
          models = models.filter(m => m.id !== element.id);
        });
      }
    }
  }
  const others = models.filter(model => !replicas.includes(model) && !matching.includes(model));
  return [replicas, matching, others, recommendedModels];
};

export const roundAccountValue = (accountValue: number) => {
  const factor = accountValue < 10000 ? 1000 : 10000;
  return accountValue <= 1000 ? accountValue : Math.round(accountValue / factor) * factor;
};

export const getInvestorOwner = (investor, user) =>
  investor.advisor && investor.advisor.user ? investor.advisor.user : user;

export const fromNow = date =>
  moment.utc(date).fromNow(true).replace('an hour', '1 hr').replace('a few seconds', '1 min');

export const addURLParams = (base, params) => {
  const url = new URL(base);
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });
  return url.toString();
};

export const getNormalizedPercentage = value => {
  const currentValue = Number(value) / 100;
  return Number(currentValue.toFixed(4));
};

export const withCurrencyFormat = (value, notation = 'standard', maximumFractionDigits = 2) =>
  Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
    notation,
    maximumFractionDigits
  }).format(value);

export const sleep = seconds =>
  new Promise(resolve => {
    setTimeout(resolve, seconds * 1000);
  });

/* positions-taxonomy mapping */

/**
 * Find the matching taxonomy for a given classification.
 * @param {Array<Taxonomy>} taxonomies List of taxonomies
 * @param {Number} type Asset type
 * @param {Number} subtype Asset subtype
 * @param {Number} sector Asset sector
 * @returns Taxonomy object that matches the given classification, or null if no matches are found.
 */
const getTaxonomy = (taxonomies, type, subtype = null, sector = null) =>
  taxonomies.find(t => t.type === type && t.subtype === subtype && t.sector === sector);

/**
 * Return the proper label for the given classification.
 * @param {Array<Taxonomy>} taxonomies List of taxonomies
 * @param {Number} type
 * @param {Number} subtype
 * @param {Number} sector
 * @returns The string with the classification label
 */
const getTaxonomyLabel = (taxonomy, type, subtype, sector) => {
  if (taxonomy?.label) return taxonomy.label;
  if (!_.isNil(subtype) && !_.isNil(sector)) {
    const subtypeLabel = config.asset.subtype.labels[subtype] ?? '';
    const sectorLabel = config.asset.sector.labels[sector] ?? '';
    return subtypeLabel && subtypeLabel === sectorLabel
      ? subtypeLabel
      : `${subtypeLabel} ${sectorLabel}`.trim();
  }
  if (type) return config.asset.type.labels[type] ?? '';
  return '-';
};
/**
 * Return a function that expect a list of positions and return them grouped
 * by a given attribute and processed by a given callback.
 * @param {String} groupAttr Attribute used to group the collection by
 * @param {Function} callback Function to process the position of each group
 * @param {Any} defaultValue Default value to use if no groups are found
 * @returns An object where keys are the different values of the groupAttr,
 *          and the values are the result of the callback function.
 */
const getTaxonomyLevel = (groupAttr, callback, defaultValue, taxonomies) => positions =>
  _.chain(positions)
    .groupBy(groupAttr)
    .entries()
    .reduce(
      (acum, [key, ps]) => {
        let label;
        const content = callback(ps);

        if (_.isPlainObject(content)) content.__id = key;

        if (groupAttr === 'name') label = key;
        else {
          const source = key.split(',').map(i => parseInt(i, 10));
          const [type, subtype, sector] = source;
          const taxonomy = getTaxonomy(taxonomies, type, subtype, sector);
          label = getTaxonomyLabel(taxonomy, type, subtype, sector);

          if (_.isPlainObject(content)) {
            content.__order = taxonomy?.order ?? null;
            content.__source = source;
          }
        }

        return {
          ...acum,
          [label]: {
            ...acum[label], // Merge existing content
            ...content, // Add new content
            __value: (acum[label]?.__value ?? 0) + content.__value // Summarize the value
          },
          __value: (acum.__value ?? 0) + ps.reduce((acum, p) => acum + p.value, 0)
        };
      },

      defaultValue
    )
    .value();

/**
 * Given a list of positions, return the nested taxonomies.
 * - The first level is formed by the positions types.
 * - The second level is formed by the subtype and sector (only if withAssetClassification).
 * - The third level is optional and contains the asset names (only if withAssetDetails).
 * - The leafs contain the asset values in that group.
 *
 * Each label is represented by the custom taxonomy label or the default StratiFi label.
 * Additionally, each level aggregated the position values in an attribute named __value.
 *
 * @param {Array<Position>} portfolio
 * @param {Array<Taxonomy>} taxonomies
 * @param {Boolean} withAssetClassification
 * @param {Boolean} withAssetDetails
 * @returns A nested object withe the level described above.
 */
export const getPositionsTaxonomy = (
  portfolio,
  taxonomies,
  withAssetClassification = true,
  withAssetDetails = true,
  breakdownCustomSecurities = false
) => {
  if (!portfolio.positions || !taxonomies) return {};

  const DEFAULT_SUBTYPE = 0;
  const DEFAULT_SECTOR = 0;

  const getProcessedPosition = position => ({
    name: position.ticker_name || position.ticker,
    value: Number.parseFloat(position.value) || 0,
    _l1: `${position.type}`,
    _l2: `${position.type},${position.subtype},${position.sector}`
  });

  const getProcessedPositionAllocations = (position, positions) => allocation => {
    positions.push({
      name: position.ticker_name || position.ticker,
      value: (Number.parseFloat(position.value) * Number.parseFloat(allocation.value)) / 100 || 0,
      _l1: `${allocation.type}`,
      _l2: `${allocation.type},${allocation.subtype ?? DEFAULT_SUBTYPE},${
        allocation.sector ?? DEFAULT_SECTOR
      }`
    });
  };

  const positions = [];
  portfolio.positions.forEach(position => {
    // Case #1 - Check if the position comes from a custom security
    if (breakdownCustomSecurities && position.is_custom && position.security_underlying_model) {
      // Creates an array with the positions of the underlying model
      const underlyingModelPositions = position.security_underlying_model.positions.map(ump => {
        const data = getProcessedPosition(ump);
        return {
          ...data,
          security_allocations: ump.security_allocations,
          ticker_name: ump.ticker_name,
          ticker: ump.ticker,
          value: (data.value * (Number.parseFloat(position.value) || 0)) / 100
        };
      });
      // Check for each position if it has `security_allocations` and use them instead.
      // Otherwise, handles everything as the default case.
      underlyingModelPositions.forEach(ump => {
        if (ump.security_allocations && !_.isEmpty(ump.security_allocations))
          ump.security_allocations
            .filter(allocation => !!allocation.value)
            .forEach(getProcessedPositionAllocations(ump, positions));
        else positions.push(ump);
      });
    }
    // Case #2 - If the position has `security_allocations`, use these instead of the traditional classification
    else if (position.security_allocations && !_.isEmpty(position.security_allocations))
      position.security_allocations
        .filter(allocation => !!allocation.value)
        .forEach(getProcessedPositionAllocations(position, positions));
    // Case #3 - Default
    else positions.push(getProcessedPosition(position));
  });

  const valueReducer = ps => ({
    __value: _.reduce(ps, (acum, p) => acum + p.value, 0)
  });
  const nameReducer = getTaxonomyLevel('name', valueReducer, {}, taxonomies);
  const l3Reducer = withAssetDetails ? nameReducer : valueReducer;
  const l2Reducer = withAssetClassification
    ? getTaxonomyLevel('_l2', l3Reducer, {}, taxonomies)
    : valueReducer;
  const l1Reducer = getTaxonomyLevel('_l1', l2Reducer, {}, taxonomies);
  const outcome = l1Reducer(positions);
  return outcome;
};

/**
 * Given a nested taxonomy map, remove the meta data and return an array of taxonomies
 * sorted by the following conditions:
 * - if the levels to compare have order, use them.
 * - give priority to the levels with order over the ones without order.
 * - if the levels to compare don't have order, sort by by key (alphabetically).
 * - if the the keys are not strings, keep the current order.
 */
export const getTaxonomyLevelIterator = data =>
  Object.entries(data)
    .filter(([key, level]) => !_.isPlainObject(key) && _.isPlainObject(level))
    .sort(([aKey, aLevel], [bKey, bLevel]) => {
      if (_.isNumber(aLevel.__order) && _.isNumber(bLevel.__order))
        return aLevel.__order - bLevel.__order;
      if (_.isNumber(bLevel.__order)) return 1;
      if (_.isNumber(aLevel.__order)) return -1;
      if (_.isString(aKey) && _.isString(bKey)) return aKey.localeCompare(bKey);
      return 0;
    });

export const sanitizeUrl = url => encodeURI(DOMPurify.sanitize(url));

export const getWidgetUrl = (investorId, params = {}) => {
  const url = new URL(`/investor/${investorId}/target-rating`, window.location.origin);
  Object.entries(params).forEach(([key, value]) => {
    if (value) url.searchParams.set(key, value);
  });
  return url.href;
};

/**
 * Return the URL of the PDF report by concatenating the `v` parameter in order to avoid
 * getting the same PDF that is already cached, and always force the browser to download
 * the latest version available.
 * @param {Object} report
 * @returns the URL of the PDF report
 */
export const getReportUrl = report => {
  if (report?.signed_pdf_report) return `${report.signed_pdf_report}?v=${report.modified}`;
  if (report?.pdf_report) return `${report.pdf_report}?v=${report.modified}`;
  return null;
};

/**
 * Order all sections of the template by their `order` attribute except for the cover and footer,
 * which will always be first and last respectively.
 * @param {object} a
 * @param {object} b
 * @returns the ordered sections
 */
export const byTemplateSectionOrder = (a, b) => {
  if (a[1].footer || b[1].cover) return 1;
  if (a[1].cover || b[1].footer) return -1;
  return (a[1].order || 0) - (b[1].order || 0);
};

/**
 * Filter all non-fixed sections used in the report.
 * @param {bool} section `[key, content]` per each section.
 * @returns the condition to filter the section.
 */
export const byNonFixedSections = ([key, metadata]) => !metadata.cover && !metadata.footer;

/**
 * Get all account IDs involved in the proposal.
 * @param {object} proposal
 * @returns an array with all the account IDs
 */
export const getTargetAccountIds = proposal =>
  proposal.target.group_details
    ? proposal.target.group_details.map(account => account.id).sort()
    : [proposal.target.id];

/**
 * Get all investors involved in the proposal.
 * @param {object} proposal
 * @returns an array with all the investors
 */
export const getTargetInvestors = proposal => {
  const investors = [];

  if (proposal.target.group_details)
    proposal.target.group_details.forEach(account => {
      if (!investors.find(investor => investor.id === account.investor.id))
        investors.push({
          data_points: account.investor.data_points,
          email: account.investor.email,
          id: account.investor.id,
          is_prospect: account.investor.is_prospect,
          name: account.investor.full_name,
          signature_request_url: account.investor.signature_request_url
        });
    });
  else if (proposal.target.investor)
    investors.push({
      data_points: proposal.target.investor.data_points,
      email: proposal.target.investor.email,
      id: proposal.target.investor.id,
      is_prospect: proposal.target.investor.is_prospect,
      name: proposal.target.investor.full_name,
      signature_request_url: proposal.target.investor.signature_request_url
    });

  return investors;
};

/*
 * Get the portfolio for update considering the positions and deleted positions.
 * @param {object} portfolio (account or model portfolio)
 * @returns the portfolio with the positions and deleted positions merged.
 *
 * Notice that the original portfolio or its positions are not modified.
 * Instead a new object is created with the merged positions.
 */
export const getPortfolioForUpdate = portfolio => {
  if (!portfolio) return null;

  const positions = portfolio.positions || [];
  const deletedPositions = portfolio.deleted_positions || [];

  return { ...portfolio, positions: [...positions, ...deletedPositions] };
};

/**
 * Allows getting a new array with the unique objects that compose it.
 * Uniqueness is guaranteed through the `id` attribute of each object.
 * @param {array} items
 * @returns Unique objects array
 */
export const getUniqueObjectsArray = items =>
  items.filter((item, idx) => idx === items.findIndex(i => item.id === i.id));

/**
 * Allows to obtain the text corresponding to the filter to be used in the section and thus
 * guarantee a visual feedback to the user.
 * @param {object} query URL params
 * @returns filter text for the section
 */
export const getFilterSubtitle = query => {
  const driftLevel = query?.drift_level && query.drift_level.replace('-', '_');
  if (driftLevel in DRIFT_LEVELS_DATA_OPTIONS)
    return DRIFT_LEVELS_DATA_OPTIONS[driftLevel].description;

  const sentiment = query?.[SENTIMENT_URL_PARAM];
  if (sentiment === POSITIVE) return 'Positive Sentiment';
  if (sentiment === NEGATIVE) return 'Negative Sentiment';

  const hasCheckIn = query?.[HAS_CHECKIN_URL_PARAM];
  if (hasCheckIn) return 'Check-in available';

  const getInTouch = query?.[GET_IN_TOUCH_URL_PARAM];
  if (getInTouch) return 'Requesting Contact';

  return '';
};

/**
 * Gets the advisor ID coming from the URL query param (if possible)
 * @param {object} authProvider
 * @returns the advisor ID
 */
export const getAdvisorIdFromSearchParams = authProvider => {
  const url = new URL(window.location.href);
  const advisorId = url.searchParams.get('by_advisor');

  if (advisorId) {
    authProvider.setAdvisorByManager(Number(advisorId));
    url.searchParams.delete('by_advisor');
    window.history.replaceState(null, '', url);
  }

  return advisorId;
};
