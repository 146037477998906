import MultipleInput from 'components/multiple-input';
import HouseholdIcon from 'components/svg-icons/household-icon';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router';
import { formatMoney } from 'utils/utils';
import './styles.scss';

const InvestorDetailStats = ({
  countAccounts,
  handleJoinHousehold,
  households,
  investor,
  joinHouseholdLoading,
  onHouseholdChange,
  onHouseholdSelected,
  showModal,
  totalValue
}) => (
  <div className="investor-stats">
    <div className="investor-stats__stats">
      <span>
        Accounts: <b>{countAccounts}</b>
      </span>
      <span>
        Total assets: <b>{formatMoney(totalValue)}</b>
      </span>
    </div>

    <div className="investor-stats__household">
      {investor && investor.household ? (
        <Link to={`/advisor/households/${investor.household.id}`}>
          <div className="btn btn-secondary btn-sm household__badge">
            <HouseholdIcon className="household-icon" />
            <span>{investor.household.name}</span>
          </div>
        </Link>
      ) : (
        <div className="household__add-member">
          <div className="form-group search">
            <MultipleInput
              autosuggestProps={{
                suggestions: households,
                getSuggestionValue: e => e.name,
                renderSuggestion: e => <span>{e.name}</span>
              }}
              inputProps={{
                type: 'text',
                onChange: onHouseholdChange,
                placeholder: 'Add Household'
              }}
              onItemStaged={onHouseholdSelected}
              onItemRemoved={() => {}}
              renderSelectedItem={inv => <span>{inv.name}</span>}
              selectedItems={[]}
              theme={{
                container: 'react-autosuggest__container',
                containerOpen: 'open react-autosuggest__container--open',
                suggestionsContainer: 'dropdown-menu react-autosuggest__suggestions-container',
                suggestion: 'dropdown-item-new react-autosuggest__suggestion'
              }}
              disabled={joinHouseholdLoading}
            />
          </div>
          <input
            className="btn btn-secondary btn-small btn-join"
            type="button"
            value="Join"
            disabled={joinHouseholdLoading}
            onClick={() => handleJoinHousehold()}
          />
        </div>
      )}

      <span
        role="button"
        tabIndex={0}
        className="btn btn-outline-primary btn-sm"
        onKeyUp={() => {}}
        onClick={showModal}
      >
        Profile
      </span>
    </div>
  </div>
);

InvestorDetailStats.propTypes = {
  countAccounts: PropTypes.number.isRequired,
  countGoals: PropTypes.number.isRequired,
  handleJoinHousehold: PropTypes.func.isRequired,
  households: PropTypes.array.isRequired,
  householdSearch: PropTypes.string.isRequired,
  investor: PropTypes.object.isRequired,
  joinHouseholdLoading: PropTypes.bool.isRequired,
  onHouseholdChange: PropTypes.func.isRequired,
  onHouseholdSelected: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  totalValue: PropTypes.number.isRequired
};

export default InvestorDetailStats;
