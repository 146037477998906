/* global STANDARD_DATE_FORMAT */
import moment from 'moment';

const getMonthsAgo = month => moment().subtract(month, 'months').format(STANDARD_DATE_FORMAT);

const TODAY = moment().add(1, 'days').format(STANDARD_DATE_FORMAT);

export const VIEW_ALL = { label: 'View All', value: [] };

export const CUSTOM_RANGE = { label: 'Custom Range', value: [{}, {}] };

const FIRST_DAY_OF_MONTH = moment(`${moment().year()}-${moment().month() + 1}-01`).format(
  STANDARD_DATE_FORMAT
);
const FIRST_DAY_OF_YEAR = moment(`${moment().year()}-01-01`).format(STANDARD_DATE_FORMAT);
const FIRST_DAY_OF_LAST_YEAR = moment(`${moment().year() - 1}-01-01`).format(STANDARD_DATE_FORMAT);
const LAST_DAY_OF_LAST_YEAR = moment(`${moment().year()}-01-01`)
  .subtract(1, 'days')
  .format(STANDARD_DATE_FORMAT);

export const rangeOptions = [
  VIEW_ALL,
  { label: 'This month', value: [FIRST_DAY_OF_MONTH, TODAY] },
  { label: 'Last month', value: [getMonthsAgo(1), TODAY] },
  { label: 'Last 3 months', value: [getMonthsAgo(3), TODAY] },
  { label: 'Last 6 months', value: [getMonthsAgo(6), TODAY] },
  { label: 'YTD', value: [FIRST_DAY_OF_YEAR, TODAY] },
  { label: 'Last year', value: [FIRST_DAY_OF_LAST_YEAR, LAST_DAY_OF_LAST_YEAR] },
  CUSTOM_RANGE
];
