import {
  ADVISOR_LIST_ES,
  ADVISOR_STATS_GET,
  EXCEPTIONS_LIST_ES,
  FETCHING
} from 'constants/actions';
import moment from 'moment';
import config from '../config';
import DataProvider from './data';

class AdvisorsElasticProvider extends DataProvider {
  list(params) {
    this.dispatch(FETCHING);
    const promise = this.provider
      .get(`${config.apiBase}search/advisor/`, params)
      .then(({ data }) => {
        this.dispatch(ADVISOR_LIST_ES, {
          data: data.map(advisor => ({ ...advisor, url: '/advisor/surveillance/' }))
        });
        return data;
      });

    return promise;
  }

  getStats(params) {
    return this.provider
      .get(`${config.apiBase}search/advisor/stats/`, params)
      .then(({ data }) => this.dispatch(ADVISOR_STATS_GET, { data }));
  }
}

export default AdvisorsElasticProvider;
