/* eslint-disable import/no-cycle */
import cn from 'classnames';
import EmailCheckInForm from 'components/advisor/investors/email-check-in-form';
import { getExternalCheckInURL } from 'components/advisor/investors/email-check-in-form/utils';
import EmailToleranceForm from 'components/advisor/investors/email-tolerance-form';
import ManualToleranceForm from 'components/advisor/investors/manual-tolerance-form';
import {
  SuccessfullyCopiedAction,
  SuccessfullyEmailedAction
} from 'components/advisor/investors/successfully-tolerance-action';
import OrSeparator from 'components/or-separator';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { connect } from 'react-redux';
import Select from 'react-select';
import {
  questionnaireTemplatesSelector,
  scoredQuestionnaireTemplatesSelector
} from 'selectors/questions';
import { trackAmplitudeEvent } from 'utils/tracking';
import { getInvestorTargetScore } from 'utils/utils';
import './styles.scss';
import {
  getExternalQuestionnaireURL,
  getRiskToleranceScore,
  getTargetName,
  getUserName
} from './utils';

const TargetScoreAction = ({
  investors,
  isCheckInTemplate,
  isScoredQuestionnaireTemplate,
  mode,
  onAction,
  onAnwerQuestionnaire,
  onClose,
  onCopyURL,
  onPrint,
  onSaveManualTarget,
  onSelectSpecificAccounts,
  onSendMail,
  questionnaireTemplates
}) => {
  const {
    authProvider,
    questionProvider,
    user,
    user: {
      advisor: {
        company: {
          disable_advisor_emails: disableAdvisorEmails,
          default_questionnaire_template_id: defaultQuestionnaireTemplateId
        }
      }
    }
  } = useContext(AdvisorContext);

  const [activeForm, setActiveForm] = useState(null);
  const [selectedQuestionnaireTemplateId, setSelectedQuestionnaireTemplateId] = useState(undefined);
  const [sent, setSent] = useState(null);

  const amplitudeAction = isCheckInTemplate ? 'check_in_modal' : 'tolerance_modal';

  const handleCopyURL = () => {
    onCopyURL();
  };

  const handleQuestionnaireTemplateChange = option => {
    setSelectedQuestionnaireTemplateId(option?.value);
  };

  const handleSetAction = action => {
    setActiveForm(action);
    onAction(action);
  };

  const handleSendMail = mails =>
    onSendMail(mails).then(value => {
      setSent(value);
      handleSetAction('successfully-email');
      trackAmplitudeEvent('tolerance_modal.emailed', { email: mails[0].email });
    });

  const [investor] = investors;
  const { accounts } = investor;
  const canSelectSpecificAccounts =
    !isCheckInTemplate && mode === 'investor' && accounts?.length > 1;
  const hasScores = investors.some(investor => !_.isEmpty(getInvestorTargetScore(investor).data));

  const questionnaireTemplateOptions = questionnaireTemplates.map(template => ({
    isDefault: template.is_default,
    isGlobal: template.is_global,
    label: template.name,
    value: template.id
  }));
  const groupedQuestionnaireTemplateOptions = [
    {
      label: 'Company Templates',
      options: questionnaireTemplateOptions.filter(template => !template.isGlobal)
    },
    {
      label: 'Stratifi Templates',
      options: questionnaireTemplateOptions.filter(template => template.isGlobal)
    }
  ];

  useEffect(() => {
    const params = {};
    if (isCheckInTemplate) params.is_check_in = true;
    questionProvider.listQuestionnaireTemplates(params);
    const replaceId = window.location.pathname.replace(/[0-9]/g, 'id');
    trackAmplitudeEvent(`${amplitudeAction}.viewed`, { path: replaceId });
  }, []);

  useEffect(() => {
    if (!selectedQuestionnaireTemplateId && !_.isEmpty(questionnaireTemplateOptions)) {
      const questionnaireTemplate =
        questionnaireTemplateOptions.find(
          option => option.value === defaultQuestionnaireTemplateId
        ) || questionnaireTemplateOptions.find(option => option.isDefault);
      if (questionnaireTemplate) setSelectedQuestionnaireTemplateId(questionnaireTemplate.value);
    }
  }, [JSON.stringify(questionnaireTemplates)]);

  return (
    <div id="target-score-action">
      {!activeForm && (
        <div
          className={cn('target-score-questionnaire-options', {
            'with-select-accounts-option': canSelectSpecificAccounts
          })}
        >
          {isScoredQuestionnaireTemplate && !isCheckInTemplate && (
            <h1>{hasScores ? 'Update risk tolerance for' : 'Find out risk tolerance for'}</h1>
          )}
          {!isScoredQuestionnaireTemplate && !isCheckInTemplate && <h1>Send form to</h1>}
          {isCheckInTemplate && <h1>Send check-in to</h1>}

          <p className="account-name">{getTargetName(investors, mode)}</p>

          {mode === 'investors' && isScoredQuestionnaireTemplate && (
            <p className="text-danger account-description">
              This will overwrite existing tolerances
            </p>
          )}
          {mode === 'accounts' && isScoredQuestionnaireTemplate && (
            <div className="account-description">Same tolerance for {accounts.length} accounts</div>
          )}
          {mode === 'account' && isScoredQuestionnaireTemplate && (
            <p className="account-description">
              {hasScores
                ? 'This will overwrite current risk tolerance'
                : 'To create a risk tolerance, have your client answer the questionnaire'}
            </p>
          )}

          {questionnaireTemplateOptions.length > 1 && (
            <Select
              isClearable
              onChange={handleQuestionnaireTemplateChange}
              options={groupedQuestionnaireTemplateOptions}
              placeholder="Select Template"
              styles={{
                container: styles => ({
                  ...styles,
                  maxWidth: 325,
                  margin: '0 auto',
                  textAlign: 'left'
                })
              }}
              value={questionnaireTemplateOptions.find(
                option => option.value === selectedQuestionnaireTemplateId
              )}
            />
          )}

          <div className="primary-options">
            <button
              type="button"
              onClick={() => {
                onAnwerQuestionnaire(selectedQuestionnaireTemplateId, isCheckInTemplate);
                trackAmplitudeEvent(`${amplitudeAction}.selected`, { action: 'Answer now' });
              }}
              className="btn btn-primary"
              disabled={!selectedQuestionnaireTemplateId}
            >
              Answer now
            </button>

            {mode !== 'investors' && authProvider.hasCopyRiskTolerancePermissions(user) && (
              <CopyToClipboard
                text={
                  isCheckInTemplate
                    ? getExternalCheckInURL(investor, selectedQuestionnaireTemplateId)
                    : getExternalQuestionnaireURL(investor, mode, selectedQuestionnaireTemplateId)
                }
                onCopy={handleCopyURL}
                disabled={!selectedQuestionnaireTemplateId}
              >
                <button
                  type="button"
                  onClick={() => {
                    handleSetAction('successfully-copy');
                    trackAmplitudeEvent(`${amplitudeAction}.selected`, { action: 'Copy link' });
                  }}
                  className="btn btn-primary"
                >
                  Copy the link
                </button>
              </CopyToClipboard>
            )}
          </div>

          <div className="secondary-options">
            {!disableAdvisorEmails && (
              <button
                type="button"
                className="btn btn-outline-primary"
                disabled={!selectedQuestionnaireTemplateId}
                onClick={() => {
                  handleSetAction('email');
                }}
              >
                Send an email
              </button>
            )}

            <button
              type="button"
              className="btn btn-outline-primary btn-mt-fixed"
              disabled={!selectedQuestionnaireTemplateId}
              onClick={() => {
                trackAmplitudeEvent(`${amplitudeAction}.selected`, { action: 'Download PDF' });
                onPrint(selectedQuestionnaireTemplateId);
                onClose();
              }}
            >
              Download the PDF
            </button>
          </div>

          {authProvider.hasUpdateTargetScorePermissions(user) &&
            isScoredQuestionnaireTemplate &&
            !isCheckInTemplate && (
              <>
                <OrSeparator />
                <div className="secondary-options">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={() => {
                      handleSetAction('manual');
                      trackAmplitudeEvent(`tolerance_modal.selected`, { action: 'Manual score' });
                    }}
                  >
                    Enter risk tolerance score
                  </button>
                </div>
              </>
            )}

          {canSelectSpecificAccounts && (
            <div className="select-accounts-option">
              {isScoredQuestionnaireTemplate && (
                <button
                  type="button"
                  className="btn btn-link"
                  disabled={!selectedQuestionnaireTemplateId}
                  onClick={() => {
                    trackAmplitudeEvent('tolerance_modal.selected', {
                      action: 'Specific Accounts'
                    });
                    onSelectSpecificAccounts();
                  }}
                >
                  I want to select specific accounts
                </button>
              )}
            </div>
          )}
        </div>
      )}

      {activeForm === 'manual' && (
        <ManualToleranceForm
          onCancel={() => handleSetAction()}
          onSubmit={onSaveManualTarget}
          prevScore={getRiskToleranceScore(investor, accounts, mode)}
          targetName={getTargetName(investors, mode, true)}
        />
      )}

      {activeForm === 'email' && !isCheckInTemplate && (
        <EmailToleranceForm
          investors={investors}
          mode={mode}
          onCancel={() => handleSetAction()}
          onClose={onClose}
          onSend={handleSendMail}
          questionnaireTemplateId={selectedQuestionnaireTemplateId}
          sent={sent}
          user={user}
        />
      )}

      {activeForm === 'email' && isCheckInTemplate && (
        <EmailCheckInForm
          investor={investor}
          onCancel={() => handleSetAction()}
          onSuccess={() => handleSetAction('successfully-email')}
          questionnaireTemplateId={selectedQuestionnaireTemplateId}
        />
      )}

      {activeForm === 'successfully-copy' && (
        <SuccessfullyCopiedAction name={getUserName(investors)} onClose={onClose} />
      )}

      {activeForm === 'successfully-email' && (
        <SuccessfullyEmailedAction
          isCheckInTemplate={isCheckInTemplate}
          name={getUserName(investors)}
          onClose={onClose}
        />
      )}
    </div>
  );
};

const UserType = PropTypes.shape({
  email: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  full_name: PropTypes.string.isRequired
});

const AccountType = PropTypes.shape({
  name: PropTypes.string.isRequired
});

const InvestorType = PropTypes.shape({
  accounts: PropTypes.arrayOf(AccountType).isRequired,
  email: PropTypes.string.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  full_name: PropTypes.string.isRequired
});

TargetScoreAction.propTypes = {
  investors: PropTypes.arrayOf(InvestorType),
  isCheckInTemplate: PropTypes.bool.isRequired,
  isScoredQuestionnaireTemplate: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired,
  onAction: PropTypes.func.isRequired,
  onAnwerQuestionnaire: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCopyURL: PropTypes.func.isRequired,
  onPrint: PropTypes.func.isRequired,
  onSaveManualTarget: PropTypes.func.isRequired,
  onSelectSpecificAccounts: PropTypes.func.isRequired,
  onSendMail: PropTypes.func.isRequired,
  questionnaireTemplates: PropTypes.array.isRequired,
  user: UserType.isRequired
};

TargetScoreAction.defaultProps = {
  investors: []
};

export default connect((state, props) => ({
  questionnaireTemplates:
    props.isScoredQuestionnaireTemplate && !props.isCheckInTemplate
      ? scoredQuestionnaireTemplatesSelector(state)
      : questionnaireTemplatesSelector(state)
}))(TargetScoreAction);
