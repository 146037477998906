/* eslint-disable no-underscore-dangle */
import config from '../config';
import DataProvider from './data';
import SurveillanceExceptionsElasticProvider from './surveillance-exceptions.es';

class SurveillanceExceptionsProvider extends DataProvider {
  get es() {
    if (!this._es)
      this._es = new SurveillanceExceptionsElasticProvider({ dispatch: this._dispatch });
    return this._es;
  }

  update(id, data) {
    return this.provider.patch(`${config.apiBase}surveillance_exceptions/${id}/`, data);
  }
}

export default SurveillanceExceptionsProvider;
