import { setBreakdownCustomSecuritiesState } from 'components/form/breakdown-custom-securities-toggle-title/utils';
import {
  CLEAR_RISK_ANALYSIS,
  MODEL_ANALYZE_RISK,
  MODEL_CLEAR,
  MODEL_COMMON_BENCHMARKS,
  MODEL_CREATE,
  MODEL_DELETE,
  MODEL_EDIT,
  MODEL_GET,
  MODEL_GET_BY_ID,
  MODEL_GET_STRATEGY_STATS,
  MODEL_LIST,
  MODEL_LIST_ALL,
  MODEL_LIST_ES,
  MODEL_MARKETPLACE_GET,
  MODEL_MARKETPLACE_LIST,
  MODEL_MARKETPLACE_PROVIDERS,
  MODEL_MARKETPLACE_REQUEST_SUBSCRIPTION,
  MODEL_PRISM_SCORE_COMPLETED,
  MODEL_PRISM_SCORE_FAILED,
  MODEL_PRISM_SCORE_IN_PROGRESS,
  MODEL_SAVE_CHART_IMAGE,
  MODEL_SET_BREAKDOWN_CUSTOM_SECURITIES,
  MODEL_UPDATE,
  MODEL_UPDATE_SELECTED_IDS,
  MODEL_VIEW
} from 'constants/actions';
import { getUniqueObjectsArray } from 'utils/utils';

const initialState = {
  all: [],
  breakdownCustomSecurities: {},
  charts: {},
  currentMarketplace: {},
  esList: [],
  list: [],
  listMeta: {},
  marketplaceList: [],
  marketplaceListMeta: {},
  marketplaceProvidersList: [],
  prismScoresInProgress: [],
  riskAnalysis: null,
  selectedModelIds: [],
  selectedModels: [],
  view: {}
};

// eslint-disable-next-line default-param-last
export default function models(state = initialState, action) {
  switch (action.type) {
    case MODEL_GET:
    case MODEL_VIEW:
      return {
        ...state,
        breakdownCustomSecurities: setBreakdownCustomSecuritiesState(
          action.data.id,
          state.breakdownCustomSecurities
        ),
        view: action.data
      };

    case MODEL_GET_BY_ID:
      return { ...state, byId: { ...state.byId, [action.id]: action.data } };

    case MODEL_EDIT:
      return { ...state, edit: action.data };

    case MODEL_LIST:
      return { ...state, list: action.data.data, listMeta: action.data.meta };

    case MODEL_LIST_ES:
      return { ...state, esList: action.data };

    case MODEL_MARKETPLACE_LIST:
      return { ...state, marketplaceList: action.data.data, marketplaceListMeta: action.data.meta };

    case MODEL_MARKETPLACE_GET:
      return { ...state, currentMarketplace: action.data };

    case MODEL_MARKETPLACE_PROVIDERS:
      return {
        ...state,
        marketplaceProvidersList: action.data.data
      };

    case MODEL_LIST_ALL:
      return { ...state, all: action.data };

    case MODEL_CREATE:
      return { ...state, edit: undefined };

    case MODEL_UPDATE:
      return { ...state, view: state.view ? action.data : null };

    case MODEL_DELETE:
      // not implemented
      return state;

    case MODEL_CLEAR:
      return { ...state, view: null };

    case MODEL_ANALYZE_RISK:
      return { ...state, riskAnalysis: action.data };

    case MODEL_SAVE_CHART_IMAGE:
      return { ...state, charts: { ...state.charts, ...action.data } };

    case MODEL_PRISM_SCORE_IN_PROGRESS:
      return { ...state, prismScoresInProgress: [...state.prismScoresInProgress, action.id] };

    case MODEL_PRISM_SCORE_COMPLETED:
    case MODEL_PRISM_SCORE_FAILED:
      return {
        ...state,
        prismScoresInProgress: state.prismScoresInProgress.filter(id => id !== action.id)
      };

    case CLEAR_RISK_ANALYSIS:
      return { ...state, riskAnalysis: null };

    case MODEL_COMMON_BENCHMARKS:
      return { ...state, benchmark: action.data.data };

    case MODEL_UPDATE_SELECTED_IDS: {
      const { selectedModelIds } = action.data;
      const models = getUniqueObjectsArray([...state.selectedModels, ...state.list]);
      return {
        ...state,
        selectedModelIds,
        selectedModels: models.filter(model => selectedModelIds.includes(String(model.id)))
      };
    }

    case MODEL_GET_STRATEGY_STATS:
      return { ...state, strategyStats: { ...action.data } };

    case MODEL_MARKETPLACE_REQUEST_SUBSCRIPTION:
      return {
        ...state,
        currentMarketplace: { ...state.currentMarketplace, subscription_status: 'pending' },
        marketplaceList: [
          ...state.marketplaceList.map(model =>
            model.id === action.data.id ? { ...model, subscription_status: 'pending' } : model
          )
        ]
      };

    case MODEL_SET_BREAKDOWN_CUSTOM_SECURITIES:
      return {
        ...state,
        breakdownCustomSecurities: { ...state.breakdownCustomSecurities, [action.id]: action.data }
      };

    default:
      return state;
  }
}
