import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';
import { NumericFormat } from 'react-number-format';
import TrashCanIcon from 'components/svg-icons/trash-can-icon';

const MINIMUN_REMAINDER = 1;

const EmailReminders = ({ initialOffsets = [], onSave }) => {
  const [reminders, setReminders] = useState(initialOffsets);

  // Helper to get ordinal suffix
  const getOrdinal = n => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  useEffect(() => {
    setReminders(initialOffsets);
  }, [initialOffsets]);

  // Helper to format dates
  const formatDate = date =>
    date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });

  // Update reminders and trigger onSave
  const updateReminders = newReminders => {
    setReminders(newReminders);
    onSave(newReminders);
  };

  // Handle add new reminder
  const addReminder = () => {
    const lastValue = reminders[reminders.length - 1] || 0;
    updateReminders([...reminders, lastValue + 5]);
  };

  // Handle update reminder
  const updateReminder = (index, value) => {
    const updatedReminders = [...reminders];
    updatedReminders[index] = Math.max(1, parseInt(value, 10) || 1);
    updateReminders(updatedReminders);
  };

  // Handle remove reminder
  const removeReminder = index => {
    updateReminders(reminders.filter((_, i) => i !== index));
  };

  const generateSimulation = () => {
    const today = new Date();
    return [
      { date: formatDate(today), subject: 'Risk Assessment Questionnaire' },
      ...reminders.map((days, i) => {
        const reminderDate = new Date();
        reminderDate.setDate(today.getDate() + days);
        return {
          date: formatDate(reminderDate),
          subject: `Risk Assessment Questionnaire (${getOrdinal(i + 1)} reminder)`
        };
      })
    ];
  };

  return (
    <div className="email-remainder">
      <div className="email-remainder__container">
        <h4>Schedule</h4>

        {reminders.map((days, index) => (
          <div key={index} className="scheduel__input_container">
            <NumericFormat
              value={days}
              onChange={e => updateReminder(index, e.target.value)}
              className="days-inputs"
            />

            <span>days after the original email.</span>
            <button
              type="button"
              onClick={() => removeReminder(index)}
              className={cn('btn', 'btn-link', 'btn-remove', {
                'btn-hidden': reminders.length === MINIMUN_REMAINDER
              })}
            >
              <TrashCanIcon
                onClick={() => removeReminder(index)}
                className="remove-email__trash-icon"
              />{' '}
            </button>
          </div>
        ))}
        <button type="button" onClick={addReminder} className="btn btn-link">
          + Add reminder
        </button>
      </div>

      <div className="email-remainder__container">
        <h4>Email Simulation</h4>
        <ul className="email-remainder__list">
          {generateSimulation().map((email, i) => (
            <li key={i}>
              <strong>{email.date}</strong> {email.subject}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

EmailReminders.propTypes = {
  initialOffsets: PropTypes.arrayOf(PropTypes.number).isRequired,
  onSave: PropTypes.func.isRequired
};

export default EmailReminders;
