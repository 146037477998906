import FeatureLimitReachedModal from 'components/advisor/feature-limit';
import { LabelInput } from 'components/form';
import PhoneField from 'components/form/phone-field';
import SplashLoading from 'components/splash-loading';
import { ADDED_CLIENT, ADDED_PROSPECT } from 'constants/actstream';
import { DEFAULT_SCOPE, SCAN_IQ_SCOPE } from 'containers/advisor/ai-assistant/constants';
import { BackendValidation, propTypesCheck } from 'hocs/backend-validation';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { isAnalyst } from 'providers/auth';
import React, { PureComponent } from 'react';
import Autosuggest from 'react-autosuggest';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { prospectsLimitReachedSelector } from 'selectors/limits';
import { trackAmplitudeEvent } from 'utils/tracking';
import { validate } from './utils';

class CreateNewClientForm extends PureComponent {
  constructor(props) {
    super(props);

    this.onHouseholdSearch = _.debounce(this.onHouseholdSearch, 500);

    this.state = {
      ...this.state,
      isLoading: false,
      householdSearch: '',
      selectedAdvisor: null,
      showFirstNameError: false,
      showLastNameError: false,
      showEmailError: false,
      investorsLimitModal: { show: props.type === 'prospect' && props.prospectsLimitReached }
    };
  }

  componentDidUpdate(prevProps) {
    const { type, prospectsLimitReached } = this.props;
    if (type === 'prospect' && prospectsLimitReached !== prevProps.prospectsLimitReached)
      this.setState({ investorsLimitModal: { show: prospectsLimitReached } });
  }

  onHouseholdSearch = search => {
    const { selectedAdvisor } = this.state;
    const { householdProvider } = this.context;
    householdProvider.list({ search, by_advisor: selectedAdvisor });
  };

  onHouseholdChange = event => {
    const { value } = event.target;

    if (value === 0) {
      event.preventDefault();
      return;
    }

    this.onHouseholdSearch(value);
    this.setState({ householdSearch: value });
  };

  setLimitReached = () => {
    this.setState({ investorsLimitModal: { show: true } });
  };

  unsetLimitReached = () => {
    this.setState({ investorsLimitModal: { show: false } });
  };

  onSubmit = values => {
    this.setState({ isLoading: true });

    const { companyProvider, prospectProvider, investorProvider, actionProvider } = this.context;
    const { onCreate, routerActions, scope, type, user } = this.props;

    const provider = type === 'investor' ? investorProvider : prospectProvider;
    const eventName = type === 'investor' ? 'client.created' : 'prospect.created';

    return provider
      .create(values, true)
      .then(response => {
        if (response.error && !_.isEmpty(response.error))
          if (response.error.code === 402) this.setLimitReached();
          else if (!response.error?.errors && response.error?.message)
            return Promise.reject(response.error.message);
          else return Promise.reject();
        else {
          companyProvider.getLimits();
          investorProvider.countNew();
          const id = response?.data?.id ?? '';

          trackAmplitudeEvent(eventName, {
            id: response?.data?.user_id,
            email: response?.data?.email,
            name: response?.data?.full_name
          });

          onCreate(response.data);

          if (type === 'prospect') {
            actionProvider.slack({ verb: ADDED_PROSPECT }, user.advisor.id);
            if (scope === DEFAULT_SCOPE)
              routerActions.push({ pathname: `/advisor/prospects/${id}` });
          } else {
            actionProvider.slack({ verb: ADDED_CLIENT });
            if (scope === DEFAULT_SCOPE)
              routerActions.push({ pathname: `/advisor/investors/${id}` });
          }
        }
        return response;
      })
      .catch(error => {
        toast.error(error ?? 'Sorry, something went wrong.');
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  resetSelectedHousehold = () => {
    const {
      fields: { household }
    } = this.props;

    household.id.onChange('');
    household.name.onChange('');
    this.setState({ householdSearch: '' });
  };

  onAdvisorChange = ({ value }) => {
    const {
      fields: { advisor }
    } = this.props;

    advisor.onChange(value);
    this.setState({ selectedAdvisor: value });
    this.resetSelectedHousehold();
  };

  onHouseholdSelected = (_, { suggestion }) => {
    const {
      fields: { household }
    } = this.props;

    household.id.onChange(suggestion.id);
    household.name.onChange(suggestion.name);
    this.setState({ householdSearch: suggestion.name });
  };

  showError = fieldName => {
    const obj = {};
    obj[fieldName] = true;
    this.setState(obj);
  };

  render() {
    const { userProvider, authProvider } = this.context;
    const {
      advisors,
      fields,
      handleSubmit,
      households,
      invalid,
      onBack,
      scope,
      submitting,
      type,
      user
    } = this.props;
    const {
      householdSearch,
      investorsLimitModal,
      isLoading,
      selectedAdvisor,
      showEmailError,
      showFirstNameError,
      showLastNameError
    } = this.state;
    const whichNew = type === 'investor' ? 'Client' : 'Prospect';

    const advisorOptions = advisors
      .filter(a => !isAnalyst(a.role) && a.id !== user.advisor.id)
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(a => ({ label: a.name, value: a.id }));

    // add the current user to the list of advisors
    let defaultAdvisorOption = null;
    if (!authProvider.isAnalyst(user)) {
      const currentAdvisorOption = {
        label: `[Me] ${user.first_name} ${user.last_name}`,
        value: user.advisor.id
      };
      advisorOptions.unshift(currentAdvisorOption);
      defaultAdvisorOption = currentAdvisorOption;
    }

    // set the default advisor in case there's no a valid advisor selected
    if (!fields.advisor.value && defaultAdvisorOption) {
      this.onHouseholdSearch(undefined);
      this.onAdvisorChange(defaultAdvisorOption);
    }

    const advisorAllowed = Boolean(advisorOptions && advisorOptions.length);
    const advisorRequired = authProvider.isAnalyst(user);

    return (
      <div>
        {scope === DEFAULT_SCOPE && <h4 className="text-title mt-3">Create a New {whichNew}</h4>}
        <form autoComplete="off">
          <div className="container-fluid table-white">
            <div className="row">
              <LabelInput
                divClassName="col-sm-6"
                label="First Name *"
                key="firstName"
                showError={showFirstNameError}
                onBlurSkipError={() => {
                  this.showError('showFirstNameError');
                }}
                {...fields.first_name}
              />
              <LabelInput
                divClassName="col-sm-6"
                key="lastName"
                label="Last Name *"
                showError={showLastNameError}
                onBlurSkipError={() => {
                  this.showError('showLastNameError');
                }}
                {...fields.last_name}
              />
            </div>
            <div className="row">
              <LabelInput
                divClassName="col-sm-6"
                key="email"
                label="Email *"
                showError={showEmailError}
                onBlurSkipError={() => {
                  this.showError('showEmailError');
                }}
                {...fields.email}
              />
              <div className="col-sm-6">
                <span className="span-label">
                  Phone<span className="optional-label"> - Optional</span>
                </span>
                <PhoneField {...fields.phone} style={{ height: 45 }} />
              </div>
            </div>

            <div className="row">
              {advisorAllowed && (
                <div className="col-sm-6">
                  <span className="span-label household">Advisor {advisorRequired ? '*' : ''}</span>
                  <Select
                    defaultValue={defaultAdvisorOption}
                    error={advisorRequired && !selectedAdvisor}
                    isSearchable
                    onChange={this.onAdvisorChange}
                    options={advisorOptions}
                    placeholder="Select an Advisor"
                    required={advisorRequired}
                    styles={{
                      control: (styles, state) => ({
                        ...styles,
                        height: 45,
                        boxShadow: 'none',
                        borderColor: state.isFocused ? '#09acf8' : styles.borderColor,
                        '&:hover': {
                          borderColor: state.isFocused ? '#09acf8' : styles.borderColor
                        }
                      })
                    }}
                  />
                </div>
              )}
              {!userProvider.isEntry(user) && (
                <div className="col-sm-6">
                  <span className="span-label household">
                    Household
                    <span className="optional-label"> - Optional</span>
                  </span>
                  <Autosuggest
                    className="form-control"
                    suggestions={households}
                    getSuggestionValue={s => s.name}
                    renderSuggestion={s => <span>{s.name}</span>}
                    onSuggestionSelected={this.onHouseholdSelected}
                    onSuggestionsFetchRequested={({ value }) => {
                      this.setState({ householdSearch: value });
                    }}
                    onSuggestionsClearRequested={() => {}}
                    inputProps={{
                      type: 'search',
                      className: 'form-control',
                      disabled: advisorRequired && !selectedAdvisor,
                      onChange: this.onHouseholdChange,
                      placeholder: 'Search Household to Add',
                      value: householdSearch
                    }}
                    theme={{
                      container: 'react-autosuggest__container',
                      containerOpen: 'open react-autosuggest__container--open',
                      suggestionsContainer:
                        'dropdown-menu react-autosuggest__suggestions-container',
                      suggestion: 'dropdown-item-new react-autosuggest__suggestion'
                    }}
                  />
                  <span className="icon-search_icn" />
                  <span className="span-label household-info">
                    Select an existing household to join
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-between align-center" style={{ position: 'relative' }}>
            {scope === DEFAULT_SCOPE && (
              <Link to={`/advisor/${type}s`} className="pl-4 text-graphite-gray">
                <i
                  className="icon-arrow"
                  style={{
                    position: 'absolute',
                    top: '15px',
                    left: 0,
                    fontSize: '9px',
                    transform: 'rotate(90deg)'
                  }}
                />
                Cancel
              </Link>
            )}
            {scope === SCAN_IQ_SCOPE && (
              <button
                type="button"
                className="btn btn-link text-dark font-weight-normal px-0"
                onClick={onBack}
              >
                Back
              </button>
            )}
            <div>
              <button
                className="btn btn-primary font-weight-normal"
                disabled={invalid || submitting}
                onClick={handleSubmit(this.onSubmit)}
                style={{ minWidth: '130px' }}
                type="submit"
              >
                {scope === SCAN_IQ_SCOPE ? 'Save & Continue' : 'Save'}
              </button>
            </div>
          </div>
        </form>

        {isLoading && <SplashLoading text={`Creating ${type}`} duration={0.25} />}

        <FeatureLimitReachedModal
          content={{ action: 'adding', kind: `${whichNew.toLowerCase()}s` }}
          show={investorsLimitModal.show}
          onHide={this.unsetLimitReached}
        />
      </div>
    );
  }
}

CreateNewClientForm.contextTypes = {
  actionProvider: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired,
  companyProvider: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  householdProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  prospectProvider: PropTypes.object.isRequired,
  userProvider: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

CreateNewClientForm.defaultProps = {
  advisors: [],
  households: [],
  onBack: () => {},
  onCreate: () => {},
  scope: DEFAULT_SCOPE
};

CreateNewClientForm.propTypes = {
  advisors: PropTypes.array,
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  households: PropTypes.array,
  onBack: PropTypes.func,
  onCreate: PropTypes.func,
  routerActions: PropTypes.object.isRequired,
  scope: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  ...propTypesCheck
};

export default compose(
  connect(state => ({ prospectsLimitReached: prospectsLimitReachedSelector(state) }), null),
  reduxForm({
    form: 'editInvestor',
    fields: [
      'id',
      'first_name',
      'last_name',
      'email',
      'phone',
      'household.id',
      'household.name',
      'advisor'
    ],
    validate
  })
)(BackendValidation(CreateNewClientForm));
