/* eslint-disable no-underscore-dangle */
import {
  FETCHING,
  FETCHING_FAILURE,
  HOUSEHOLD_GET,
  HOUSEHOLD_GET_MEMBER_SUGGESTIONS,
  HOUSEHOLD_LIST,
  HOUSEHOLD_UPDATE,
  HOUSEHOLD_UPDATE_SELECTED_IDS
} from 'constants/actions';
import { preferredValuesPerPage } from 'constants/pagination';
import config from '../config';
import DataProvider from './data';
import HouseholdsElasticProvider from './households.es';

export default class HouseholdProvider extends DataProvider {
  get es() {
    if (!this._es) this._es = new HouseholdsElasticProvider({ dispatch: this._dispatch });
    return this._es;
  }

  list(params, query = {}) {
    return this.provider
      .get(`${config.apiBase}households/`, { ...params, ...query })
      .then(({ data, error }) => {
        if (!error) {
          this._params = params;
          this.dispatch(HOUSEHOLD_LIST, {
            data: {
              data: data.results || [],
              meta: { params, query, count: data.count, totalPages: data.total_pages }
            }
          });
        } else this.dispatch(HOUSEHOLD_LIST, { data: { data: [], meta: {} } });
        return data;
      });
  }

  get(id) {
    this.dispatch(FETCHING);
    const promise = this.provider.get(`${config.apiBase}households/${id}/`).then(data => {
      if (!data.error)
        this.dispatch(HOUSEHOLD_GET, {
          data: data.data || []
        });
      else this.dispatch(FETCHING_FAILURE);
      return data;
    });
    return promise;
  }

  searchSuggestions(params, query = {}) {
    const queryParams = {
      page_size: params.page_size || preferredValuesPerPage().value,
      ordering: params.ordering || 'full_name',
      ...params,
      ...query
    };

    this.dispatch(FETCHING);
    return Promise.all([
      this.provider.get(`${config.apiBase}investors/suggestions/`, queryParams),
      this.provider.get(`${config.apiBase}prospects/suggestions/`, queryParams)
    ]).then(([investors, prospects]) => {
      if (investors.error || prospects.error) this.dispatch(FETCHING_FAILURE);
      else {
        const suggestions = [...investors.data.results, ...prospects.data.results];
        this.dispatch(HOUSEHOLD_GET_MEMBER_SUGGESTIONS, { data: suggestions });
        return suggestions;
      }
      return [];
    });
  }

  create(data) {
    return this.provider.post(`${config.apiBase}households/`, data);
  }

  update(data) {
    const { id, name, action } = data;
    const promise = this.provider
      .patch(`${config.apiBase}households/${id}/`, { name, action })
      .then(data => {
        if (!data.error)
          this.dispatch(HOUSEHOLD_UPDATE, {
            data: data.data
          });
        return data;
      });

    return promise;
  }

  delete(id) {
    return this.provider.delete(`${config.apiBase}households/${id}/`);
  }

  downloadCSV() {
    return this.provider
      .requestNoJSON(`${config.apiBase}households/csv/`)
      .then(response => Promise.all([response.blob(), response]))
      .then(([data, response]) => (response.ok ? Promise.resolve(data) : Promise.reject(data)))
      .then(data => {
        const url = URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'households.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  uploadCSV(file) {
    return this.provider.postFormData(`${config.apiBase}households/csv/`, file);
  }

  updateSelectedId(selectedHouseholdIds) {
    this.dispatch(HOUSEHOLD_UPDATE_SELECTED_IDS, { data: { selectedHouseholdIds } });
  }

  getManagers(householdId) {
    return this.provider
      .get(`${config.apiBase}households/${householdId}/managers/`)
      .then(({ data }) => data);
  }
}
