import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const CheckInAlert = ({ latestCheckIn }) => {
  if (!latestCheckIn?.get_in_touch) return null;

  const message = _.isEmpty(latestCheckIn?.discussion_topics) ? (
    'The client requested to get in touch with the advisor but did not provide specific topics to discuss.'
  ) : (
    <div>
      <p className="mb-2">
        The client requested to get in touch with the advisor when filling the Check-in.
      </p>
      <p>The topics to discuss are:</p>
      <div className="text__topics">
        {latestCheckIn.discussion_topics.map((topic, idx) => (
          <span key={topic}>
            {idx + 1}. {topic}
          </span>
        ))}
      </div>
    </div>
  );

  return (
    <div className="check-in-alert">
      <div className="check-in-alert__text">
        <span className="fs-icon-exclamation-circle warning-icon" />
        {message}
      </div>
    </div>
  );
};

CheckInAlert.propTypes = {
  latestCheckIn: PropTypes.object
};

CheckInAlert.defaultProps = {
  latestCheckIn: null
};

export default CheckInAlert;
