import EmailTemplate from 'components/advisor/templates/email';
import MetaTemplate from 'components/advisor/templates/meta';
import TextTemplate from 'components/advisor/templates/text/';
import SpinnerLoader from 'components/performance-spinner';
import EmailsButtonsAndDisclosure from 'app/components/advisor/templates/emails-buttons-and-disclosure';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import DEFAULT_TEMPLATES from '../defaults';
import {
  CRS_METADATA,
  EMAIL_DISCLOSURE,
  IPS_REVIEW_EMAIL,
  QUESTIONNAIRE_EMAIL,
  REPORT_DISCLOSURE,
  EMAILS
} from './contants';

const CompanyMeta = ({ templates, target }) => {
  const { companyProvider, authProvider } = useContext(AdvisorContext);

  const handleSaveTemplate = (target, content, isCustom = false) =>
    companyProvider.saveTemplate(target, content).then(() => {
      toast.success(() => (
        <div>
          {isCustom ? 'A custom section was successfully created' : 'Template saved successfully'}
        </div>
      ));
      // update company templates
      authProvider.getUser();
    });

  useEffect(() => {
    companyProvider.getTemplates();
  }, []);

  if (_.isEmpty(templates)) return <SpinnerLoader spinnerLoading />;

  const content = templates[target] || DEFAULT_TEMPLATES[target];
  const templateProps = { target, content, handleSaveTemplate };

  if (target === IPS_REVIEW_EMAIL || target === QUESTIONNAIRE_EMAIL)
    return <EmailTemplate {...templateProps} />;

  if (target === EMAILS)
    return (
      <EmailsButtonsAndDisclosure
        templates={templates}
        target={target}
        handleSaveTemplate={handleSaveTemplate}
      />
    );

  if (target === CRS_METADATA)
    return <MetaTemplate allowPrint title="Form CRS" {...templateProps} />;

  if (target === EMAIL_DISCLOSURE || target === REPORT_DISCLOSURE)
    return <TextTemplate {...templateProps} />;

  return null;
};

CompanyMeta.contextTypes = {
  companyProvider: PropTypes.object.isRequired,
  authProvider: PropTypes.object.isRequired
};

CompanyMeta.propTypes = {
  target: PropTypes.string.isRequired,
  templates: PropTypes.object
};

CompanyMeta.defaultProps = {
  templates: {}
};

export default connect(state => ({
  templates: state.company.templates
}))(CompanyMeta);
