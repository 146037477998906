/**
 * Converts a RGB triplet contained in the set [0, 255] to Hexadecimal string.
 * @param {Number} t Triplet
 * @returns Hexadecimal string representation
 */
const tripletToHex = t => {
  const hex = t.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

/**
 * Converts a RGB color value to Hexadecimal string.
 * @param {Object} rgb
 * @returns Hexadecimal string representation
 */
const rgbToHex = ({ r, g, b }) => {
  const t1 = tripletToHex(Number.parseInt(r, 10));
  const t2 = tripletToHex(Number.parseInt(g, 10));
  const t3 = tripletToHex(Number.parseInt(b, 10));

  return `#${t1}${t2}${t3}`;
};

/**
 * Converts an Hexadecimal string value to RGB.
 * @param {String} hex
 * @returns RGB color object representation
 */
const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

/**
 * Converts a RGB color value to HSV. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes r, g, and b are contained in the set [0, 255] and
 * returns h, s, and v in the set [0, 360).
 * @param {Object} rgb
 * @returns HSV color object representation
 */
const rgbToHsv = ({ r, g, b }) => {
  let h;
  let s;
  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);

  const v = max;
  const delta = max - min;
  if (max !== 0) s = delta / max;
  else {
    s = 0;
    h = -1;
    return [h, s, undefined];
  }
  if (r === max) h = (g - b) / delta;
  // between yellow & magenta
  else if (g === max) h = 2 + (b - r) / delta;
  // between cyan & yellow
  else h = 4 + (r - g) / delta; // between magenta & cyan
  h *= 60; // degrees
  if (h < 0) h += 360;
  if (Number.isNaN(h)) h = 0;
  return { h, s, v };
};

/**
 * Converts a HSV color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSV_color_space.
 * Assumes h, s, and v are contained in the set [0, 360) and
 * returns r, g, and b in the set [0, 255].
 * @param {Object} hsv
 * @returns RGB color object representation
 */
export const hsvToRgb = ({ h, s, v }) => {
  let r;
  let g;
  let b;
  if (s === 0) {
    r = g = b = v;
    return { r, g, b };
  }
  h /= 60; // sector 0 to 5
  const i = Math.floor(h);
  const f = h - i; // factorial part of h
  const p = v * (1 - s);
  const q = v * (1 - s * f);
  const t = v * (1 - s * (1 - f));
  switch (i) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    default:
      r = v;
      g = p;
      b = q;
      break;
  }
  return { r, g, b };
};

/**
 * Saturates a Hexadecimal color by converting it to its HSV representation to
 * reduce its lightness.
 * @param {String} color Hexadecimal color
 * @param {Number} seed Brightness factor
 * @returns Hexadecimal string representation
 */
export const saturate = (color, seed) => {
  const { h, s, v } = rgbToHsv(hexToRgb(color));
  const seedValue = Number(seed);
  const multiplier = seedValue ? seedValue / (seedValue > 10 ? 100 : 10) : s;
  const s2 = Math.min(Math.abs(s * multiplier), 1);
  return rgbToHex(hsvToRgb({ h, s: s2, v }));
};

export const RISK_LEVELS_COLORS = [
  '#66CB33',
  '#89C332',
  '#AABC31',
  '#C5B430',
  '#E4A82F',
  '#F29B2E',
  '#EE772D',
  '#E6672F',
  '#D54D36',
  '#C7393D'
];
