import MatchingModelsChart from 'components/charts/matching-models-chart/pdf';
import PropTypes from 'prop-types';
import React from 'react';
import BaseSection from 'reports/base/sections/base';
import { MetadataPropTypes } from '../../types';

const IPSMatchingModels = ({ breakSection, ips: { proposal }, metadata }) => {
  const { body, title } = metadata;

  return (
    <BaseSection body={body} breakSection={breakSection} title={title} wrap>
      <MatchingModelsChart proposal={proposal} />
    </BaseSection>
  );
};

IPSMatchingModels.propTypes = {
  breakSection: PropTypes.bool,
  ips: PropTypes.shape({ proposal: PropTypes.object }).isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired
};

IPSMatchingModels.defaultProps = {
  breakSection: false
};

export default IPSMatchingModels;
