import PropTypes from 'prop-types';

export const AddressPropTypes = {
  address1: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  id: PropTypes.number,
  postcode: PropTypes.string,
  state: PropTypes.string
};

export const CompanyPropTypes = {
  address: PropTypes.shape(AddressPropTypes),
  brand_primary_color: PropTypes.string,
  brand_secondary_color: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string,
  website: PropTypes.string
};

export const UserPropTypes = {
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired
};

export const AdvisorPropTypes = {
  address: PropTypes.shape(AddressPropTypes),
  company: PropTypes.shape(CompanyPropTypes).isRequired,
  logo: PropTypes.string,
  phone: PropTypes.string,
  user: PropTypes.shape(UserPropTypes).isRequired,
  website: PropTypes.string
};

export const AccountExceptionPropTypes = {
  investor_is_prospect: PropTypes.bool.isRequired,
  investor: PropTypes.number.isRequired,
  prism: PropTypes.number.isRequired,
  target_ct: PropTypes.string.isRequired,
  target_name: PropTypes.string.isRequired,
  target: PropTypes.number.isRequired,
  tolerance_is_manual: PropTypes.bool.isRequired,
  tolerance: PropTypes.number.isRequired
};
