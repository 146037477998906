import InvestorList from 'components/advisor/investors';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AccountProvider from 'providers/account';
import InvestorProvider from 'providers/investor';
import MarketProvider from 'providers/market';
import ModelProvider from 'providers/model';
import ProspectProvider from 'providers/prospects';
import QuestionProvider from 'providers/question';
import ServerErrosProvider from 'providers/server-errors-provider';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import './styles.scss';

class AdvisorInvestors extends Component {
  getChildContext() {
    const {
      accountProvider,
      errorsProvider,
      investorAccounts,
      investorProvider,
      investors,
      investorsMeta,
      loading,
      marketProvider,
      model,
      modelProvider,
      prospectAccounts,
      prospectProvider,
      prospects,
      prospectsMeta,
      questionProvider,
      routerActions,
      selectedInvestorIds,
      selectedInvestors,
      selectedProspectIds,
      selectedProspects,
      totalExcludedAccounts,
      totalNewInvestors,
      user
    } = this.props;
    return {
      accountProvider,
      errorsProvider,
      investorAccounts,
      investorProvider,
      investors,
      investorsMeta,
      loading,
      marketProvider,
      model,
      modelProvider,
      prospectAccounts,
      prospectProvider,
      prospects,
      prospectsMeta,
      questionProvider,
      routerActions,
      selectedInvestorIds,
      selectedInvestors,
      selectedProspectIds,
      selectedProspects,
      totalExcludedAccounts,
      totalNewInvestors,
      user
    };
  }

  render() {
    const {
      location,
      route: { type }
    } = this.props;

    return (
      <div id="investors">
        <div className="investors-wrapper">
          <InvestorList location={location} type={type} />
        </div>
      </div>
    );
  }
}

AdvisorInvestors.childContextTypes = {
  accountProvider: PropTypes.object.isRequired,
  errorsProvider: PropTypes.object.isRequired,
  investorAccounts: PropTypes.array,
  investorProvider: PropTypes.object.isRequired,
  investors: PropTypes.array,
  investorsMeta: PropTypes.object.isRequired,
  loading: PropTypes.object.isRequired,
  marketProvider: PropTypes.object.isRequired,
  model: PropTypes.object,
  modelProvider: PropTypes.object.isRequired,
  prospectAccounts: PropTypes.array,
  prospectProvider: PropTypes.object.isRequired,
  prospects: PropTypes.array,
  prospectsMeta: PropTypes.object.isRequired,
  questionProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired,
  selectedInvestorIds: PropTypes.array.isRequired,
  selectedInvestors: PropTypes.array.isRequired,
  selectedProspectIds: PropTypes.array.isRequired,
  selectedProspects: PropTypes.array.isRequired,
  totalExcludedAccounts: PropTypes.number.isRequired,
  totalNewInvestors: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired
};

AdvisorInvestors.propTypes = {
  accountProvider: PropTypes.object.isRequired,
  errorsProvider: PropTypes.object.isRequired,
  investorAccounts: PropTypes.array.isRequired,
  investorProvider: PropTypes.object.isRequired,
  investors: PropTypes.array,
  investorsMeta: PropTypes.object.isRequired,
  loading: PropTypes.shape({ count: PropTypes.number }),
  location: PropTypes.object.isRequired,
  marketProvider: PropTypes.object.isRequired,
  marketStore: PropTypes.object.isRequired,
  model: PropTypes.object,
  modelProvider: PropTypes.object.isRequired,
  prospectAccounts: PropTypes.array.isRequired,
  prospectProvider: PropTypes.object.isRequired,
  prospects: PropTypes.array,
  prospectsMeta: PropTypes.object.isRequired,
  questionProvider: PropTypes.object.isRequired,
  route: PropTypes.shape({ type: PropTypes.string }),
  routerActions: PropTypes.object.isRequired,
  selectedInvestorIds: PropTypes.array.isRequired,
  selectedInvestors: PropTypes.array.isRequired,
  selectedProspectIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedProspects: PropTypes.array.isRequired,
  totalExcludedAccounts: PropTypes.number.isRequired,
  totalNewInvestors: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired
};

AdvisorInvestors.defaultProps = {
  investors: null,
  loading: null,
  model: null,
  prospects: null,
  route: null
};

const prospectListSelector = state => state.prospects.list;
const investorListSelector = state => state.investors.list;
const getGoalValue = (accumulated, account) => {
  if (!account.excluded) accumulated += account.value;
  return accumulated;
};

const getGoalDrift = goal =>
  !_.isEmpty(goal.aggregated_prism_scores) && !_.isEmpty(goal.aggregated_target_scores)
    ? Math.abs(
        goal.aggregated_prism_scores.overall - goal.aggregated_target_scores.overall
      ).toFixed(1)
    : '';

const investorWithGoalsListSelector = selector =>
  createSelector([selector], investorList =>
    (investorList || []).map(investor => {
      const { goals, accounts } = investor;
      const accountsGroupedByGoal = _.groupBy(accounts, a => a.goal);
      const mappedGoals = goals.map(goal => ({
        ...goal,
        accounts: accountsGroupedByGoal[goal.id] || [],
        value: (accountsGroupedByGoal[goal.id] || []).reduce(getGoalValue, 0),
        drift: getGoalDrift(goal)
      }));
      return { ...investor, goals: mappedGoals };
    })
  );

export default connect(
  state => ({
    investorAccounts: state.investors.viewAccounts,
    investors: investorWithGoalsListSelector(investorListSelector)(state) || [],
    investorsMeta: state.investors.listMeta,
    loading: state.loading,
    marketStore: state.market,
    model: state.models.view,
    prospectAccounts: state.prospects.viewAccounts,
    prospects: investorWithGoalsListSelector(prospectListSelector)(state) || [],
    prospectsMeta: state.prospects.listMeta,
    selectedInvestorIds: state.investors.selectedInvestorIds,
    selectedInvestors: state.investors.selectedInvestors,
    selectedProspectIds: state.prospects.selectedProspectIds,
    selectedProspects: state.prospects.selectedProspects,
    totalExcludedAccounts: state.accounts.totalExcludedAccounts,
    totalNewInvestors: state.investors.totalNewInvestors,
    user: state.auth.user
  }),
  dispatch => ({
    accountProvider: new AccountProvider({ dispatch }),
    errorsProvider: new ServerErrosProvider({ dispatch }),
    investorProvider: new InvestorProvider({ dispatch }),
    marketProvider: new MarketProvider({ dispatch }),
    modelProvider: new ModelProvider({ dispatch }),
    prospectProvider: new ProspectProvider({ dispatch }),
    questionProvider: new QuestionProvider({ dispatch }),
    routerActions: bindActionCreators(routerActions, dispatch)
  })
)(AdvisorInvestors);
