/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
/* global FULL_DATE_FORMAT */
import tooltipCloseButton from 'assets/img/tooltip-close-button.svg';
import cn from 'classnames';
import CheckInAlertIcon from 'components/advisor/investors/check-in-alert/icon';
import CheckInIcon from 'components/advisor/investors/check-ins-history/icon';
import InvestorProfileBasic from 'components/advisor/investors/investor-profile-basic';
import RiskOverall from 'components/advisor/risk-analysis/risk-overall';
import ScrollToTop from 'components/advisor/scroll-cards-index/scroll-to-top';
import AggregatedToleranceScoreBubble from 'components/advisor/utils/score-bubble/aggregated-tolerance';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import SpinnerLoader from 'components/performance-spinner';
import CardOverview from 'components/utils/card-overview';
import Link from 'components/utils/link';
import tabs from 'constants/tabs';
import { RISK_LEVELS } from 'containers/risk-tolerance-questionnaire/result/score-context/utils/constants';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactTooltip from 'react-tooltip';
import {
  getInvestorRtqSentTimestamp,
  getInvestorTargetScore,
  getRiskToleranceLatestUpdate,
  numToRiskScaleString
} from 'utils/utils';
import InvestorAccountsOverviewTable from './investor-accounts-overview-table';
import InvestorDetailStats from './investor-stats';
import './styles.scss';

class InvestorOverview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      basicProfileModal: false,
      householdSearch: '',
      householdSelected: {},
      joinHouseholdLoading: false,
      latestNote: null,
      latestCheckIn: null,
      loadingLatestCheckIn: true,
      tooltipManuallyClosed: false
    };

    this.onHouseholdChange = _.debounce(this.onHouseholdChange, 750);
    this.getInvestorAccounts = this.getInvestorAccounts.bind(this);

    this.riskToleranceTooltipRef = React.createRef();
  }

  componentDidMount() {
    this.getLatestNote();
    this.getLatestCheckIn();
  }

  showModal = () => this.setState({ basicProfileModal: true });

  closeModal = () => this.setState({ basicProfileModal: false });

  hideAssessRiskToleranceTooltip = () => {
    this.setState({ tooltipManuallyClosed: true });
    ReactTooltip.hide(this.riskToleranceTooltipRef.current);
  };

  getLatestNote = () => {
    const { clientProvider } = this.context;
    const { investor } = this.props;

    if (!_.isEmpty(investor))
      clientProvider.lastNote(investor).then(({ data }) => {
        this.setState({ latestNote: data });
      });
  };

  getLatestCheckIn = () => {
    const { clientProvider } = this.context;
    const { investor } = this.props;

    if (!_.isEmpty(investor))
      clientProvider
        .getLastCheckIn(investor.id)
        .then(({ data }) => {
          this.setState({ latestCheckIn: data });
        })
        .finally(() => {
          this.setState({ loadingLatestCheckIn: false });
        });
  };

  getInvestor = () => {
    const { clientProvider } = this.context;
    const {
      params: { id }
    } = this.props;
    return clientProvider.get(id);
  };

  getInvestorAccounts = () => {
    const { clientProvider } = this.context;
    const {
      params: { id }
    } = this.props;
    return clientProvider.getAccounts(id);
  };

  getData = () => [this.getInvestor(), this.getInvestorAccounts()];

  handleJoinHousehold = () => {
    const { clientProvider } = this.context;
    const { investor } = this.props;
    const { householdSelected } = this.state;

    if (_.isEmpty(householdSelected)) toast.error(() => <div>Choose a household to add</div>);
    else {
      this.setState({ joinHouseholdLoading: true });
      clientProvider
        .linkHousehold(investor.id, { id: householdSelected.id })
        .then(() => this.getInvestor());
    }
  };

  onHouseholdSelected = suggestion => {
    this.setState({ householdSearch: suggestion.name, householdSelected: suggestion });
  };

  onHouseholdChange = search => {
    const { householdProvider } = this.context;
    householdProvider.list({ search });
  };

  render() {
    const { showTargetScoreWizard, clientProvider, user } = this.context;

    const { accounts, households, investor, selectedAccountIds, totalAccounts, totalValue } =
      this.props;

    const {
      basicProfileModal,
      householdSearch,
      joinHouseholdLoading,
      latestCheckIn,
      latestNote,
      loadingLatestCheckIn,
      tooltipManuallyClosed
    } = this.state;

    const prismScore = investor && investor.aggregated_prism_scores;
    const targetScore = getInvestorTargetScore(investor).data;
    const targetScoreLatestUpdate = getRiskToleranceLatestUpdate(investor, accounts);

    const riskLevels = user?.advisor?.company?.risk_levels || RISK_LEVELS;

    const clientType = investor.is_prospect ? 'prospects' : 'investors';
    const somePrismOverall = _.some(accounts, 'prism_score_summary');

    const investorRtqSentTimestamp = getInvestorRtqSentTimestamp(investor);

    // Check on every render if the tooltip should be hidden
    if (targetScore && !_.isEmpty(targetScore) && !investorRtqSentTimestamp)
      ReactTooltip.hide(this.riskToleranceTooltipRef.current);

    return (
      <div>
        <div className="investor-overview">
          <InvestorDetailStats
            investor={investor}
            countAccounts={totalAccounts}
            totalValue={totalValue}
            showModal={this.showModal}
            households={households}
            onHouseholdSelected={this.onHouseholdSelected}
            householdSearch={householdSearch}
            onHouseholdChange={this.onHouseholdChange}
            handleJoinHousehold={this.handleJoinHousehold}
            joinHouseholdLoading={joinHouseholdLoading}
          />

          <div className="card-deck">
            <CardOverview title="Risk Tolerance">
              <div>
                <div className="info-div">
                  {targetScore && targetScore.overall ? (
                    <div className="tolerance-div">
                      {investor && (
                        <AggregatedToleranceScoreBubble
                          element={{ ...investor, accounts }}
                          overview
                        />
                      )}
                      <span className="text">
                        {numToRiskScaleString(targetScore.overall, riskLevels)}
                      </span>
                    </div>
                  ) : (
                    <>
                      <span className="info-text tolerance">No Risk Tolerance Generated yet</span>
                      {investorRtqSentTimestamp && (
                        <span className="risk-tolerance-sent-timestamp">
                          (You sent a questionnaire on{' '}
                          {moment(investorRtqSentTimestamp).format('LL')})
                        </span>
                      )}
                    </>
                  )}
                </div>

                {targetScoreLatestUpdate && (
                  <div className="target-score-latest-update">{`(Last updated: ${moment
                    .utc(targetScoreLatestUpdate)
                    .fromNow()})`}</div>
                )}

                <div className="button-div">
                  {targetScore && _.isEmpty(targetScore) && investorRtqSentTimestamp ? (
                    <button
                      className="btn btn-outline-primary btn-small risk-card"
                      type="button"
                      onClick={() => showTargetScoreWizard()}
                    >
                      Send Reminder
                    </button>
                  ) : (
                    <>
                      <button
                        data-tip=""
                        data-for="assess-update-risk-tolerance-tooltip"
                        data-event="fake"
                        className="btn btn-primary risk-card btn-small"
                        type="button"
                        ref={this.riskToleranceTooltipRef}
                        onClick={() => showTargetScoreWizard()}
                      >
                        {targetScore && targetScore.overall
                          ? 'Update Risk Tolerance'
                          : 'Assess Risk Tolerance'}
                      </button>

                      <ReactTooltip
                        id="assess-update-risk-tolerance-tooltip"
                        effect="solid"
                        place="bottom"
                        clickable
                      >
                        <img
                          className="tooltip-close-button"
                          src={tooltipCloseButton}
                          alt="Close"
                          onClick={this.hideAssessRiskToleranceTooltip}
                        />
                        Find out risk tolerance by inviting the prospect/client to take the
                        questionnaire.{' '}
                        <span role="img" aria-label="happy-face">
                          😃
                        </span>
                      </ReactTooltip>

                      {targetScore &&
                        _.isEmpty(targetScore) &&
                        !investorRtqSentTimestamp &&
                        !tooltipManuallyClosed &&
                        ReactTooltip.show(this.riskToleranceTooltipRef.current)}
                    </>
                  )}
                </div>
              </div>
            </CardOverview>

            <CardOverview title="Portfolio Risk">
              <div>
                <div className="card-description">
                  {prismScore && prismScore.overall ? (
                    <RiskOverall score={prismScore.overall} smaller small />
                  ) : (
                    <span className="info-text"> No data to show </span>
                  )}
                </div>
                <div className="button-div">
                  <Link
                    disabled={!prismScore || !prismScore.overall}
                    to={`/advisor/${clientType}/${investor.id}/${tabs.PRISM}`}
                    className="btn btn-primary btn-small"
                  >
                    Review Portfolio Risk
                  </Link>
                </div>
              </div>
            </CardOverview>

            <CardOverview title="Check-in">
              <div>
                <div
                  className={cn('card-description', {
                    'card-description--check-in': !loadingLatestCheckIn
                  })}
                >
                  <CheckInAlertIcon latestCheckIn={latestCheckIn} />
                  {loadingLatestCheckIn && <SpinnerLoader />}
                  {!loadingLatestCheckIn && latestCheckIn && (
                    <>
                      <div className="check-in__summary">
                        <span>Market Sentiment</span>
                        <span>Financial Future</span>
                        <CheckInIcon
                          color={latestCheckIn.data.market_sentiment.color}
                          height={25}
                          label={latestCheckIn.data.market_sentiment.value}
                          src={latestCheckIn.data.market_sentiment.image}
                        />
                        <CheckInIcon
                          color={latestCheckIn.data.financial_future.color}
                          height={25}
                          label={latestCheckIn.data.financial_future.value}
                          src={latestCheckIn.data.financial_future.image}
                        />
                      </div>
                      <div className="check-in__dates">
                        <span>Last Check-in: {moment.utc(latestCheckIn.created).format('ll')}</span>
                        {latestCheckIn.next_checkin_date && (
                          <span>
                            Next Check-in:{' '}
                            {moment.utc(latestCheckIn.next_checkin_date).format('ll')}
                          </span>
                        )}
                      </div>
                    </>
                  )}
                  {!loadingLatestCheckIn && !latestCheckIn && (
                    <span className="info-text">No data available</span>
                  )}
                </div>
                <div className="button-div">
                  <Link
                    to={`/advisor/${clientType}/${investor.id}/${tabs.CHECK_INS}`}
                    className="btn btn-primary btn-small"
                  >
                    View Check-ins
                  </Link>
                </div>
              </div>
            </CardOverview>

            <CardOverview title="Portfolio Insights" newTag>
              <div>
                <div className="card-description">
                  {prismScore && prismScore.overall ? (
                    <span className="info-text">
                      Discover in-depth insights into portfolio allocation, sector exposure,
                      investment style, and more.
                    </span>
                  ) : (
                    <span className="info-text">No information to display Portfolio Insights</span>
                  )}
                </div>
                <div className="button-div">
                  <Link
                    disabled={!prismScore || !prismScore.overall}
                    className="btn btn-primary btn-small"
                    to={`/advisor/${clientType}/${investor.id}/${tabs.PORTFOLIO_INSIGHTS}`}
                  >
                    Explore Portfolio
                  </Link>
                </div>
              </div>
            </CardOverview>

            <CardOverview title="Proposals">
              <div>
                <div className="card-description">
                  <span className="info-text">
                    Generate a personalized proposal by selecting a model portfolio tailored to the
                    client&apos;s needs.
                  </span>
                </div>
                <div className="button-div">
                  <Link
                    disabled={!somePrismOverall}
                    className="btn btn-primary btn-small"
                    to={`/advisor/${clientType}/${investor.id}/${tabs.PROPOSAL}`}
                  >
                    Generate Proposal
                  </Link>
                </div>
              </div>
            </CardOverview>

            <CardOverview title="IPS">
              <div>
                <div className="card-description">
                  <span className="info-text">
                    Create a professional IPS and request a digital signature from the client for
                    streamlined compliance.
                  </span>
                </div>
                <div className="button-div">
                  <Link
                    disabled={
                      !targetScore || !targetScore.overall || !prismScore || !prismScore.overall
                    }
                    className="btn btn-primary btn-small"
                    to={`/advisor/${clientType}/${investor.id}/${tabs.IPS}`}
                  >
                    Generate IPS
                  </Link>
                </div>
              </div>
            </CardOverview>

            {latestNote && (
              <div id="compliance-note-card">
                <div className="note-content">
                  <span className="font-weight-bold">Previous note:</span>
                  <div className="note-content__message">
                    {latestNote.message.replace(/(.{65})..+/, '$1…')}
                  </div>
                  <div className="note-content__date">
                    {moment(latestNote.modified).format(FULL_DATE_FORMAT)}
                  </div>
                </div>
                <Link
                  className="btn btn-outline-primary btn-small"
                  to={`/advisor/${clientType}/${investor.id}/${tabs.COMPLIANCE_NOTES}`}
                >
                  Add a note
                </Link>
              </div>
            )}
          </div>

          {accounts && (
            <div className="investor-accounts__container">
              <InvestorAccountsOverviewTable
                accounts={accounts}
                getData={this.getData}
                investor={investor}
                selectedAccountIds={selectedAccountIds}
              />
              <div className="scroll-to-top__container">
                <ScrollToTop />
              </div>
            </div>
          )}
        </div>

        <Modal
          id="basic-profile-modal"
          key={3}
          title="test"
          className="modal-lg modal-basic-profile"
          show={basicProfileModal}
          onShown={this.showModal}
          onHidden={this.closeModal}
        >
          <ModalHeader title={_.isEmpty(investor) ? '' : `${investor.full_name}'s Profile`} />
          <ModalBody>
            <InvestorProfileBasic
              investor={investor}
              investorProvider={clientProvider}
              closeModal={this.closeModal}
              getInvestor={this.getInvestor}
            />
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

InvestorOverview.propTypes = {
  accounts: PropTypes.array.isRequired,
  households: PropTypes.array.isRequired,
  investor: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  selectedAccountIds: PropTypes.array,
  totalAccounts: PropTypes.number.isRequired,
  totalValue: PropTypes.number.isRequired
};

InvestorOverview.defaultProps = {
  selectedAccountIds: []
};

InvestorOverview.contextTypes = {
  account: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  prospectProvider: PropTypes.object.isRequired,
  clientProvider: PropTypes.object.isRequired,
  householdProvider: PropTypes.object.isRequired,
  showTargetScoreWizard: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userProvider: PropTypes.object.isRequired
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  const {
    location: { pathname }
  } = ownProps;
  const {
    investor,
    investorAccounts,
    investorsBreakdownCustomSecurities,
    prospect,
    prospectAccounts,
    prospectsBreakdownCustomSecurities
  } = stateProps;

  const isProspect = _.includes(pathname, 'advisor/prospects/');
  const accounts = isProspect ? prospectAccounts : investorAccounts;
  let totalAccounts = 0;
  let totalValue = 0;

  accounts.forEach(account => {
    if (account.excluded === false) {
      totalAccounts += 1;
      totalValue += account.value;
    }
  });

  return {
    ...stateProps,
    ...ownProps,
    ...dispatchProps,
    accounts,
    breakdownCustomSecurities: isProspect
      ? prospectsBreakdownCustomSecurities
      : investorsBreakdownCustomSecurities,
    investor: isProspect ? prospect : investor,
    totalAccounts,
    totalValue
  };
}

export default connect(
  state => ({
    households: state.households.list,
    investor: state.investors.view,
    investorAccounts: state.investors.viewAccounts || [],
    investorsBreakdownCustomSecurities: state.investors.breakdownCustomSecurities,
    prospect: state.prospects.view,
    prospectAccounts: state.prospects.viewAccounts || [],
    prospectsBreakdownCustomSecurities: state.prospects.breakdownCustomSecurities,
    selectedAccountIds: state.accounts.selectedAccounts || []
  }),
  null,
  mergeProps
)(InvestorOverview);
