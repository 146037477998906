/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/label-has-for, jsx-a11y/no-static-element-interactions */
import cn from 'classnames';
import CheckInIcon from 'components/advisor/investors/check-ins-history/icon';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const MultiBoxField = ({ field, flavor, multiple, options }) => {
  const getCurrentValues = () => {
    let previousValues = [];

    if (!_.isUndefined(field.value) && field.value !== '') previousValues = field.value;
    else if (!_.isUndefined(field.initialValue) && field.initialValue !== '')
      previousValues = field.initialValue;

    return _.isArray(previousValues) ? [...previousValues] : [previousValues];
  };

  const handleChange = value => () => {
    let values = getCurrentValues();

    if (values.includes(value)) values = values.filter(v => v !== value);
    else values = multiple ? [...values, value] : [value];

    field.onChange(values);
  };

  const values = getCurrentValues();

  return (
    <div className={cn('form-group', { [`multi--${flavor}`]: flavor })}>
      <div data-question={field.name} className="multi-box-container">
        {options.map(option => (
          <div
            className={cn('multi-box', { selected: values.includes(option.value) })}
            key={option.value}
            onClick={handleChange(option.value)}
          >
            <CheckInIcon src={option.image} color={option.color} label={option.value} />
          </div>
        ))}
      </div>
    </div>
  );
};

MultiBoxField.propTypes = {
  field: PropTypes.object.isRequired,
  flavor: PropTypes.string,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      image: PropTypes.string,
      value: PropTypes.string.isRequired
    })
  ).isRequired
};

MultiBoxField.defaultProps = {
  flavor: '',
  multiple: false
};

export default MultiBoxField;
