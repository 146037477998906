/* eslint-disable react/no-array-index-key */
import cn from 'classnames';
import DateValue from 'components/advisor/proposal/body/commons/date-value-label';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import PropTypes from 'prop-types';
import React from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';
import './styles.scss';

import {
  BENCHMARK_ANALYTICS,
  RECOMMENDED_ANALYTICS,
  TARGET_ANALYTICS,
  getAnnualizedReturnsFromSource,
  getCumulativeReturnsFromSource,
  getHistoricalReturnsHeaders
} from './utils';

const ProposalHistoricalReturn = ({
  actionsDisabled,
  metadata,
  propertyKey,
  proposal,
  setMetadata
}) => {
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });

  const cumulativeHeaders = getHistoricalReturnsHeaders(proposal.start, 'Cumulative Total Return');
  const annualizedHeaders = getHistoricalReturnsHeaders(proposal.start, 'Annualized Return');

  const targetCumulativeReturns = getCumulativeReturnsFromSource(proposal, TARGET_ANALYTICS);
  const targetAnnualizedReturns = getAnnualizedReturnsFromSource(proposal, TARGET_ANALYTICS);

  const hasRecommended = !!proposal.recommended;
  const recommendedCumulativeReturns = hasRecommended
    ? getCumulativeReturnsFromSource(proposal, RECOMMENDED_ANALYTICS)
    : null;

  const recommendedAnnualizedReturns = hasRecommended
    ? getAnnualizedReturnsFromSource(proposal, RECOMMENDED_ANALYTICS)
    : null;

  const hasBenchmark = !!proposal.benchmark;
  const benchmarkCumulativeReturns = hasBenchmark
    ? getCumulativeReturnsFromSource(proposal, BENCHMARK_ANALYTICS)
    : null;
  const benchmarkAnnualizedReturns = hasBenchmark
    ? getAnnualizedReturnsFromSource(proposal, BENCHMARK_ANALYTICS)
    : null;

  return (
    <EditBoxText {...proposalMetadata}>
      <DateValue proposal={proposal} />
      <div className="historical-return-box">
        <div
          className={cn('fancy-grid', {
            'no-recommended': !hasRecommended,
            'no-benchmark': !hasBenchmark
          })}
        >
          {cumulativeHeaders.map(header => {
            const key = header ? header.toLowerCase().replace(/\s/g, '-') : 'empty';
            return (
              <div key={`hr-header-${key}`} className="header">
                {header}
              </div>
            );
          })}

          <div>{proposal.target_label || 'Current Portfolio'}</div>
          {targetCumulativeReturns.map((value, i) => (
            <div key={`hr-target-${value}-${i}`}>{value}</div>
          ))}

          {hasRecommended && (
            <>
              <div>{proposal.recommended_label}</div>
              {recommendedCumulativeReturns.map((value, i) => (
                <div key={`hr-recommended-${value}-${i}`}>{value}</div>
              ))}
            </>
          )}

          {hasBenchmark && (
            <>
              <div>{proposal.benchmark_label}</div>
              {benchmarkCumulativeReturns.map((value, i) => (
                <div key={`hr-benchmark-${value}-${i}`}>{value}</div>
              ))}
            </>
          )}
        </div>
      </div>

      <div className="historical-return-box">
        <div
          className={cn('fancy-grid', {
            'no-recommended': !hasRecommended,
            'no-benchmark': !hasBenchmark
          })}
        >
          {annualizedHeaders.map(header => {
            const key = header ? header.toLowerCase().replace(/\s/g, '-') : 'empty';
            return (
              <div key={`hr-header-${key}`} className="header">
                {header}
              </div>
            );
          })}

          <div>{proposal.target_label || 'Current Portfolio'}</div>
          {targetAnnualizedReturns.map((value, i) => (
            <div key={`hr-target-${value}-${i}`}>{value}</div>
          ))}

          {hasRecommended && (
            <>
              <div>{proposal.recommended_label}</div>
              {recommendedAnnualizedReturns.map((value, i) => (
                <div key={`hr-recommended-${value}-${i}`}>{value}</div>
              ))}
            </>
          )}

          {hasBenchmark && (
            <>
              <div>{proposal.benchmark_label}</div>
              {benchmarkAnnualizedReturns.map((value, i) => (
                <div key={`hr-benchmark-${value}-${i}`}>{value}</div>
              ))}
            </>
          )}
        </div>
      </div>
    </EditBoxText>
  );
};

ProposalHistoricalReturn.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalType: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalHistoricalReturn;
