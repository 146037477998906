/* eslint-disable react/no-unstable-nested-components */
import cn from 'classnames';
import { DriftBox, SortableHeader } from 'components/utils/react-table';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { FormattedNumber } from 'react-intl';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { EXCEPTION_STATUS, EXCEPTION_TYPE } from 'utils/drift';
import { fromNow } from 'utils/utils';
import './styles.scss';

export const AccountExceptionsRow = ({
  id,
  exceptions,
  openNotesModal,
  headers,
  nonce,
  isClient
}) => {
  const { investorProvider } = useContext(AdvisorContext);

  useEffect(() => {
    if (isClient) investorProvider.getExceptions(id);
  }, [investorProvider, nonce, isClient]);

  const getExceptionAge = age => {
    if (_.isNil(age)) return '-';
    if (age === 0) return 'Today';
    return `${age} days old`;
  };

  const isSnoozedOrClosed = status =>
    [EXCEPTION_STATUS.SNOOZED, EXCEPTION_STATUS.CLOSED].includes(status);

  const columns = [
    {
      Header: <SortableHeader title="Status" />,
      id: 'status',
      minWidth: 140,
      accessor: ({ status }) => status,
      Cell: ({ original: exception }) => (
        <div className="status-col">
          <button
            type="button"
            className={cn('btn btn-transparent')}
            style={{ cursor: 'pointer' }}
            onClick={e => {
              openNotesModal(e, {
                accountId:
                  exception.target_type === EXCEPTION_TYPE.ACCOUNT
                    ? exception.target.id
                    : undefined,
                exceptionId: exception.id,
                investorId:
                  exception.target_type === EXCEPTION_TYPE.ACCOUNT
                    ? exception.target.investor.id
                    : exception.target.id,
                tags: exception.tags
              });
            }}
          >
            {exception.status}
          </button>
        </div>
      )
    },
    {
      Header: <SortableHeader title="Target" />,
      id: 'account-name',
      className: 'justify-left account-col',
      headerClassName: 'justify-left',
      minWidth: 280,
      accessor: ({ target }) => target.display_name,
      Cell: ({ original: { status, target, target_type } }) => {
        const iconSrc = `/img/icons/surveillance-exceptions/${target_type}.png`;
        return (
          <>
            <img className="mr-1" src={iconSrc} alt={target_type} height={17} />
            <span className={cn({ disabled: isSnoozedOrClosed(status) })}>
              {target.display_name || target.full_name}
            </span>
          </>
        );
      }
    },
    {
      Header: <SortableHeader title="Portfolio Value" />,
      id: 'account-value',
      minWidth: 180,
      accessor: ({ value }) => value,
      Cell: ({ original: { value, status } }) => (
        <span className={cn({ disabled: isSnoozedOrClosed(status) })}>
          <FormattedNumber value={value} format="currency" />
        </span>
      )
    },
    {
      Header: <SortableHeader title="Drift" />,
      id: 'drift',
      className: 'drift-row',
      minWidth: 80,
      accessor: ({ drift }) => drift,
      Cell: ({ original: { drift, is_healthy, status } }) => (
        <DriftBox isHealthy={is_healthy} snoozed={status === 'Snoozed'} value={drift} />
      )
    },
    {
      Header: <SortableHeader title="Age" />,
      id: 'age',
      minWidth: 140,
      accessor: ({ age }) => age,
      Cell: ({ original: { age, status } }) => (
        <span className={cn({ disabled: isSnoozedOrClosed(status) })}>{getExceptionAge(age)}</span>
      )
    },
    {
      Header: <SortableHeader title="Last updated" />,
      id: 'modified',
      minWidth: 140,
      accessor: ({ modified }) => modified,
      Cell: ({ original: { modified, status } }) => (
        <span className={cn({ disabled: isSnoozedOrClosed(status) })}>{fromNow(modified)} ago</span>
      )
    }
  ];

  return (
    <ReactTable
      className="exception-row"
      columns={columns}
      data={exceptions[id]}
      getTheadProps={() => (headers ? {} : { style: { display: 'none' } })}
      minRows={1}
      noDataText="No Exceptions"
      showPagination={false}
      showPageSizeOptions={false}
      sortable={headers}
    />
  );
};

AccountExceptionsRow.propTypes = {
  exceptions: PropTypes.object,
  headers: PropTypes.bool,
  id: PropTypes.number.isRequired,
  isClient: PropTypes.bool,
  nonce: PropTypes.number,
  openNotesModal: PropTypes.func.isRequired
};

AccountExceptionsRow.defaultProps = {
  exceptions: {},
  headers: false,
  nonce: 0,
  isClient: true
};

export default connect(state => ({
  exceptions: state.investors.exceptions
}))(AccountExceptionsRow);
