/* eslint-disable default-param-last */
import { SURVEILLANCE_EXCEPTIONS_LIST_ES } from 'constants/actions';

const initialState = {
  esList: [],
  esListMeta: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SURVEILLANCE_EXCEPTIONS_LIST_ES:
      return { ...state, esList: action?.data || [], esListMeta: action?.meta || {} };

    default:
      return state;
  }
};
