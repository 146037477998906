import DashboardHeader from 'components/advisor/dashboard/dashboard-header';
import DashboardButtonSection from 'components/advisor/dashboard/header-button-section';
import BookLevelExceptionsWidget from 'components/advisor/dashboard/widgets/book-level-exceptions-widget';
import CheckInsReviewWidget from 'components/advisor/dashboard/widgets/check-ins-review-widget';
import DriftExceptionsWidget from 'components/advisor/dashboard/widgets/drift-exceptions-widget';
import DriftLevelWidget from 'components/advisor/dashboard/widgets/drift-level-widget';
import RiskDistributionWidget from 'components/advisor/dashboard/widgets/risk-distribution-widget';
import RiskLevelWidget from 'components/advisor/dashboard/widgets/risk-level-widget';
import StrategiesListWidget from 'components/advisor/dashboard/widgets/strategies-list-widget';
import Disclosure from 'components/disclosure';
import { AdvisorContext } from 'containers/advisor';
import withSubscription from 'hocs/subscription-validation';
import _ from 'lodash';
import React, { useContext } from 'react';
import './styles.scss';

const defaultAnalyticsSetup = {
  'risk-drift-from-tolerance': { enabled: true },
  'book-level-exceptions': { enabled: true },
  'prism-rating': { enabled: true },
  'drift-exceptions': { enabled: true },
  'portfolio-drift-exceptions': { enabled: true },
  'prism-rating-distribution': { enabled: true },
  'check-ins-review': { enabled: true }
};

const AdvisorDashboard = () => {
  const { user } = useContext(AdvisorContext);
  const analyticsSetup = _.merge({}, defaultAnalyticsSetup, user.advisor.company.analytics_setup);

  return (
    <div id="Dashboard">
      <section className="advisor-dashboard">
        <DashboardHeader />

        <div className="widget-container">
          <DashboardButtonSection />
        </div>

        <div id="dashboard-content">
          {analyticsSetup['risk-drift-from-tolerance'].enabled && <DriftLevelWidget />}
          {analyticsSetup['book-level-exceptions'].enabled && <BookLevelExceptionsWidget />}
          {analyticsSetup['prism-rating'].enabled && <RiskLevelWidget />}
          {analyticsSetup['drift-exceptions'].enabled && <DriftExceptionsWidget />}
          {analyticsSetup['portfolio-drift-exceptions'].enabled && <StrategiesListWidget />}
          {analyticsSetup['prism-rating-distribution'].enabled && <RiskDistributionWidget />}
          {analyticsSetup['check-ins-review'].enabled && <CheckInsReviewWidget />}
        </div>

        <Disclosure />
      </section>
    </div>
  );
};

export default withSubscription({
  plan: 'business',
  id: 'analytics',
  name: 'Analytics Dashboard'
})(React.memo(AdvisorDashboard));
