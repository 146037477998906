import DateValue from 'components/advisor/proposal/body/commons/date-value-label';
import EditBoxText from 'components/advisor/utils/edit-box-text';
import ClusteredColumnChart from 'components/charts/clustered-column-chart';
import UpsideDownsideChart, { THEME } from 'components/charts/upside-downside-chart';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useContext } from 'react';
import useProposalMetadata from '../hooks';
import { MetadataPropTypes, ProposalPropTypes } from '../types';
import { CLUSTERED_COLUMN_CHART_ID } from './constants';
import setOptions from './options';
import ScenarioPerformance from './scenario-performance';
import './styles.scss';
import { getScenariosPerformance } from './utils';

const ProposalMarketScenarioAnalysis = ({
  actionsDisabled,
  metadata,
  propertyKey,
  proposal,
  proposalCharts,
  setMetadata
}) => {
  const {
    proposalProvider,
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);

  const onChartReady = useCallback(
    (id, data) => {
      proposalProvider.saveChartImage({ [id]: data });
    },
    [proposalCharts[CLUSTERED_COLUMN_CHART_ID]]
  );

  const { benchmark, target, recommended } = proposal;
  const proposalMetadata = useProposalMetadata({
    actionsDisabled,
    metadata,
    propertyKey,
    setMetadata
  });

  const market = {
    up: company.market_upside_performance,
    down: company.market_downside_performance
  };

  const upCapturePercentage = Math.round(
    proposal.target.prism_score_summary.upside_capture_ratio * market.up
  );
  const downCapturePercentage = Math.round(
    proposal.target.prism_score_summary.downside_capture_ratio * market.down
  );

  const targetName = proposal.target_label || 'Current Portfolio';
  const options = setOptions(targetName, proposal.recommended_label, proposal.benchmark_label);
  const scenarios = getScenariosPerformance(target.scenarios, target, recommended, benchmark);

  const upsideDownsideChartData = {
    target: {
      upside: proposal.target.prism_score_summary.upside_capture_ratio / 10,
      downside: proposal.target.prism_score_summary.downside_capture_ratio / 10,
      name: targetName
    },
    recommended: recommended
      ? {
          upside: proposal.recommended.prism_score_summary.upside_capture_ratio / 10,
          downside: proposal.recommended.prism_score_summary.downside_capture_ratio / 10,
          name: proposal.recommended_label
        }
      : null,
    benchmark: benchmark
      ? {
          upside: proposal.benchmark.prism_score_summary.upside_capture_ratio / 10,
          downside: proposal.benchmark.prism_score_summary.downside_capture_ratio / 10,
          name: proposal.benchmark_label
        }
      : null
  };

  return (
    <EditBoxText {...proposalMetadata}>
      <div>
        <p className="historical-explanation">
          Historically, the S&amp;P 500 has been between <span className="down">-{market.down}%</span>{' '}
          and <span className="up">+{market.up}%</span> about 90% of the time. If the S&amp;P 500 was
          down <span className="down">{market.down}%</span> then this portfolio&apos;s performance
          may be{' '}
          {downCapturePercentage ? <span className="down">-{downCapturePercentage}%</span> : '-'}{' '}
          and if the S&amp;P 500 was up <span className="up">{market.up}%</span> then this
          portfolio&apos;s performance may be{' '}
          {upCapturePercentage ? <span className="up">{upCapturePercentage}%</span> : '-'}
        </p>

        <DateValue proposal={proposal} />

        <div className="legend">
          <div>
            <div className="box series-0" />
            <div className="title">{targetName}</div>
          </div>
          {recommended && (
            <div>
              <div className="box series-1" />
              <div className="title">{proposal.recommended_label}</div>
            </div>
          )}
          {benchmark && (
            <div>
              <div className="box series-2" />
              <div className="title">{proposal.benchmark_label}</div>
            </div>
          )}
        </div>

        <div className="scenarios">
          {scenarios.map(scenario => (
            <div className="scenario" key={scenario.name}>
              <div className="scenario__title">{scenario.title}</div>
              <div className="scenario__performance">
                <div className="performance__details">
                  <ScenarioPerformance
                    className="series-0"
                    percentage={scenario.target}
                    startingValue={proposal.starting_value}
                  />
                  {!_.isNil(scenario?.recommended) && (
                    <ScenarioPerformance
                      className="series-1"
                      percentage={scenario.recommended}
                      startingValue={proposal.starting_value}
                    />
                  )}
                  {!_.isNil(scenario?.benchmark) && (
                    <ScenarioPerformance
                      className="series-2"
                      percentage={scenario.benchmark}
                      startingValue={proposal.starting_value}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>

        <ClusteredColumnChart
          data={scenarios}
          fullWidth
          id={CLUSTERED_COLUMN_CHART_ID}
          onChartReady={onChartReady}
          options={options}
          style={{ height: 350 }}
        />

        <div className="performance-row">
          <div className="twelve-months-chart">
            <div className="timeframe">
              Next<span>12</span>Months
            </div>
            <UpsideDownsideChart
              {...upsideDownsideChartData}
              theme={THEME.proposal}
              withRecommended={!!recommended}
            />
          </div>

          <div className="performace-value">
            <div>
              <h4>Downside performance</h4>
              <div className="scenario__performance">
                <div className="performance__details">
                  <ScenarioPerformance
                    className="series-0"
                    percentage={Math.round(
                      -1 * proposal.target.prism_score_summary.downside_capture_ratio * market.down
                    )}
                    startingValue={proposal.starting_value}
                  />
                  {recommended && (
                    <ScenarioPerformance
                      className="series-1"
                      percentage={Math.round(
                        -1 *
                          proposal.recommended.prism_score_summary.downside_capture_ratio *
                          market.down
                      )}
                      startingValue={proposal.starting_value}
                    />
                  )}
                  {benchmark && (
                    <ScenarioPerformance
                      className="series-2"
                      percentage={Math.round(
                        -1 *
                          proposal.benchmark.prism_score_summary.downside_capture_ratio *
                          market.down
                      )}
                      startingValue={proposal.starting_value}
                    />
                  )}
                </div>
              </div>
            </div>

            <div>
              <h4>Upside performance</h4>
              <div className="scenario__performance">
                <div className="performance__details">
                  <ScenarioPerformance
                    className="series-0"
                    percentage={Math.round(
                      proposal.target.prism_score_summary.upside_capture_ratio * market.up
                    )}
                    startingValue={proposal.starting_value}
                  />
                  {recommended && (
                    <ScenarioPerformance
                      className="series-1"
                      percentage={Math.round(
                        proposal.recommended.prism_score_summary.upside_capture_ratio * market.up
                      )}
                      startingValue={proposal.starting_value}
                    />
                  )}
                  {benchmark && (
                    <ScenarioPerformance
                      className="series-2"
                      percentage={Math.round(
                        proposal.benchmark.prism_score_summary.upside_capture_ratio * market.up
                      )}
                      startingValue={proposal.starting_value}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EditBoxText>
  );
};

ProposalMarketScenarioAnalysis.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  proposal: PropTypes.shape(ProposalPropTypes).isRequired,
  proposalCharts: PropTypes.object.isRequired,
  proposalType: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired
};

export default ProposalMarketScenarioAnalysis;
