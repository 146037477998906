import React, { useContext } from 'react';
import { trackAmplitudeEvent } from 'utils/tracking';
import PropTypes from 'prop-types';
import ScoreBubble from 'components/advisor/utils/score-bubble';
import { numToRiskScaleString } from 'utils/utils';
import { AdvisorContext } from 'containers/advisor';
import { RISK_LEVELS } from 'app/containers/risk-tolerance-questionnaire/result/score-context/utils/constants';
import { Link } from 'react-router';
import { RequestAction } from 'components/advisor/section-locked/index';
import './styles.scss';

const truncateDescription = (description, maxLength = 100) => {
  if (description.length <= maxLength) return description;
  return `${description.substring(0, maxLength)}...`;
};

const assambleCoverImageUrl = coverId => {
  if (!coverId) return null;
  return `https://assets.stratifi.com/marketplace/default_covers/${coverId}.png`;
};

const ROLLBACK_BG_COLOR = '#4897d9';

const ModelPortfolioCard = ({
  id,
  name,
  cover,
  coverDefault,
  logo,
  companyName,
  displayName,
  inceptionDate,
  type,
  description,
  prismScore,
  subscriptionStatus
}) => {
  const { modelProvider, user } = useContext(AdvisorContext);

  const riskLevels = user?.advisor?.company?.risk_levels || RISK_LEVELS;

  const onRequestAccessClick = () => {
    trackAmplitudeEvent('marketplace.requested', { id });
    modelProvider.postSubscription(id);
  };

  const truncatedDescription = truncateDescription(description || '', 100);

  const bgStyle = (cover, coverDefault) => {
    if (cover) return { backgroundImage: `url(${cover})` };

    if (coverDefault) return { backgroundImage: `url(${assambleCoverImageUrl(coverDefault)})` };

    return { backgroundColor: ROLLBACK_BG_COLOR };
  };

  const viewDetailsLink = `/advisor/marketplace/${id}`;
  let requestAccessText = '';

  if (subscriptionStatus === 'pending') requestAccessText = 'Access requested.';
  if (subscriptionStatus === 'revoked')
    requestAccessText = 'The access to this model was not approved.';

  return (
    <div className="portfolio-model-card">
      <div className="portfolio-model-card__top">
        <div
          className="portfolio-model-card__cover"
          style={{
            ...bgStyle(cover, coverDefault)
          }}
        >
          <div className="portfolio-model-card__logo">
            {logo ? (
              <img src={logo} alt={companyName} />
            ) : (
              <div className="portfolio-model-card__logo__rollback-text">{companyName}</div>
            )}
          </div>
        </div>
        <div className="portfolio-model-card__content">
          {displayName && <h2 className="portfolio-model-card__title">{displayName}</h2>}
          {inceptionDate && (
            <div className="portfolio-model-card__date">
              {new Date(inceptionDate).toLocaleDateString('en-US', {
                month: 'short',
                day: '2-digit',
                year: 'numeric'
              })}
            </div>
          )}
          {type && <div className="portfolio-model-card__type">{type}</div>}
          {description && (
            <div className="portfolio-model-card__description">{truncatedDescription}</div>
          )}
          <div className="portfolio-model-card__prism">
            <ScoreBubble score={prismScore} className="risk-bubble" />
            <span className="portfolio-model-card__prism-label">
              {numToRiskScaleString(prismScore, riskLevels)}
            </span>
          </div>
        </div>
      </div>

      <div className="portfolio-model-card__bottom">
        <Link to={viewDetailsLink} className="btn btn-primary portfolio-model-card__link">
          Explore Model
        </Link>

        <div className="portfolio-model-card__additional-data">
          <span>{requestAccessText}</span>
        </div>
      </div>
    </div>
  );
};

ModelPortfolioCard.defaultProps = {
  id: '',
  name: '',
  cover: '',
  coverDefault: '',
  logo: '',
  companyName: '',
  displayName: '',
  inceptionDate: '',
  type: '',
  description: '',
  prismScore: '',
  subscriptionStatus: null
};

ModelPortfolioCard.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  cover: PropTypes.string,
  coverDefault: PropTypes.string,
  logo: PropTypes.string,
  companyName: PropTypes.string,
  displayName: PropTypes.string,
  inceptionDate: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  prismScore: PropTypes.string,
  subscriptionStatus: PropTypes.string || null
};

export default ModelPortfolioCard;
