import RiskGaugeChart from 'components/charts/risk-gauge-chart';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { formatMoney, range } from 'utils/utils';
import DocumentationNoteForm from '../documentation-note';
import '../styles.scss';

const QuestionnaireIntro = ({
  flavor,
  investors,
  isProspect,
  mode,
  onQuestionnaireBegin,
  onQuestionnaireCancel,
  questionnaireTemplate
}) => {
  const { questionProvider, user } = useContext(AdvisorContext);

  const [formData, setFormData] = useState({ answers_source: 'In-person', answers_date: '' });

  const sourceIsRequired =
    user?.advisor?.company?.questionnaire_compliance_note_required_for_advisors;
  const startDisabled = sourceIsRequired && (!formData.answers_source || !formData.answers_date);

  const handleFormDataChange = (field, value) => {
    setFormData(prevData => ({ ...prevData, [field]: value }));
  };

  const handleSave = () => {
    if (flavor === 'advisor') {
      const data = { ...formData };
      if (formData.answers_date)
        // eslint-disable-next-line prefer-destructuring
        data.answers_date = formData.answers_date.toISOString().split('T')[0];
      questionProvider.saveDocumentationNotes(data);
    }
    onQuestionnaireBegin();
  };

  return (
    <div
      className={`prism-questions-begin ${
        flavor === 'investor' ? 'prism-investor-content' : 'prism-questions-card'
      }`}
    >
      {questionnaireTemplate?.is_scored && (
        <p className="prism-questions-begin-text">
          <FormattedMessage
            id={flavor === 'advisor' ? 'rtq.common.intro-advisor' : 'rtq.common.intro'}
          />
        </p>
      )}
      {questionnaireTemplate?.is_check_in && (
        <p className="prism-questions-begin-text text-lg-center">
          <FormattedMessage
            id="check-in.intro"
            values={{ br: <br />, number: questionnaireTemplate?.questions?.length }}
          />
        </p>
      )}

      {flavor === 'advisor' && !!investors.length && (
        <div className="selected-targets">
          <h3>
            <FormattedMessage
              id="rtq.common.note"
              values={{ prospect: mode.startsWith('investor') && isProspect }}
            />
          </h3>
          {mode.startsWith('account') && (
            <>
              {investors[0].accounts.map(account => (
                <div className="target" key={`target-${account.id}`}>
                  <div className="name">{account.display_name}</div>
                  <div className="value">{formatMoney(account.value)}</div>
                </div>
              ))}
            </>
          )}
          {mode.startsWith('investor') && (
            <>
              {investors.map(investor => (
                <div className="target" key={`target-${investor.id}`}>
                  <div className="name">{investor.full_name}</div>
                </div>
              ))}
            </>
          )}
        </div>
      )}

      <div
        className="text-sm-center prism-questions-action prism-questions-begin-get-started prism-button"
        data-advisor={flavor === 'advisor'}
      >
        {flavor === 'advisor' && (
          <DocumentationNoteForm
            formData={formData}
            onFormDataChange={handleFormDataChange}
            sourceIsRequired={sourceIsRequired}
          />
        )}

        <button
          aria-label="Start Questionnaire"
          className="btn btn-primary"
          data-action="start-questionnaire"
          disabled={startDisabled}
          onClick={handleSave}
          type="button"
        >
          <FormattedMessage id="rtq.common.get-started" />
        </button>
        <br />
        {flavor === 'advisor' && (
          <button
            aria-label="Do this later"
            className="do-this-later btn-transparent"
            onClick={onQuestionnaireCancel}
            type="button"
          >
            <FormattedMessage id="rtq.common.do-later" />
          </button>
        )}
      </div>

      {!questionnaireTemplate?.is_check_in && flavor !== 'advisor' && !!investors.length && (
        <div className="text-sm-center mb-3 prism-questions-begin-scores prism-charts">
          <div className="gauge-container">
            <RiskGaugeChart id="low-risk-gauge" value={1} />
            <h4 className="low">
              <FormattedMessage id="rtq.common.chart.low-risk" />
            </h4>
          </div>
          <div className="prism-questions-begin-scores-dots">
            {range(1, 3).map(i => (
              <div key={i} className="prism-questions-begin-scores-dot" />
            ))}
          </div>
          <div className="gauge-container">
            <RiskGaugeChart id="high-risk-gauge" value={9} />
            <h4 className="high">
              <FormattedMessage id="rtq.common.chart.high-risk" />
            </h4>
          </div>
        </div>
      )}
    </div>
  );
};

QuestionnaireIntro.propTypes = {
  flavor: PropTypes.string.isRequired,
  investors: PropTypes.array,
  isProspect: PropTypes.bool,
  mode: PropTypes.string.isRequired,
  onQuestionnaireBegin: PropTypes.func.isRequired,
  onQuestionnaireCancel: PropTypes.func.isRequired,
  questionnaireTemplate: PropTypes.object
};

QuestionnaireIntro.defaultProps = {
  investors: [],
  isProspect: false,
  questionnaireTemplate: null
};

export default QuestionnaireIntro;
