import { StyleSheet } from '@react-pdf/renderer';
import {
  BASE_FONT_FAMILY,
  BASE_PAGE_PADDING,
  FOR_LANDSCAPE_ORIENTATION,
  LIGHT_GRAPHITE_COLOR
} from '../styles';

const BORDERS = {
  borderLeft: 20,
  borderLeftStyle: 'solid',
  borderRight: 20,
  borderRightStyle: 'solid'
};

const styles = StyleSheet.create({
  top: {
    ...BASE_PAGE_PADDING,
    ...BORDERS,
    flexGrow: 1,
    borderRightColor: 'white'
  },
  center: {
    ...BASE_PAGE_PADDING,
    ...BORDERS,
    flexGrow: 1,
    justifyContent: 'center',
    maxHeight: 250,
    borderLeftColor: 'white',
    borderRightColor: 'white',
    backgroundColor: LIGHT_GRAPHITE_COLOR,
    [FOR_LANDSCAPE_ORIENTATION]: {
      maxHeight: 200
    }
  },
  bottom: {
    ...BORDERS,
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingVertical: 25,
    maxHeight: 100,
    borderLeftColor: 'white'
  },
  brand: {
    maxWidth: 400,
    maxHeight: 150,
    objectFit: 'scale-down',
    objectPositionX: 'left',
    marginTop: 45,
    paddingHorizontal: 2,
    [FOR_LANDSCAPE_ORIENTATION]: {
      marginTop: 15,
      maxWidth: 450
    }
  },
  title: {
    ...BASE_FONT_FAMILY,
    fontSize: 24,
    fontWeight: 500,
    marginTop: 60,
    [FOR_LANDSCAPE_ORIENTATION]: {
      marginTop: 50
    }
  },
  subtitle: {
    ...BASE_FONT_FAMILY,
    fontWeight: 500,
    marginTop: 10
  },
  subtitleContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
    marginTop: 10
  },
  preparedFor: { ...BASE_FONT_FAMILY, fontWeight: 300 },
  preparedBy: { ...BASE_FONT_FAMILY, fontWeight: 300, fontSize: 14 },
  preparedByContent: { fontSize: 16, maxLines: 2, textOverflow: 'ellipsis' },
  advisorTitle: { ...BASE_FONT_FAMILY, fontSize: 10, fontWeight: 300, marginTop: 5 },
  advisorCompany: { ...BASE_FONT_FAMILY, fontSize: 14, marginTop: 10 },
  advisorContainer: { ...BASE_FONT_FAMILY, fontWeight: 500, lineHeight: 1.5 },
  companyContainer: {
    ...BASE_FONT_FAMILY,
    fontWeight: 300,
    fontSize: 10,
    marginTop: 10,
    lineHeight: 1.75
  },
  website: { textDecoration: 'none', color: 'black' },
  poweredBy: {
    ...BASE_FONT_FAMILY,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 300,
    fontSize: 6
  },
  dateContainer: {
    ...BASE_FONT_FAMILY,
    ...BORDERS,
    borderLeftColor: 'white',
    borderRightColor: 'white',
    backgroundColor: LIGHT_GRAPHITE_COLOR,
    fontWeight: 300,
    fontSize: 10,
    lineHeight: 1.75,
    paddingHorizontal: 25,
    paddingBottom: 10
  }
});

export default styles;
