/* global FULL_DATE_FORMAT */
/* eslint-disable no-nested-ternary */
import AccountNotesModal from 'components/advisor/accounts/notes-modal';
import LoadingPrismDataMessage from 'components/advisor/dashboard/loading-prism-data-message';
import PositionsAnalysis from 'components/advisor/risk-analysis/risk-analysis-target/positions-analysis';
import SecurityTypeConcentration from 'components/advisor/risk-analysis/risk-analysis-target/security-type-concentration';
import GeographicExposure from 'components/advisor/risk-analysis/securities/geographic-exposure';
import InvestmentStyle from 'components/advisor/risk-analysis/securities/investment-style';
import SectorExposure from 'components/advisor/risk-analysis/securities/sector-exposure';
import TopHoldings from 'components/advisor/risk-analysis/securities/top-holdings';
import ScrollCardsIndex from 'components/advisor/scroll-cards-index';
import ScrollToTop from 'components/advisor/scroll-cards-index/scroll-to-top';
import BreakdownCustomSecuritiesToggleTitle from 'components/form/breakdown-custom-securities-toggle-title';
import {
  ACCOUNT_ENTITY_TYPE,
  ALLOCATIONS_TYPE,
  GEOGRAPHIC_EXPOSURE_TYPE,
  INVESTMENT_STYLE_TYPE,
  SECTOR_EXPOSURE_TYPE,
  TOP_HOLDINGS_TYPE
} from 'components/form/breakdown-custom-securities-toggle-title/constants';
import TargetTimestamps from 'components/target-timestamps';
import DisplayScore from 'components/utils/DisplayScore';
import Link from 'components/utils/link';
import PrismRating from 'components/utils/prism-rating';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accountsWithPrismScoresInProgressSelector } from 'selectors/accounts';
import { numToRiskScaleString } from 'utils/utils';
import { RISK_LEVELS } from 'app/containers/risk-tolerance-questionnaire/result/score-context/utils/constants';

export class AccountDetailsOverview extends Component {
  constructor(props) {
    super(props);
    this.state = { latestNote: null, showNotesModal: false };
  }

  componentDidMount() {
    const { account, accountProvider } = this.context;
    accountProvider.lastNote(account).then(({ data }) => {
      this.setState({ latestNote: data });
    });
  }

  showLatestNote = () => {
    const { latestNote } = this.state;
    return (
      <div className="note-div">
        <div className="note-message">{latestNote.message.replace(/(.{65})..+/, '$1…')}</div>
        <div className="note-date">{moment(latestNote.modified).format(FULL_DATE_FORMAT)}</div>
      </div>
    );
  };

  onHideModal = () => {
    const { account, accountProvider } = this.context;
    accountProvider.get(account.id);
    accountProvider
      .lastNote(account)
      .then(({ data }) => {
        this.setState({ latestNote: data });
      })
      .finally(() => {
        this.setState({ showNotesModal: false });
      });
  };

  render() {
    const {
      account,
      investor: investorContext,
      showTargetScoreWizard,
      toggleEditAccountModal,
      user
    } = this.context;
    const { breakdownCustomSecurities, prismScoresInProgress } = this.props;
    const { latestNote, showNotesModal } = this.state;

    const portfolioBreakdownCustomSecurities = breakdownCustomSecurities[account.id];
    const investor = investorContext || account.investor;
    const isStrategy = account?.target_model?.is_strategy;
    const riskLevels = user?.advisor?.company?.risk_levels || RISK_LEVELS;
    const prismOverall =
      account && account.prism_score_summary && account.prism_score_summary.overall;
    const targetOverall =
      account && account.target_score_summary && account.target_score_summary.overall;

    return (
      <div className="account-overview-container">
        <TargetTimestamps
          createdAt={account.created_at}
          integrationLinkProvider={account.provider_name}
          integrationLinkUpdatedAt={account.integration_link_updated_at}
          prismUpdatedAt={account.prism_update_date}
          toleranceUpdatedAt={account.target_score_updated_at}
        />

        {account && !_.isEmpty(account.positions) && (
          <ScrollCardsIndex isStrategy={isStrategy} score={prismOverall} />
        )}

        {prismOverall && isStrategy ? (
          <div className="box-container box-position-analysis">
            <div className="header">
              <span className="prism-header">
                {account.target_model.name} Strategy Risk{' '}
                <span className="sub-text">Powered By</span> PRISM Rating &trade;
              </span>
            </div>

            <PrismRating
              prismSummary={account.prism_score_summary}
              targetSummary={account.target_score_summary}
              value={account.value}
            />
          </div>
        ) : null}

        {prismOverall && (
          <div className="box-container box-position-analysis" id="portfolio-risk">
            <div className="header">
              <span className="prism-header">
                {isStrategy ? 'Holdings Risk' : 'Portfolio Risk'}{' '}
                <span className="sub-text">Powered By</span> PRISM Rating &trade;
              </span>
              {!prismScoresInProgress.includes(account.id) && (
                <span className="account-value">
                  <Link
                    className="btn btn-primary view-button"
                    to={`/advisor/${investor.is_prospect ? 'prospects' : 'investors'}/${
                      investor.id
                    }/account/${account.id}/prism`}
                  >
                    View Report
                  </Link>
                </span>
              )}
            </div>
            {prismScoresInProgress.includes(account.id) ? (
              <div className="prism-update">
                <LoadingPrismDataMessage message="Calculating Portfolio Risk" inset />
              </div>
            ) : (
              <PrismRating
                prismSummary={account.original_prism_score_summary || account.prism_score_summary}
                targetSummary={account.target_score_summary}
                value={account.value}
              />
            )}
          </div>
        )}

        <div className="overview">
          <div className="card-deck">
            <div className="card">
              <div className="card-header">Risk Tolerance</div>
              <div className="card-body">
                <div>
                  <div className="info-div">
                    {targetOverall ? (
                      <div className="tolerance-div">
                        <DisplayScore
                          score={targetOverall}
                          manualScore={account.target_score_manual}
                        />
                        <span className="text">
                          {numToRiskScaleString(targetOverall, riskLevels)}
                        </span>
                      </div>
                    ) : (
                      <span className="info-text tolerance">No Risk Tolerance Generated yet</span>
                    )}
                  </div>
                  <div className="button-div">
                    <button
                      className="btn btn-primary risk-card"
                      type="button"
                      onClick={() => showTargetScoreWizard()}
                    >
                      Update Risk Tolerance
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-header">Compliance Note</div>
              <div className="card-body">
                <div>
                  {latestNote ? (
                    <div>
                      <span className="prev-note">Previous note:</span>
                      <br />
                      {this.showLatestNote()}
                    </div>
                  ) : (
                    <span className="info-text notes">
                      Spoke to your Client about their Portfolio Risk?
                      <br />
                      Leave a note for tracking.
                    </span>
                  )}
                </div>
                <div className="button-div">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={e => {
                      this.setState({ showNotesModal: true });
                    }}
                  >
                    Add a Note
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {account && account.value > 0 && (
          <div>
            <div className="box-container box-position-analysis overview-portfolio" id="allocation">
              <div className="header">
                <BreakdownCustomSecuritiesToggleTitle
                  portfolio={account}
                  portfolioType={ACCOUNT_ENTITY_TYPE}
                  sectionType={ALLOCATIONS_TYPE}
                />
              </div>
              <PositionsAnalysis
                breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                  ALLOCATIONS_TYPE
                )}
                hiddenVal={false}
                portfolio={account}
                expandRegions
              />
            </div>
            <div className="account__scroll-to-top">
              <ScrollToTop />
            </div>
          </div>
        )}

        {(!account || !(account.value > 0)) && (
          <div className="box-container no-value-container">
            <div className="no-value-info">
              Your account has no value. To see portfolio risk and generate reports, please add
              value to the account.
            </div>
            <br />
            <button
              className="btn btn-primary no-value-button"
              type="button"
              onClick={() => toggleEditAccountModal(account)}
            >
              Edit Account
            </button>
          </div>
        )}

        <div>
          <div className="box-container box-position-analysis">
            <div className="header">
              <BreakdownCustomSecuritiesToggleTitle
                isNewFeature
                portfolio={account}
                portfolioType={ACCOUNT_ENTITY_TYPE}
                sectionType={INVESTMENT_STYLE_TYPE}
              />
            </div>
            <InvestmentStyle
              breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                INVESTMENT_STYLE_TYPE
              )}
              portfolio={account}
            />
          </div>
          <div className="account__scroll-to-top">
            <ScrollToTop />
          </div>
        </div>

        <div>
          <div className="box-container box-position-analysis">
            <div className="header" id="sector-exposure--header">
              <BreakdownCustomSecuritiesToggleTitle
                isNewFeature
                portfolio={account}
                portfolioType={ACCOUNT_ENTITY_TYPE}
                sectionType={SECTOR_EXPOSURE_TYPE}
              />
            </div>
            <SectorExposure
              breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                SECTOR_EXPOSURE_TYPE
              )}
              portfolio={account}
            />
          </div>
          <div className="account__scroll-to-top">
            <ScrollToTop />
          </div>
        </div>

        <div>
          <div className="box-container box-position-analysis">
            <div className="header">
              <BreakdownCustomSecuritiesToggleTitle
                isNewFeature
                portfolio={account}
                portfolioType={ACCOUNT_ENTITY_TYPE}
                sectionType={TOP_HOLDINGS_TYPE}
              />
            </div>
            <TopHoldings
              breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                TOP_HOLDINGS_TYPE
              )}
              portfolio={account}
            />
          </div>
          <div className="account__scroll-to-top">
            <ScrollToTop />
          </div>
        </div>

        <div>
          <div className="box-container box-position-analysis">
            <div className="header" id="geographic-exposure--header">
              <BreakdownCustomSecuritiesToggleTitle
                isNewFeature
                portfolio={account}
                portfolioType={ACCOUNT_ENTITY_TYPE}
                sectionType={GEOGRAPHIC_EXPOSURE_TYPE}
              />
            </div>
            <GeographicExposure
              breakdownCustomSecurities={portfolioBreakdownCustomSecurities.includes(
                GEOGRAPHIC_EXPOSURE_TYPE
              )}
              portfolio={account}
            />
          </div>
          <div className="account__scroll-to-top">
            <ScrollToTop />
          </div>
        </div>

        {account && !_.isEmpty(account.positions) && (
          <div>
            <div className="box-container box-position-analysis overview-portfolio">
              <div className="header">
                <span className="full-name">Security Type Concentration</span>
              </div>
              <SecurityTypeConcentration
                positions={account.positions}
                totalAssets={account.value}
              />
            </div>
            <div className="account__scroll-to-top">
              <ScrollToTop />
            </div>
          </div>
        )}

        {showNotesModal && (
          <AccountNotesModal
            accountId={account.id}
            investorId={account.investor.id}
            onHide={this.onHideModal}
            show
          />
        )}
      </div>
    );
  }
}

AccountDetailsOverview.contextTypes = {
  account: PropTypes.object.isRequired,
  accountProvider: PropTypes.object.isRequired,
  investor: PropTypes.object.isRequired,
  showTargetScoreWizard: PropTypes.func.isRequired,
  toggleEditAccountModal: PropTypes.func.isRequired
};

AccountDetailsOverview.propTypes = {
  breakdownCustomSecurities: PropTypes.object.isRequired,
  prismScoresInProgress: PropTypes.array.isRequired
};

export default connect(state => ({
  breakdownCustomSecurities: state.accounts.breakdownCustomSecurities,
  prismScoresInProgress: accountsWithPrismScoresInProgressSelector(state)
}))(AccountDetailsOverview);
