import Widget, { LAYOUTS } from 'components/advisor/dashboard/widgets/';
import useWidget, {
  DATASETS,
  widgetStorePropTypes,
  widgetStoreSelector
} from 'components/advisor/dashboard/widgets/hooks';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import './styles.scss';
import {
  GET_IN_TOUCH_URL_PARAM,
  HAS_CHECKIN_URL_PARAM,
  NEGATIVE,
  POSITIVE,
  SENTIMENT_URL_PARAM
} from './utils';

const CheckInsReviewWidget = ({ store, layout }) => {
  const fetch = (provider, params) => provider.es.list({ ...params, get_all: true });

  const { data, dataset, ...widget } = useWidget({
    fetch,
    store,
    datasets: [DATASETS.CLIENTS, DATASETS.PROSPECTS]
  });

  const baseUrl = dataset === DATASETS.PROSPECTS ? '/advisor/prospects' : '/advisor/investors';

  const investorsWithCheckIn = (data || []).filter(investor => investor.has_checkin);
  const investorsRequestingContact = investorsWithCheckIn.filter(investor => investor.get_in_touch);
  const investorSentiments = investorsWithCheckIn.reduce(
    (acc, investor) => {
      if (investor.sentiment === POSITIVE) acc[POSITIVE] += 1;
      if (investor.sentiment === NEGATIVE) acc[NEGATIVE] += 1;
      return acc;
    },
    { [POSITIVE]: 0, [NEGATIVE]: 0 }
  );

  return (
    <Widget
      {...widget}
      dataset={dataset}
      empty={_.isEmpty(investorsWithCheckIn)}
      layout={layout}
      title="Check-ins Review"
      id="CheckInsReviewWidget"
    >
      <div className="card-content__summary">
        <div className="summary__box">
          <div className="box__image">
            <img src="/img/icons/check-ins/positive.svg" alt="Positive" height={50} />
          </div>
          <div className="box__content">
            <span>{investorSentiments[POSITIVE]}</span>
            <p>Positive Sentiment</p>
            <Link to={`${baseUrl}?${SENTIMENT_URL_PARAM}=${POSITIVE}`}>View all</Link>
          </div>
        </div>
        <div className="summary__box">
          <div className="box__image">
            <img src="/img/icons/check-ins/negative.svg" alt="Negative" height={50} />
          </div>
          <div className="box__content">
            <span>{investorSentiments[NEGATIVE]}</span>
            <p>Negative Sentiment</p>
            <Link to={`${baseUrl}?${SENTIMENT_URL_PARAM}=${NEGATIVE}`}>View all</Link>
          </div>
        </div>
        <div className="summary__box">
          <div className="box__image">
            <img src="/img/icons/check-ins/calendar.svg" alt="Contact" height={50} />
          </div>
          <div className="box__content">
            <span>{investorsRequestingContact.length}</span>
            <p>Customers Requesting Contact</p>
            <Link to={`${baseUrl}?${GET_IN_TOUCH_URL_PARAM}=true`}>View all</Link>
          </div>
        </div>
        <div className="summary__box">
          <div className="box__image">
            <img src="/img/icons/check-ins/summary.svg" alt="Summary" height={50} />
          </div>
          <div className="box__content">
            <span>{investorsWithCheckIn.length}</span>
            <p>Number of total answered Check-ins</p>
            <Link to={`${baseUrl}?${HAS_CHECKIN_URL_PARAM}=true`}>View all</Link>
          </div>
        </div>
      </div>
    </Widget>
  );
};

CheckInsReviewWidget.propTypes = {
  layout: PropTypes.string,
  store: PropTypes.shape(widgetStorePropTypes).isRequired
};

CheckInsReviewWidget.defaultProps = {
  layout: LAYOUTS.BOX
};

export default connect(state => ({
  store: widgetStoreSelector('esList')(state)
}))(CheckInsReviewWidget);
