import { VerboseErrorInput } from 'components/form';
import InlineLabeledInput from 'components/form/inline-labeled-input';
import PhoneField from 'components/form/phone-field';
import SplashLoading from 'components/splash-loading';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import { AdvisorContext } from 'containers/advisor';
import { reduxForm } from 'redux-form';
import { check, validation } from 'utils/form';

const SIZES = { label: 3, input: 9 };

const validate = values => ({
  first_name: check(values.first_name).required().error,
  last_name: check(values.last_name).required().error,
  email: check(values.email).required().email().error,
  phone: values.phone && validation.internationalPhone(values.phone)
});

const InvestorProfileCommonPropTypes = {
  fields: PropTypes.object.isRequired,
  invalid: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired
};

const InvestorProfileForm = ({ fields, onCancel, onSubmit, invalid, submitting, loading }) => (
  <div>
    <div className="basic-profile-body">
      <InlineLabeledInput
        label="First name"
        inputComponent={VerboseErrorInput}
        labelClassName="text-sm-right"
        sizes={SIZES}
        inputProps={{
          className: 'form-control',
          ...fields.first_name
        }}
      />
      <InlineLabeledInput
        label="Last name"
        inputComponent={VerboseErrorInput}
        labelClassName="text-sm-right"
        sizes={SIZES}
        inputProps={{
          className: 'form-control',
          ...fields.last_name
        }}
      />
      <InlineLabeledInput
        label="Email"
        inputComponent={VerboseErrorInput}
        labelClassName="text-sm-right"
        sizes={SIZES}
        inputProps={{
          className: 'form-control',
          ...fields.email
        }}
      />
      <InlineLabeledInput
        label="Phone"
        inputComponent={PhoneField}
        labelClassName="text-sm-right"
        sizes={SIZES}
        inputProps={fields.phone}
      />
    </div>

    <div className="text-sm-center mt-3">
      <button
        type="button"
        className="btn btn-secondary"
        style={{ padding: '.375rem 4rem', marginRight: '20px' }}
        onClick={onCancel}
      >
        Cancel
      </button>

      <button
        type="submit"
        className="btn btn-primary"
        style={{ padding: '.375rem 4rem' }}
        onClick={onSubmit}
        disabled={invalid || submitting}
      >
        Save
      </button>
    </div>

    {loading && <SplashLoading text="Editing client" duration={0.25} />}
  </div>
);

InvestorProfileForm.propTypes = {
  ...InvestorProfileCommonPropTypes,
  loading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

const InvestorProfileBasic = ({
  closeModal,
  fields,
  getInvestor,
  handleSubmit,
  initializeForm,
  invalid,
  investor,
  investorProvider,
  submitting
}) => {
  const { setupTargetScoreWizard } = useContext(AdvisorContext);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initializeForm(investorProvider.form(investor));
  }, []);

  const onSubmit = values => {
    setLoading(true);
    investorProvider
      .update(values, true)
      .then(updatedInvestor => {
        getInvestor();
        const formattedData = {
          investors: [updatedInvestor.data],
          refresh: () => {}
        };

        setupTargetScoreWizard(formattedData);
        closeModal();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <InvestorProfileForm
      fields={fields}
      onCancel={closeModal}
      onSubmit={handleSubmit(onSubmit)}
      loading={loading}
      invalid={invalid}
      submitting={submitting}
    />
  );
};

InvestorProfileBasic.defaultProps = {
  getInvestor: () => {}
};

InvestorProfileBasic.propTypes = {
  ...InvestorProfileCommonPropTypes,
  closeModal: PropTypes.func.isRequired,
  getInvestor: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  initializeForm: PropTypes.func.isRequired,
  investor: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired
};

export default reduxForm({
  form: 'investorProfileBasic',
  fields: [
    'id', // hidden
    'first_name',
    'last_name',
    'email',
    'phone'
  ],
  validate
})(InvestorProfileBasic);
