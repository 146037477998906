import Dropzone from 'components/dropzone';
import LoadingButton from 'components/loading-button';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import ProgressBar from 'components/progress-bar';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AdvisorProvider from 'providers/advisor';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import './styles.scss';

const UPLOADING_STEP = 1;
const PROCESSING_STEP = 2;
const MAX_PROGRESS_VALUE = 100;
const FILE_SIZE_RATIO = 0.0005;

const CustomSecuritiesCsvUpdate = ({ downloading, getSecuritiesList, securitiesMeta }) => {
  const { customSecurityProvider } = useContext(AdvisorContext);
  const interval = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [droppedFileName, setDroppedFileName] = useState(null);
  const [file, setFile] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [progress, setProgress] = useState(0);
  const [step, setStep] = useState(UPLOADING_STEP);
  const [errorMessages, setErrorMessages] = useState([]);

  const estimatedTimeToComplete = (file?.size || 0) * FILE_SIZE_RATIO;

  useEffect(() => {
    if (fileUploaded) {
      setCurrentTime(0);
      setProgress(MAX_PROGRESS_VALUE);
      if (interval.current) clearInterval(interval.current);
    } else {
      const currentProgress = (currentTime / estimatedTimeToComplete) * MAX_PROGRESS_VALUE;
      if (currentProgress < MAX_PROGRESS_VALUE) setProgress(currentProgress);
    }
  }, [currentTime, fileUploaded]);

  const show = () => setIsShown(true);

  const hide = () => {
    setFile(null);
    setProgress(0);
    setDroppedFileName(null);
    setFileUploaded(false);
    setStep(UPLOADING_STEP);
    setIsShown(false);
    if (interval.current) clearInterval(interval.current);
  };

  const startUploadProgressBar = () => {
    setCurrentTime(0);
    if (!estimatedTimeToComplete) return;
    interval.current = setInterval(() => {
      setCurrentTime(prevCurrentTime => prevCurrentTime + 0.5);
    }, 1000);
  };

  const downloadSecurities = () => {
    customSecurityProvider.downloadCustomSecuritiesCSV();
  };

  const onDrop = files => {
    if (files.length) {
      setFile(files[0]);
      setDroppedFileName(files[0].name);
      setFileUploaded(false);
    } else toast.error('Invalid CSV file. Please check and try again.');
  };

  const onSubmit = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    setStep(PROCESSING_STEP);
    startUploadProgressBar();
    setErrorMessages([]);

    customSecurityProvider
      .uploadCustomSecuritiesCSV(formData)
      .then(response => {
        if (response.data?.errors) {
          const errors = response?.data?.errors;
          setErrorMessages(errors || ['An unexpected error occurred. Please try again.']);
          return setFileUploaded(false);
        }

        if (!response.version) throw new Error('Missing version in response');

        setFileUploaded(true);
        getSecuritiesList(securitiesMeta.params);
        toast.success('🎊 The custom securities were saved.');
        hide();
      })
      .catch(() => {
        setErrorMessages({
          error: ['Something went wrong while uploading the file. Please try again.']
        });
        return setFileUploaded(false);
      });
  };

  return (
    <>
      <div className="additional-links additional-links-csv-update-security">
        <button type="button" onClick={show} className="btn btn-secondary">
          CSV Update
        </button>
      </div>

      <Modal id="custom-securities-csv-update" className="modal-lg" show={isShown} onHidden={hide}>
        <ModalHeader />
        <ModalBody>
          <h3>Update Custom Securities</h3>

          {step === UPLOADING_STEP && (
            <>
              <ol>
                <li>
                  <p>
                    Download
                    <a
                      key="getcsvtemplate"
                      href="/csv/custom_securities_template.csv"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-link"
                    >
                      our CSV Template
                    </a>
                  </p>
                </li>
                <li>
                  <span>Download Current Securities</span>
                  <LoadingButton
                    className="btn btn-primary"
                    loading={downloading}
                    onClick={downloadSecurities}
                  >
                    Download Securities
                  </LoadingButton>
                </li>
                <li>
                  <span>Upload Updated CSV</span>
                  <div className="dropzone">
                    <Dropzone onDrop={onDrop} accept="text/csv">
                      {droppedFileName || 'Drag and drop or click here to upload file'}
                    </Dropzone>
                  </div>
                </li>
              </ol>
              <div className="btn-upload-csv-wrapper">
                <button type="button" className="btn btn-primary btn-upload-csv" onClick={onSubmit}>
                  Submit
                </button>
              </div>
            </>
          )}

          {step === PROCESSING_STEP && (
            <div className="loading-step">
              {!_.isEmpty(errorMessages) && (
                <>
                  <p className="error-message">The following issues were found in your CSV:</p>
                  {Object.entries(errorMessages).map(([errorType, messages]) => (
                    <div key={errorType} className="error-list-container">
                      <p className="error-type">
                        {errorType.replace(/_/g, ' ').replace(/^\w/, c => c.toUpperCase())}:
                      </p>
                      <ul className="error-list">
                        {
                          Array.isArray(messages) ? (
                            messages.map((msg, index) => <li key={index}>{msg}</li>)
                          ) : (
                            <li>{messages}</li>
                          ) // fallback for string or non-array
                        }
                      </ul>
                    </div>
                  ))}
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setStep(UPLOADING_STEP)}
                  >
                    Back to Upload
                  </button>
                </>
              )}

              {fileUploaded && errorMessages?.length === 0 && (
                <>
                  <p>The custom securities were saved!</p>
                  <button type="button" className="btn btn-primary btn-confirm" onClick={hide}>
                    Confirm
                  </button>
                </>
              )}

              {!fileUploaded && errorMessages?.length === 0 && (
                <>
                  <p>Processing securities update...</p>
                  <p>This might take a few seconds.</p>
                  <ProgressBar completed={progress} />
                </>
              )}
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

CustomSecuritiesCsvUpdate.propTypes = {
  downloading: PropTypes.bool.isRequired,
  getSecuritiesList: PropTypes.func.isRequired,
  securitiesMeta: PropTypes.object.isRequired
};

export default connect(
  state => ({ downloading: state.customSecurity.downloadingData }),
  dispatch => ({ advisorProvider: new AdvisorProvider({ dispatch }) })
)(CustomSecuritiesCsvUpdate);
