import _ from 'lodash';

export const EXCEPTION_STATUS = {
  TO_DO: 'To Do',
  IN_PROGRESS: 'In Progress',
  SNOOZED: 'Snoozed',
  RESOLVED: 'Resolved',
  CLOSED: 'Closed'
};

export const EXCEPTION_TYPE = {
  ACCOUNT: 'account',
  HOUSEHOLD: 'household',
  INVESTOR: 'investor'
};

const SUPERVISOR_ACTION = 'supervisor';
const NOTE_OTHER_ACTION_LABEL = 'Other (please specify below)';
export const NOTE_OTHER_ACTION_VALUE = 'Other';

const DEFAULT_NOTE_ACTIONS = {
  advisor: [
    'Notified client by phone',
    'Notified client by email',
    'Notified client in person',
    'Rebalance portfolio',
    'Sent proposal',
    'Sent IPS',
    'Account closed',
    'No action needed',
    NOTE_OTHER_ACTION_LABEL
  ],
  [SUPERVISOR_ACTION]: [
    'Please provide details for the exception',
    'Please obtain new questionnaire for the account',
    'Please obtain new investment objective schedule for the account',
    'This exception will be re-evaluated in 6 months'
  ]
};

export const DEFAULT_NOTE_TYPES = [
  'Account will be closing',
  'Concentration risk resulting from tax-sensitivity',
  'Temporary excess liquidity',
  'Unmatched investment goal with risk tolerance'
];

const byLabelValue = action => ({
  label: action,
  value: action === NOTE_OTHER_ACTION_LABEL ? NOTE_OTHER_ACTION_VALUE : action
});

const createActionOptions = (actions, hasCompliancePermissionsOrAbove) => {
  // get valid actions according to user permission role
  let validActions = Object.keys(actions).filter(
    key => !(key === SUPERVISOR_ACTION && !hasCompliancePermissionsOrAbove)
  );

  // check if the element 'supervisor' exists to filter it and reorder it at the beginning
  const hasSupervisor = validActions.includes(SUPERVISOR_ACTION);
  if (hasSupervisor) {
    validActions = validActions.filter(key => key !== SUPERVISOR_ACTION);
    validActions.unshift(SUPERVISOR_ACTION);
  }

  // create multiple option groups
  if (validActions.length > 1)
    return validActions.map(key => ({
      label: `${key} actions`,
      options: actions[key].map(byLabelValue)
    }));

  // create a single option group
  if (validActions.length === 1) {
    const [first] = validActions;
    return actions[first].map(byLabelValue);
  }

  // no valid options
  return [];
};

export const getNoteActionOptions = (actions, hasCompliancePermissionsOrAbove) => {
  if (actions) return createActionOptions(actions, hasCompliancePermissionsOrAbove);
  return createActionOptions(DEFAULT_NOTE_ACTIONS, hasCompliancePermissionsOrAbove);
};

export const getNoteTypeOptions = types => {
  if (types) return types.map(byLabelValue);
  return DEFAULT_NOTE_TYPES.map(byLabelValue);
};
