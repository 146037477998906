import SendEmailForm from 'components/advisor/investors/email-form';
import {
  CLICK_INVITE_TO_SHARE_BUTTON_CLIENT,
  CLICK_INVITE_TO_SHARE_BUTTON_PROSPECT
} from 'constants/actstream';
import { AdvisorContext } from 'containers/advisor';
import { DEFAULT_CONNECT_EMAIL_TEMPLATE } from 'containers/advisor/templates/defaults';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { trackAmplitudeEvent } from 'utils/tracking';

const ShareAccountEmail = ({ investor, onSend, hideModal, heldAway }) => {
  const { actionProvider } = useContext(AdvisorContext);

  const onSubmit = async ({ email, cc, sender, subject, message }) => {
    actionProvider.storeAction({
      verb: investor.is_prospect
        ? CLICK_INVITE_TO_SHARE_BUTTON_PROSPECT
        : CLICK_INVITE_TO_SHARE_BUTTON_CLIENT
    });
    trackAmplitudeEvent('add_account.connect.email_sent');
    return onSend(investor.id, {
      email,
      cc,
      sender,
      subject,
      message,
      url_query_params: { held_away: heldAway }
    });
  };

  return (
    <SendEmailForm
      action={{ text: 'Connect' }}
      className="share-account-email"
      initialValues={{ ...DEFAULT_CONNECT_EMAIL_TEMPLATE, email: investor.email }}
      investor={investor}
      onCancel={hideModal}
      submit={onSubmit}
      title="Share Your Account"
    />
  );
};

ShareAccountEmail.propTypes = {
  fields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  heldAway: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  investor: PropTypes.object.isRequired,
  onSend: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired
};

export default ShareAccountEmail;
