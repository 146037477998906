/* eslint-disable import/prefer-default-export */

const mergeCommonPositions = positions => {
  const mergedPositions = {};
  positions.forEach(position => {
    const { ticker } = position;
    if (!mergedPositions[ticker]) mergedPositions[ticker] = { ...position };
    else {
      mergedPositions[ticker].weight += position.weight;
      mergedPositions[ticker].weighted_yield_percentage += position.weighted_yield_percentage;
      mergedPositions[ticker].yield_value += position.yield_value;
    }
  });
  return Object.values(mergedPositions);
};

const getUnderlyingPositionsWithYields = (positions, parentWeight) =>
  positions
    .filter(position => position.yield_percentage)
    // The weight of the positions of the `security_underlying_model` is based on 100%
    // of the security, but this is not always true, so it requires adjustments based
    // on the weight of the parent position to correctly take the percentage needed.
    .map(position => ({
      ...position,
      weight: position.weight * parentWeight,
      weighted_yield_percentage: position.weighted_yield_percentage * parentWeight,
      yield_value: position.yield_value * parentWeight
    }));

export const getProcessedPositions = (proposal, breakdownCustomSecurities, type) => {
  if (!proposal[type]) return [];

  const positions = proposal[type].positions.reduce((acc, position) => {
    if (breakdownCustomSecurities && position.is_custom && position.security_underlying_model)
      return [
        ...acc,
        ...getUnderlyingPositionsWithYields(
          position.security_underlying_model.positions,
          position.weight
        )
      ];

    if (position.yield_percentage) return [...acc, position];
    return acc;
  }, []);

  if (breakdownCustomSecurities) return mergeCommonPositions(positions);
  return positions;
};
