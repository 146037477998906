import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import RcSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './styles.scss';

const { createSliderWithTooltip } = RcSlider;
const Slider = createSliderWithTooltip(RcSlider.Range);

const LIMIT_SCORE_MINIMUM_VALUE = 1.9; // Minimum value for rows except the first one
const LIMIT_SCORE_MAXIMUM_FIRST_ROW = 1.8;
const LIMIT_SCORE_MAXIMUM_FOR_INTERMEDIATE_ROW = 9.8;
const LIMIT_SCORE_MINIMUM_FOR_LAST_ROW = 9.9;

const RiskLevelsSlider = ({ riskLevels, onChange }) => {
  const range = Array.from({ length: 10 }, (_, i) => i + 1);
  const marks = Object.fromEntries(
    range.map(i => [
      i,
      {
        label: `${i} - `,
        style: { color: 'lightgray', marginLeft: '-40px', fontSize: '14px' }
      }
    ])
  );
  // Dynamically update handles based on riskLevels
  const getHandles = () => riskLevels.slice(1).map(level => level.min);
  const [handles, setHandles] = useState(getHandles());

  useEffect(() => {
    setHandles(getHandles());
  }, [riskLevels]);

  const gradientColors = riskLevels
    .map(level => `${level.color} ${(level.min - 0.5) * 10}% ${(level.max - 1) * 10}%`)
    .join(', ');

  const railStyle = {
    background: `linear-gradient(to bottom, ${gradientColors})`,
    width: '10px',
    height: '127%',
    marginTop: '-55px'
  };

  const handleStyle = handles.map((_, index) => ({
    borderColor: riskLevels[index + 1]?.color || 'gray'
  }));

  const handleSliderChange = values => {
    if (!Array.isArray(values)) return;

    const updatedLevels = [...riskLevels];
    const roundedValues = values.map(value => Math.round(value * 10) / 10);

    roundedValues.forEach((value, index) => {
      // Enforce the minimum value constraint
      if (index === 0) {
        // First level
        updatedLevels[0].min = 1; // Always 1 for the first row
        updatedLevels[0].max = Math.max(roundedValues[index] - 0.1, LIMIT_SCORE_MAXIMUM_FIRST_ROW);
      } else if (index < roundedValues.length) {
        // Intermediate levels
        updatedLevels[index].min = Math.max(
          roundedValues[index - 1],
          updatedLevels[index - 1].max,
          LIMIT_SCORE_MINIMUM_VALUE
        );
        updatedLevels[index].max = Math.min(
          Math.max(roundedValues[index] - 0.1, updatedLevels[index].min + 0.1),
          LIMIT_SCORE_MAXIMUM_FOR_INTERMEDIATE_ROW // Ensure max does not exceed 9.8
        );

        if (index === roundedValues.length - 1)
          updatedLevels[index].max = Math.min(updatedLevels[index].max, 9.9);
      }
    });

    const lastIndex = updatedLevels.length - 1;
    updatedLevels[lastIndex].min = Math.min(
      Math.max(
        roundedValues[roundedValues.length - 1],
        updatedLevels[lastIndex - 1].max + 0.1,
        LIMIT_SCORE_MINIMUM_VALUE
      ),
      LIMIT_SCORE_MINIMUM_FOR_LAST_ROW
    );
    updatedLevels[lastIndex].max = 10; // Last max always remains 10

    // Ensure proper rounding and prevent min/max overlap
    updatedLevels.forEach(level => {
      level.min = Math.round(level.min * 10) / 10;
      level.max = Math.round(level.max * 10) / 10;

      // Ensure max is always greater than or equal to min + 0.1
      if (level.max < level.min + 0.1) level.max = level.min + 0.1;
    });

    onChange(updatedLevels);
  };

  return (
    <div className="configuration--risk-level__slider">
      <Slider
        vertical
        withHandle
        dots={false}
        allowCross={false}
        infinite={false}
        draggable={false}
        lazyLoad
        reverse
        range
        min={1.9}
        max={9.1}
        pushable={0.9}
        tipFormatter={value => value.toFixed(1)}
        step={0.1}
        value={handles}
        marks={marks}
        onChange={handleSliderChange}
        railStyle={railStyle}
        handleStyle={handleStyle}
      />
    </div>
  );
};

RiskLevelsSlider.propTypes = {
  riskLevels: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default RiskLevelsSlider;
