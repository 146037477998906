import config from 'app/config';
import clone from 'clone';
import PropTypes from 'prop-types';
import 'utils/gaugare';
import Chart from '..';

const DISABLED_RANGE_COLOR = '#d4d6db';

class RiskGaugeChart extends Chart {
  className = 'risk-gauge-chart';

  updateGaugeOpts(opts, value, target) {
    const { dynamicArrowColor, radius, innerRadius, arrowRadius, arrowNailRadius, arrowStartWith } =
      this.props;

    let idxValue = 0;

    if (target) {
      opts.arrows[0].value = target;
      if (arrowRadius) opts.arrows[idxValue].radius = arrowRadius;
      if (arrowNailRadius) opts.arrows[idxValue].nailRadius = arrowNailRadius;
      if (arrowStartWith) opts.arrows[idxValue].startWidth = arrowStartWith;
      idxValue = 1;
    }

    opts.arrows[idxValue].value = value;

    if (arrowRadius) opts.arrows[idxValue].radius = arrowRadius;
    if (arrowNailRadius) opts.arrows[idxValue].nailRadius = arrowNailRadius;
    if (arrowStartWith) opts.arrows[idxValue].startWidth = arrowStartWith;
    if (dynamicArrowColor) {
      const colors = (opts.arrows[idxValue].color = opts.axes[0].bands.filter(
        b => b.startValue <= value && b.endValue >= value
      ));
      opts.arrows[idxValue].color = colors[colors.length - 1].color;
    }
    opts.axes[0].bands.forEach(b => {
      b.radius = radius;
      b.innerRadius = innerRadius;
      if (b.startValue >= value) b.color = DISABLED_RANGE_COLOR;
    });
  }

  getOptions(newProps) {
    const props = newProps || this.props;
    const { options, value, target } = props;
    const opts = {
      ...clone(config.chart.gauge.riskScore),
      ...clone(options)
    };
    this.updateGaugeOpts(opts, value, target);
    return opts;
  }
}

RiskGaugeChart.propTypes = {
  arrowNailRadius: PropTypes.number,
  arrowRadius: PropTypes.string,
  dynamicArrowColor: PropTypes.bool,
  id: PropTypes.string,
  innerRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.object,
  radius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.number.isRequired
};

RiskGaugeChart.defaultProps = {
  arrowNailRadius: null,
  arrowRadius: '',
  dynamicArrowColor: true,
  id: null,
  innerRadius: 60,
  options: {},
  radius: 75
};

export default RiskGaugeChart;
