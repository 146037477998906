/* eslint-disable no-return-assign */
import Disclosure from 'components/disclosure';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import SplashLoading from 'components/splash-loading';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InvestorHeader from './investor-header';
import InvestorListTable from './list';
import './styles.scss';

class InvestorList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      callBackFunc: null,
      expanded: {},
      isConverting: false,
      name: '',
      show: false
    };
  }

  componentDidMount() {
    const { prismProvider } = this.context;
    prismProvider.getAccountPrismIntents();
  }

  getList = (params = {}) => {
    const { accountProvider, investorProvider, prospectProvider } = this.context;
    const {
      type,
      location: { query }
    } = this.props;

    const isProspectList = type === 'prospect';
    const provider = isProspectList ? prospectProvider : investorProvider;

    if (!isProspectList) {
      // the `ordering` parameter is removed because it's not compatible with this request,
      // and does not influence the total number of excluded accounts
      accountProvider.countExcludedAccounts({ ...params, ordering: undefined });
      investorProvider.countNew();
    }

    return provider.list(params, query);
  };

  toggleProspectNotSelectedModal = callBackFunc => {
    const { show } = this.state;
    this.setState({ show: !show, callBackFunc });
  };

  toggleProspectCompletedLoader = () => {
    const { isConverting } = this.state;
    this.setState({ isConverting: !isConverting });
  };

  render() {
    const {
      investorProvider,
      investorsMeta,
      location,
      prospectProvider,
      prospectsMeta,
      selectedProspectIds,
      user
    } = this.context;
    const { excludedCount, type } = this.props;
    const { callBackFunc, expanded, isConverting, name, show } = this.state;

    const canSubmit = selectedProspectIds.length > 0;
    const meta = type === 'prospect' ? prospectsMeta : investorsMeta;

    return (
      <div className="investor-list-view">
        <InvestorHeader getList={this.getList} meta={meta} type={type} />

        <InvestorListTable
          expanded={expanded}
          getList={this.getList}
          investorProvider={investorProvider}
          location={location}
          meta={meta}
          name={name}
          prospectProvider={prospectProvider}
          showBottomNotification={excludedCount <= 0}
          toggleProspectCompletedLoader={this.toggleProspectCompletedLoader}
          toggleProspectNotSelectedModal={this.toggleProspectNotSelectedModal}
          type={type}
          user={user}
        />

        <Modal
          id="convert-client-modal"
          className="modal-lg"
          ref={c => (this.modal = c)}
          show={show}
          onHidden={this.toggleProspectNotSelectedModal}
        >
          <ModalHeader />
          <ModalBody>
            <h4 className="text-center">Convert to Client</h4>

            <div className="input-section">
              {!canSubmit
                ? `Please select the prospects you want to convert to Clients.`
                : `You will not be able to reverse this action. Are you sure you'd like to convert this prospect to a client?`}
            </div>
            <div className="mt-3 mb-2 text-center">
              <button
                className="btn btn-primary"
                type="button"
                onClick={() => {
                  if (callBackFunc) callBackFunc(canSubmit);
                  this.modal.hide();
                }}
              >
                Confirm
              </button>
            </div>
          </ModalBody>
        </Modal>

        {isConverting && <SplashLoading text="Converting the selected prospects" duration={0.25} />}

        <Disclosure />
      </div>
    );
  }
}

InvestorList.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  investorProvider: PropTypes.object.isRequired,
  investorsMeta: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  prismProvider: PropTypes.object.isRequired,
  prospectProvider: PropTypes.object.isRequired,
  prospectsMeta: PropTypes.object.isRequired,
  selectedProspectIds: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired
};

InvestorList.propTypes = {
  excludedCount: PropTypes.number,
  location: PropTypes.object.isRequired,
  type: PropTypes.string
};

InvestorList.defaultProps = {
  excludedCount: 0,
  type: null
};

export default InvestorList;
